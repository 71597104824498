export default {
  title: `Upcoming early game preview!`,
  date: `2021-03-26`,
  intro: `I have some exciting news to share with you!`,
  fullText: `
<p>
   I'm finally closing in on what I'd consider the first playable, early alpha version of the new game! And once I have that up and running - I want to make it available to you - the players - to test out how the game works in the real world. But before I get way ahead of myself, there's couple important things I must point out first.
</p>
<h2>The purpose</h2>
<p>
  I think of this this earliest version of the game as a test. There are couple of things I want to test out at this stage:
</p>
<ul>
  <li>The viability of the action-point based interaction with the game world</li>
  <li>The stability of the game server</li>
  <li>The usability of the core interface elements</li>
</ul>
<p>
  Between these three angles, your feedback will be absolutely crucial to me. What works, what's clunky, what's broken.
</p>
<p>
  That said, there are couple of things I should note about this upcoming early version. To start with - I've created a really tiny test world, along with resources, monsters and recipes. None of these particular items are guaranteed or even expected to necessarily be part of the final game.
</p>
<p>
  Next and it's very important - the game at this stage is vastly incomplete. This means that a number of systems that you might be familiar with from the first game won't be available as part of this test. Couple of features I should mention here that will be missing would be house decoration, pets, weather, player races and dungeons. They are coming in later, but I'm still working out the details of how these will work in this new iteration and as such I don't want to delay this preview for these features specifically.
</p>
<p>
  I should also note - at this stage the game will have no tutorial and as such I expect some prior knowledge to Soulforged's main concepts will be very advantageous.
</p>
<p>
  While the game is coming along nicely, I still have a somewhat lengthy list of necessary improvements, fixes and features the game needs before I can make it available. It's only yesterday that I added the option to flee from combat!
</p>
<h2>Quickfire round</h2>
<p>
  Now for couple of questions I expect to pop up regarding this very early version.
</p>
<h5>Q. What will I need to play this new version of the game?</h5>
<p>A. A somewhat modern browser and a google account.</p>
<h5>Q. How much does it cost to play this version of the game?</h5>
<p>A. This early version of the game will be completely and absolutely free.</p>
<h5>Q. When is it going to be available?</h5>
<p>A. I estimate that this version of the game will be ready in about 2-4 weeks.</p>
<h5>Q. How will I know it's available? How do I sign up?</h5>
<p>A. Keep an eye out on the announcements channel on the <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>. Once a link is posted there everyone will be able to access the game. There is no sign-up process.</p>
<h5>Q. How would I go about sharing my impressions and feedback about the game?</h5>
<p>A. I welcome all feedback, praise and damnation, in our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server!</p>
<h5>Q. Are the characters/items/currency going to be retained for the live version of the game?</h5>
<p>A. There are no plans to do that. Additionally - you should be prepared that this demo world may be reset at any point due to specific issues that may crop up.</p>
<h5>Q. How long will the test be available for?</h5>
<p>A. Initial plan is to have it up and running at least for a month. Depending on feedback and changes the feedback may trigger it might be running longer than that.</p>
<h2>What follows</h2>
<p>
  Once the demo is up and about I'll be exploring my options regarding setting up donation/patreon! I'm both anxious and excited as to what this could mean for me and for the game. Your support is very much appreciated and some financial support would mean I could start looking at bespoke assets to be made for the game, making it all more engaging, immersive and exciting!
</p>
<p>
  I hope you're at least half as excited as I am! If you have any follow up questions regarding this upcoming test please feel free to message me on <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>, always brightens my day to get a message from you! 
</p>
`
};
