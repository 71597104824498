export default {
  title: `The invisible work`,
  date: `2021-06-18`,
  intro: `Building an online game is a bit more than just piling on features.`,
  fullText: `
<p>
  There's work that needs to be done to ensure server stability and responsiveness, game quality, and other non-feature aspects of running an online game. Today I'd like to provide you with a small peek behind the curtain, showcase couple of things that are being done and worked one that would otherwise go mostly unnoticed.
</p>
<h2>Test coverage</h2>
<p>
  I mentioned in an update back in December that I've been working diligently to ensure all of back-end codebase is covered by automated tests. I'm somewhat proud to report that I continue to maintain 100% coverage, especially since the work required to maintain that often doesn't feel very rewarding, at least not directly. 
</p>
<p>
  Why continue to maintain it then? I have a show story that I'd like to share that gives an example of why having those tests in place is really worth this effort. So buckle up and get ready, since we're going to go a bit technical on this one.
</p>
<h3>The story of the kind-of-broken tools</h3>
<p>
  This story starts with a player bug report. A player named <em>Kai</em> reported via <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> that while gathering, the game reported their tool broken unexpectedly. There was no broken tool in their bags and instead they had two worn tool items. 
</p>
<p class="small">
  Sidenote: that was an awesomely detailed bug report and such reports make my job so much easier - thank you so much!
</p>
<p>
  So what happened was as follows:
</p>
<ul>
  <li>The player started with 2 axes in their bags, same quality, one mint and one worn</li>
  <li>As they chopped for wood using the mint axe, it was slowly deteriorating and became worn</li>
  <li>As the item became worn, the game checked if it can be stacked and found the other worn axe</li>
  <li>The server stacked the axes. Stacking items means that one is destroyed and the other is marked as having increased count</li>
  <li>As it happens, the axe currently used to gather wood was the one that was destroyed</li>
  <li>The current action detected the tool is no longer there and reported the tool broken</li>
</ul>
<p>
  Now that I was aware of the issue, I started to work on the fix. Typically the first thing to do with a bug report is to reproduce it. But rather than manually setting up the scenario, creating character, creating the tools, etc. I decided to go ahead and implement a test case that goes over this exact scenario. And sure enough when I implemented the test, the first time I ran it the test failed, as expected. 
</p>
<p>  
  I was ready to start working on the fix. I already have a nice method that allows me to detect stacked items and get the item it was stacked into, so at first glance it should be easy enough. Unfortunately for me, the selected tools for the current action were stored by their ID, rather than having direct reference to the item. And the moment the item is destroyed, the ID becomes useless, as I can't retrieve destroyed items by ID.
</p>
<p>
  The solution was still fairly simple - change the action tools so that they keep reference to the item, rather than just its ID. That means when an item is destroyed, I still can check what this item was and more importantly, which item it was stacked with. The problem is - there are a number of ways these action tools are referenced in the codebase and missing any of them risks a fatal server bug and crashes.
</p>
<p>
  And that's exactly where test coverage shines. Instead of very meticulously going over every line of code that may be even remotely related to action tools, couple of search-replace, re-running the tests, fixing one edge case and 5 minutes later I have the overhaul done. Now I can apply the replacement with the item the tool would be stacked with, add code to update current server state to fix the existing references, quick rattle test and the fix is ready to ship!  
</p>
<p>
  Having tests in place the relatively daunting task of changing such fundamental property was quick and painless. And gives me the confidence that all the standard use-cases will work as expected. On top of that, with the extra test case added to cover that scenario I can continue the development with confidence that other changes I make later are unlikely to cause this bug to reappear.
</p>
<h2>Having backups</h2>
<p>
  Next I want to look at another important aspect and that's data backups. In an online role-playing game data is absolutely everything. In other games, like rougelikes or online shooters, there's hardly any data that really needs to be retained. You jump in, play a round or two, have fun and then shut it down. All that matters is that the game session itself was smooth and working as expected. But in an RPG game, the decisions we made, the challenges we chose to tackle and the ones we overcame - all that adds up to where we are in the game right now. Losing that data is disastrous and can ruin the game for most players.
</p>
<p>
  When I was first running Soulforged Zero, I was being somewhat lax with regards to the data. Not to say I was completely negligent, as I was still storing lots of historical data allowing me to closely inspect and restore an old state if I needed, but one thing that I kept postponing was keeping off-server backups. Eventually, on a New Years Eve, a disaster struck: the hosting service I used had a fatal incident and we lost several hours of game progress, and all the backups generated during that time. 
</p>
<p>
  Shortly after I implemented off-site backups using a Raspberry Pi I had at home, still subject to other issues like network availability, but an improvement on what I had before.
</p>
<h3>Stepping up</h3>
<p>
  We're still in pre-alpha stages of the game, where a reset could possibly be happening any day, but I am way more diligent about the data already. I've set up automated backups that are made every 4 hours and the data is stored off the server itself. I'm actually using Azure's services geo-redundancy - which means the data is safer than ever. The server itself is running from a UK location and the backups are stored in two other locations - on the US western coast and US eastern cost. That means that short of the western civilization collapsing altogether the data should be perfectly secure.
</p>
<p>
  <span class="image-frame right" style="width: 40%;">
    <a target="_blank" href="./images/2021-06-18/logs-backups.jpg">
      <img src="./images/2021-06-18/logs-backups.jpg">
    </a>
    <span>Yesterday's log backup. Almost 80MB of data</span>
  </span>
  I'm also storing and rotating all the logs, which have a whole lot of data in them. They too are stored off-server. This also makes it easier to maintain the main game server since I avoid running into the ever-present issues of disk space that Soulforged Zero (and Souls: Re-Forged) suffered from.
</p>
<h2>Studying the data</h2>
<p>
  Finally, one other thing I wanted to talk about is data analytics. I have the access to the server save at all times, including the backups, but it's important to employ fitting tools to really study that data.
</p>
<p> 
  Working with Heap Analytics before, them having a free tier and inclusion of custom events is all I could really ask for. For maximum accuracy I also chose to have the events all be triggered server-side. 
</p>
  <span class="image-frame right" style="width: 40%;">
    <a target="_blank" href="./images/2021-06-18/analytics-structures.jpg">
      <img src="./images/2021-06-18/analytics-structures.jpg">
    </a>
    <span>Example report showing the number of buildings present by type</span>
  </span>
<p class="small">
  Explanation: Analytics tools are usually running in the browser, client side. But many ad-blockers will prevent these tools from collecting the data, meaning that some data would be missing.
</p>
<p>
  I've only started gathering the data recently, but there's so much interesting information to be glimpsed from it. It's really hard to decide what kind of information is worth measuring, but starting with just some basic statistics I'm already getting additional overview of how the game is played.
</p>
<h3>The story of never-ending combat</h3>
<p>
  In here I'd like to mention one other incident we had recently. It was reported by a few players, first brought up by <em>Cedric</em>, that when they died to a hostile creature they couldn't find their gear in the location where they died. Downloading the save, doing a bit of inspecting and I fairly quickly found out that there was something happening with combat encounters - and that they were not terminated correctly, keeping all the items "hostage", so to speak. 
</p>
<p>
  I still need to refine my reports, but it was entirely possible to get the wind of this issue from the data report directly:   
  <span class="image-frame" style="width: 100%;">
    <a target="_blank" href="./images/2021-06-18/analytics-combat.jpg">
      <img src="./images/2021-06-18/analytics-combat.jpg">
    </a>
    <span>The constantly growing list of ongoing combat encounters</span>
  </span>
</p>
<p>
  As you can see on the graph, the problem was already present before the reset that happened on May 28th, but since then the problem was getting worse on a daily basis. The number of ongoing encounters keeps growing until June 12th, when the issue was reported and I deployed a fix.
</p>
<h3>Other uses</h3>
<p>
  I can use these statistics to track overall player progress, player activity and how different changes impact player behaviour. For instance I am planning to run a test that checks the % of players that end up reaching the Action Point cap on daily basis when I increase the Action Points cap. 
</p>
<h2>Summing up</h2>
<p>
  While this isn't all that I'm doing behind the scenes, I hope this gives you a bit of an overview of this kind of work. I also hope this article helps by reassuring you how seriously I treat this game and the respect I have for you, the players and your experience. As always - if there's anything you'd like to know or have some suggestions, be sure to join out <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server and let's have a chat! 
</p>
`
};
