export default {
  title: `Game progression`,
  date: `2021-06-04`,
  intro: `Today I'd like to go over my intent for player progression in Soulforged.`,
  fullText: `
<p>
I think it's an important topic to cover and I'd really hope to give the community the confidence that game progression in Soulforged is something you'll want to engage and have fun with.
</p>
<p>
Before we dive into this topic I need to emphasize that this is only my <em>current intent</em> for how I see the progression shaping up. This means that it may change and evolve, but also that it's subject to how well I can really deliver and execute on this. Alright, let's dive in! 
</p>
<h2>Tiers</h2>
<p>
  First let's open up with the concept of tiers. The game world will be comprised of a number of areas and each of those areas will be of a certain "tier". The higher the tier, the more difficult it is to survive and thrive in that area, be it because of the difficulty of obtaining resources (requiring higher skill levels), the toughness of the monsters (requiring better equipment), the region climate (requiring specific gear) or a combination of those factors. The earliest tier will be the initial starting area where you can run around with no equipment and still be very likely to survive just fine.
</p>
<h2>Progressing through tiers</h2>
<p>
  To gain access to the higher tiers, you'll usually be looking to exploit and gain power in the earlier tiers. Getting your hands on the equipment, house decorations, food, consumables, improving your character skills and statistics - all that will help you make the next tier accessible to your character. On lower tiers you can expect needing just a few improvements out of the available pool to get access to the next tier. But the higher you go, the more and more likely you'll need to employ all of the possible enhancements to survive the new areas.
</p>
<p>This also means that particularly the early tiers of the game are not tied to a specific progression line and you'll have the choice of which area you'll want to move to next.</p>
<h2>Settling down</h2>
<p>
  Once you set up a solid foothold in a specific area, you can expect to be able to sustain yourself by living off that land alone, without necessarily needing to visit earlier areas. It may still be worthwhile to do that for the purpose of obtaining some of the materials only available there for some cutting-edge equipment, but you shouldn't need to do that to just survive. 
</p>
<p>
  What this means is that the area you live in will be providing you with the materials to produce consumables and food, keep your house maintained and your character equipped well enough to function there. That means combating the climate, staving off beasts and having the skills needed to gather and prepare meals, even in case of a character death and respawn.
</p>
<h2>Death and rebirth</h2>
<p>
  It's here that we get to the very essence of the intent behind the progression system. Once your character perishes, be it due to old age or unfortunate circumstances, you won't be looking at progressing all the way through each of the tiers again. Instead you will find that your gear, house decorations and consumables enable you to live and continue functioning in the areas you previously found comfortable. If the basic resources in a given area require a certain skill level to gather, you can expect to be able to obtain items that improve your skills enough to have a fair chance of getting them.  
</p>
<p>
  Only tangent, but still important to note is that you'll also have more flexibility in where your next character spawns, so you can expect to be able to resume your playstyle almost immediately with a freshly spawned character. 
</p>
<h2>Playing with friends</h2>
<p>
  What this also means is that you can almost immediately play with friends who only just start the adventure in Soulforged! Provided of course that you can spare them the gear and items they need to survive and function in an area you currently live in. 
</p>
<p>
  As a veteran player you'll still have a number of advantages, like the accumulated in-game currency, the researches you've done and the knowledge about the game world. But what this means is that you can play together with your friends and meaningfully support one another, regardless of what stage of the journey you're on.
</p>
<h2>The importance of death</h2> 
<p>
  Reading the above you may start to create the vibe that death may actually feel meaningless in this game. I think it's fair to ask the question if we aren't better off removing perma-death from the game altogether.  
</p>
<p>
  My answer to that question is absolutely not. Soulforged is still intended to be, first and foremost, a game. And while this term got really blurred over the years, for me it remains a core element of that term that it tests and pushes the skills of a player.
</p>
<p>
  I'd argue that death brings meaning to your character's existence, the choices and the sacrifices you make. Without that you're looking to inevitably be able able to increase your character's skills and stats high enough that you can access any area in the game. With it, the skill and stat gains are there to help you reach over the thresholds and set foot in new areas. Provided you play the game well enough.  
</p>
<h2>Other playstyles</h2>
<p>
  It's worth noting that the game progression and particularly cutting edge of it won't be something that is necessary to enjoy the game. For players who decide to make an easier region their home, they can still enjoy the game and they can continue to meaningfully contribute to the in-game community from there. Many challenges will be built around player cooperation and to beat them you may need the help of players from all across the world!   
</p>
<h2>Closing thoughts</h2>
<p>
  I hope this give you some insight into what I intend the player progression to be like in Soulforged. I also hope it gives you confidence that I'm building toward a game that can be enjoyed and that will be there to challenge you and your friends, including those you make along the way. 
</p>
<p>
  As always, this article is an invitation to a discussion! I very much welcome your thoughts on what you read here today, whether that makes you excited to see where the game goes or if you have some reservations. Let's chat over on our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a>!
</p>
`
};
