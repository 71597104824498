export default {
  title: `Farming, literally`,
  date: `2021-08-27`,
  intro: `In today's post I'd like to delve a bit into the upcoming farming system.`,
  fullText: `
<p>
    With the design complete and most of the implementation already done, I think now's a good time to talk about my intent for the farming system. I have to admit - I'm really excited about this one, I hope it really delivers on the goals I have for farming as an activity and it'll add to emphasis for Soulforged to be more than just another combat-oriented game.
</p>
<p>
    I can't overstate my appreciation for all the testing the community has done so far on the pre-alpha and at the same time I feel I can do better to serve the community when it comes to those tests. This is why I decided to write this blog post - to explain my goals and my reasons for why farming was designed the way I did it. I hope this will help the players who join me in testing this system, verify if it's fit for the purpose it was created for and whether the goals align with what players would normally expect.
</p>
<h2>The old</h2>
<p>
    Before we begin let's take a quick look at where we've been so far when it comes to farming. Soulforged Zero had farming system implemented, but it was what I'd call extremely barebone. Certain plants were marked as suitable for farming. Players could pick a handful of them and plant them down in a publicly-accessible farm. They'd then wait for a few days, depending on the plant type, and just harvest them to get more than initially planted. There were some other limitations to this system, but all in all it was about multiplying the crop. It was really basic and so I took virtually none of it when thinking about this new design. Sounds interesting? Let's dive in.
</p>
<h2>Interaction</h2>
<p>
    First and most important thing I wanted to change is how interactive farming is. You will still be looking to plant your crop, this time using seeds, and you will still get to harvest them, but what happens in between is now also crucially important. The crop you'll be growing will now be subject to weed infestations, vermin nibbling at your plants and watering will become an important part of the process. If the crop is neglected, left to vermin and weeds, you can expect the final result to be not-so-impressive.
</p>
<p>
    So while farming is now becoming more interactive, it's important to me that it's never time-sensitive. As such, if a weed takes root on your farm you don't need to rush and panic and work to immediately remove it. Same with the vermin - while they will be going after your plants, they're sure to be a bit shy at first. What this means is that the consequences of having vermin, weeds or lacking watering do not kick in immediately and are not wholly ruinous to your garden. Which leads me to the next point...
</p>
<h2>Satisfaction</h2>
<p>
    I want farming to feel and to be satisfying. I believe the most important step in that regarding is the feedback player receives. From the moment you plant the seeds, throughout the growing period and tending to the plants, all the way to harvest - each step contributes to how successful the outcome is. And in this case I keep the outcome simple - that is the yield of your crop. Assuming adequate skill, good climate and tending to the plants - you're looking to have better final yield than if any of these elements were lacking. And that's where feedback comes in - when you're harvesting the plants you can expect information on what the yield is and why it turned out the way it did. It'll list planting, harvesting, vermin, weeds, climate and watering individually, grading each on how well they worked for the plant you just harvested.
</p>
<p>
    The second element needed for satisfying farming is an adequate reward for doing the work. The starting point that I envision for the growth is as follows: taking a plant you can extract seeds. You'd usually get a few of them for one fruit. One seed is needed to plant one tile of your farm. A small starter farm would have about six tiles to work with, with much bigger farms available as your skill grows and you find additional materials. The growth period would be something between one to four weeks and the yield for a single tile will be comparable to what you'd get harvesting a wild resource from plenty all the way. That means several dozens of fruit, assuming the conditions were ideal.
</p>
<h2>Ownership</h2>
<p>
    Finally, there's the matter of ownership. While in Soulforged Zero farms were all publicly available and had to be shared with other players, this often led to situation where no one really knew what was going on on the farm and who planted anything. It also left it open to players collecting harvest of one another, intentionally or not.
</p>
<p>
    In Soulforged the farms you build are your own. You will have exclusive access to your farm's crops, planting and harvesting. The one small exception to that is the vermin - when a vermin decides to nibble at your plant, it'll be a creature that exists in the location your farm is at. As such, any player can actually engage and kill the vermin, solving the problem for you.
</p>
<p>
    I do plan to include options of sharing your structures with other players, which would including housing, shops as well as farms. This means that you will get an option of creating a farming community where players help one another or simply have a friend tend to your farm in your absence.
</p>
<h2>Closing thoughts</h2>
<p>
    There's still more to share on the farming, particularly on how the interface for it looks like, but I think I might want to keep this one as a treat (I hope!) for those who try it out in the pre-alpha when the update hits.
</p>
<p>
    I thank you for reading this short article and I hope it makes you excited about farming in Soulforged!
</p>
`
};
