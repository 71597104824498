export default {
  title: `Chapter 2 goes live!`,
  date: `2023-02-17`,
  intro: `Today's the date that Chapter 2 is launched for everyone to enjoy!`,
  fullText: `
<p>
  The journey of Soulforged continues! It's now a little over a year since the Early Access launch and the game has been expanded and improved in many ways. But the biggest update yet has just been made available - Soulforged Chapter 2 is now live! Here's what you can expect from this new chapter:
</p>
<ul>
  <li>The available landmass is now more than doubled!</li>
  <li>A music system - discover how to play musical instruments and inspire fellow players!</li>
  <li>New and exciting threat! Stay vigilant as you trek the newly opened lands.</li>
  <li>Offhand combat equipment to help you brave the new challenges!</li>
  <li>63 new craftable items</li>
  <li>35 new character powers</li>
  <li>12 new creatures</li>
  <li>...and a handful more new smaller features!</li>
</ul>

<p>
  You can play the game now at <a href="https://play.soulforged.net">https://play.soulforged.net</a>!
</p>
<p>
  Consider joining the community on our <a href="https://discord.gg/XExbewT5GQ">Discord</a>!
</p>

<h2>F.A.Q.</h2>
<p>
  <strong>Q.</strong> How much does this expansion cost?<br/>
  <strong>A.</strong> All of the Chapter 2 contents, same as all the content available previously, is available <em>completely for free</em>.
</p>

<p>
  <strong>Q.</strong> I didn't play in a while. How do I catch up to play Chapter 2 content?<br/>
  <strong>A.</strong> The lands added in Chapter 2 can be reached relatively easily and there's room there for all characters, regardless of their power and equipment, to find something to pursue there.<br/>
</p>
`
};
