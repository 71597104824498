<template>
  <div class="content">
    <h2>Screenshots</h2>
    <p>
      In-game screenshots provided by the community. Would you like to see your
      screenshot here? Share it on our
      <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>
      server!
    </p>
    <div class="screenshots-container">
      <div
        v-for="img of screenshots"
        :key="img"
        class="screen-box"
        @click="selected = img"
      >
        <img :src="img" />
      </div>
    </div>
    <div v-if="selected">
      <div class="overlay" @click="selected = null">
        <img class="overlay-image" :src="selected" />
      </div>
    </div>
  </div>
</template>

<script>
import screenshots from "../script/screenshots.js";

export default {
  data: () => ({
    screenshots,
    selected: null
  })
};
</script>

<style lang="scss">
.screenshots-container {
  display: flex;
  flex-wrap: wrap;
}

.screen-box {
  width: 50%;
  margin-bottom: 15px;
  cursor: pointer;

  img {
    margin: 0 auto;
    display: block;
    max-height: 150px;
    border: 1px solid #555;
    box-shadow: 3px 3px 4px #555;
  }

  &:hover {
    img {
      border-color: darkgoldenrod;
    }
  }
}

.overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .overlay-image {
    max-width: 80%;
    max-height: 80%;
    margin: 0 auto;
  }
}
</style>
