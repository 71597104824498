export default {
  title: `Sky is the limit`,
  date: `2021-02-12`,
  intro: `In this article I'd like to go over an experiment I conducted a while back, why I pursued it and why I scrapped it.`,
  fullText: `
<p>
  Building a new version of Soulforged allows me to reconsider or reinvent some of the solutions and systems and experiment with things that I'd previously dismiss as simply too big and complex.
</p>
<p>
  One such experiment involved the game presentation. I wanted to move away from the constraining hex-based map design as well as resolve the many problems that stemmed from showing all the locations discovered so far by the player. Another thing that I dream of, even today, is to be able to present the players with the sky.  
</p>
<p>
  It may sound very mundane, but I feel that including sky in the game view can have a huge impact on the atmosphere of the whole game as well as the individual locations the players visit. Between soothing sunsets, energizing sunrises, dreadful storms - the sky can be extremely powerful in complimenting the tone of the in-game situation.
  <span class="image-frame right" style="width: 40%;">
    <a target="_blank" href="./images/2021-02-12/wild_hunt.jpg">
      <img src="./images/2021-02-12/wild_hunt.jpg">
    </a>
    <span>Copyright CD Projekt Red</span>
  </span>
  I think one of the games that capture the magic of the sky is Witcher 3: Wild Hunt and hopefully the screenshot I attached here helps you visualize what I mean. (click the image for original size)
</p>
<p>
  Starting with the sky, couple other ideas followed immediately. First - the idea of showing the player the current location, including foliage, structures and features, in a 360-degree panorama. Then, including distant features like trees, hills and mountains to provide players with some context of what's surrounding them as well as a way to navigate the world using known landmarks.
</p>
<p>
  The more I pictured this, the more it felt like perhaps the game could be stripped of the rigidness of most of the UI. Perhaps there's even some room to explore some elements of a <a href="https://en.wikipedia.org/wiki/Puzzle_video_game#Hidden_object_game" target="_blank">hidden object game</a>? For instance - trying to hunt for an animal could start with spotting it's footprints and interacting with them.
</p>
<p>
  That was the point when I decided I need a prototype to help validate some of the ideas, like being able to navigate and render the game efficiently. After around two days of putting it together I had a rudimentary engine to present the world in this fashion (even including a parallax scrolling!), all rendered dynamically based on the map image supplied to the game. Things were looking promising for a moment.
</p>
<p>
  To help you picture this, here's a very short video that shows this early prototype, including the parallax scrolling:
</p>
<div>
  <video src="./images/2021-02-12/prototype.mov" controls autoplay loop />
</div>
<p>
  Immediately I started testing how well this can be used to navigate the map and things fell apart really quickly. Trying to pinpoint your location on a larger map based on what's around you was absolutely doable, even by someone who has some trouble navigating maps. But the discrete nature of paths and the inability to convey the distance travelled means that going from location to location felt almost random at times. I'd choose a path toward what seemed like a forest only to find myself in a location with barely any trees, since the "forest" that I saw was just a thin stretch of trees that I ended up walking past. Even simply trying to walk toward a mountain would be frustrating as times and times again I'd find myself closer to it, but without any path that leads directly further in the direction I was heading.
</p>
<p>
  That short experiment showcased to me that navigating using this system would be impractical or just downright infuriating at times. It was time to go back to the drawing board. Still liking the idea of presenting the sky, I considered adding a separate travel map view, one that closely resembles what I showcased in my earlier update <a href="#/updates/3" target="_blank"?>posted here</a>. That'd solve the navigational issue, but the question remains - what is the purpose of the horizon view for this game and is it actually improving the gameplay?
</p>
<p>
  Around that time I also stumbled upon another game, due to completely unrelated reasons, which I think presents really well what a parallax scroll and this perspective can do to bring a world to life. The game in question is <a href="https://thewanderingvillage.com/" target="_blank">The&nbsp;Wandering&nbsp;Village</a> and I feel it does a great job of presenting the world in a way I would hope to present it. And that helped me realize the sheer amount of effort required to make a world in this way truly beautiful and really shine. 
</p>
<p>
  With the map view coming in regardless and with the amount of work this presentation would require, I decided to scrap this idea from the game, at least for the time being.
</p>
<p>
  I'm curious to hear what you think! Do you agree that sky can create a wonderful and evocative imagery? Do you think I made the right call scrapping this approach? How would you feel if Soulforged included hidden object game mechanics? Please share your thoughts on our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a>!
</p>
`
};
