export default {
  title: `Tech and tools - behind the scenes`,
  date: `2020-12-31`,
  intro: `Today I wanted to tell you a bit more about the tooling I'm using to create Soulforged.`,
  fullText: `
<p>
  Before we dive into it - I should probably say right out that this update will get increasingly technical as I cover virtually every single tool I'm using. That said, I still hope I manage to present and explain each of them in an accessible and digestible manner.
</p>
<p class="small">
  Sidenote: I'm not sponsored or in any way associated with any of the third party software I mention here. I just really like them. :)
</p>

<h2>Visual editors</h2>
<p>
  Let me start off with what I think are the prettiest of the tools I'm using, before I end up boring you with details of how code is created and managed. ;) 
</p>
<h3>Inkarnate</h3>
<p>
  First we have some real eye candy. <a href="https://inkarnate.com/" target="_blank">Inkarnate</a> is a platform that allows you to build gorgeous maps in a very easy fashion using a large number of good-looking and varied assets. For anyone familiar with Photoshop - 
  <span class="image-frame right" style="width: 40%;">
    <a target="_blank" href="./images/2020-12-31/scene-0.c282e930.jpg">
      <img src="./images/2020-12-31/scene-0.c282e930.jpg">
    </a>
    <span>Copyright by Inkarnate</span>
  </span>
you may be inclined to think of it as a glorified copy of said software, but I think the way it manages layers & allows you to use the randomized stamps - it's really a massive quality of life step up compared to standard graphic editing software. Added with the assets it really feels like an enormously powerful tool, even in my unskilled hands.
</p>
<p>
  The licensing seems pretty straightforward as well, with a fairly low subscription of 25 USD <b>per year</b>, which enables me to use the maps commercially. It's all I could ask for really.
</p>

<h3>Krita</h3>
<p>
  Next I want to bring up <a target="_blank" href="https://krita.org/en/">Krita</a> - a free alternative to the already mentioned Photoshop. Back in the day I worked a little bit with Photoshop and I guess that's the reason why I was really happy when I found Krita, which has a similar interface and all the core features that I need (selections, layering, filters, etc).
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/krita.jpg">
    <img src="./images/2020-12-31/krita.jpg">
  </a>
</span>
<p>
  I've been using it since Soulforged 1, including recolors of items, simple transformations as well as drawing the images for lean-to and the mill (both my own creations from scratch). The only bad thing that needs mentioning is that the macOS version was at times a bit janky, hanging up or disabling some controls, but since I switched to Windows - it is a really smooth ride.
</p>
<p>
  I'd probably consider sticking with Photoshop if it weren't for the egregious pricing model they have right now - they ask for 30 GBP <b>per month</b> (more than Inkarnate asks for <b>yearly</b>) and they even include a misleading pricing on their website suggesting it's only 20 GBP/month - but turns out that's only if you buy a yearly subscription.
</p>

<h3>World Editor</h3>
<p>
  Next I have a small homegrown solution that is still very much in development - and that is the World Editor. This one isn't a graphical editor, but it's more about defining locations, paths, resources and everything that makes the map image come to life and become part of the game.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/world-editor.jpg">
    <img src="./images/2020-12-31/world-editor.jpg">
  </a>
</span>
<p>
  It's a simple web-based application with an even simpler server behind it, all created by myself. It's not much to look at yet, but I plan to keep expanding it providing everything that's needed to make the world interesting, without editing config files.
</p>

<h2>Work organizers</h2>
<p>
  Using editors is all nice and fun, but to make sure I keep on delivering and focus on the right stuff, I am using some software to keep things tidy and organized.
</p>

<h3>Jira</h3>
<p>
  Starting with <a href="https://www.atlassian.com/software/jira" target="_blank">Jira</a>, which is basically a ticketing/task tracking system. Allows you to store tickets/tasks with descriptions and comments, store files, categorize and prioritize them. I used it extensively in my professional capacity, among a handful of other ticketing tools I had opportunity to work with in different companies. For me, while it can be a little annoying at times, it's still miles better than anything else I have used, professionally or privately.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/jira.jpg">
    <img src="./images/2020-12-31/jira.jpg">
  </a>
</span>
<p>
  The primary reason for me to use Jira is to offload my head about all those different things that need doing. When thinking about a single feature for the game it can quickly grow to an unmanageable list of intertwined tasks and things to remember about. By writing them down it's easier for me to focus on one particular step that needs doing right now. As an added bonus, ticking off those items as they get done can be a small morale booster as I see the progress I'm making. The list doesn't need to be stored in Jira, it can be just on a piece of paper, but I find that not centralizing those items leads to another problem - and that is having a large number of separate lists, which somewhat ironically can be very demotivating.
</p>
<p>
  All that and I get to use Jira, hosted remotely by the company that made the software, for the low price of free. :) 
</p>

<h3>Clockify</h3>
<p>
  Next tool might seem a bit unexpected for a self-employed game developer, but I opted to use a simple web app (again, free) called <a target="_blank" href="https://clockify.me/">Clockify</a> to track my working time.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/clockify.jpg">
    <img src="./images/2020-12-31/clockify.jpg">
  </a>
</span>
<p>
  Being self-employed I have no automatic obligation to keep track of the time I spend working, but it's something that I find still has value for me. It helps me make sure that I stay focused and that I have a pretty clear distinction of what is my working time and what's my leisure time.
</p>
<p>
  And in case you're wondering - yes, the screenshot shows my actual time reporting for the recent days. :) And yes, I did take a bit of time off for the Christmas break. :)
</p>

<h3>Discord</h3>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/discord.jpg">
    <img src="./images/2020-12-31/discord.jpg">
  </a>
</span>
<p>
  I think it's also worth mentioning <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> as another tool that I use fairly regularly. 
</p>
<p>
  From announcing these articles, discussions with the SF players, calls and chats with some contributors to SF2 - it's the platform that allows me to stay connected with you folks and that makes it really important to me!
</p>

<h3>Physical aids</h3>
<p>
  It's not all computers, as I find I can still appreciate and benefit from some time away from the screen.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/whiteboard.jpg">
    <img src="./images/2020-12-31/whiteboard.jpg">
  </a>
</span>
<p>
  The two most important physical tools for me are a notebook and a whiteboard (which I bought a while back - I was surprised how affordable and useful a magnetic whiteboard is!).
<p>
  Notebook is essential when I need to take a step back and think through some design or solution, break it down, extract the essential bits. Once that's done I can put the coherent bits of my scribblings into Jira, including any outstanding open question I may have.
</p>
<p>
  Whiteboard on the other hand is great when I need to do some quick math or scribble a graphic representation of a problem I'm trying to solve. 
</p>

<h2>The very technical stuff</h2>
<p>
  No we're going to dive deep into the more technical stuff that I use.
</p>

<h3>GitHub</h3>
<p>
  For anyone who ever worked with code - you're very likely familiar with version control tools. For those that did not - a bit of explanation is in order.
</p>
<p>
  To start off - program code, which is the essence of any working application, is basically just text. And similar to writing a book, rather than writing line by line from the beginning to end you would start by writing an outline first, then fill in some details and continue to change and add more chapters and fragments. Occasionally you would also scrap some parts as they no longer fit with the rest of the book. 
</p>
<p>
  This process takes weeks, months or even years. That means a whole lot of changes to the code (or book) and sometimes the reasons why a change was made will be forgotten and at the same time those reasons may be very important and relevant while making further changes. And that's where Git (version control application) comes in. Rather than just keeping the latest version of the code, it allows you to retain the full history of changes you make to the code and later browse them if you need. Similar to Google docs version history, for instance. 
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/github.jpg">
    <img src="./images/2020-12-31/github.jpg">
  </a>
</span>
<p>
  What Git also does is that it helps you keep the code (and all of history) synchronized on multiple machines - which supports collaboration and also allows you to have a centralized repository as the main reference point. And that's where <a href="https://github.com/" target="_blank">GitHub</a> comes in - which is a platform that provides that centralized space to keep the code.
</p>
<p>
  In addition to storage it also provides some additional tools and interfaces to simplify some tasks.
</p>
<h4>Why GitHub?</h4>
<p>
  There are several different platforms that can be used as the main repository for your code. I opted for GitHub because of its reliability, tooling and available space. It's only possible to use GitHub like this for me since earlier this year when Microsoft bought GitHub and enabled private repositories for users on a free tier.
</p>

<h3>SourceTree</h3>
<p>
  Git is nice, but save for some purists - using it can be a bit of a doozy. There is a whole host of commands to memorize and they need to be really well understood to avoid possibly damaging the repository.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/sourcetree.jpg">
    <img src="./images/2020-12-31/sourcetree.jpg">
  </a>
</span>
<p>
  For me - I prefer to use a graphical interface to browse and manage my code repository. This makes it much easier to understand the structure, branches and everything that's going on with the repository. I opted to use application called <a href="https://www.sourcetreeapp.com/" target="_blank">SourceTree</a>, made by the same company that created Jira. It also includes all of the essential actions you may need to manage the repository and the changes.
</p>
<p>
  It's not perfect, particularly when it comes to configuring authentication you can expect things to get a bit bumpy. Still it's sufficient to my needs and it's still the best tool I encountered so far.
</p>
<p class="small">
  Sidenote: For anyone wondering why the history seems to straightforward in this report - this is because I use merge & squash in GitHub, which creates this simple to study history. And if I ever need the details of the changes made on the branch, that's still available via GitHub and their Pull Requests.
</p>

<h3>Windows (& Windows Subsystem for Linux)</h3>
<p>
  One of the more unorthodox choices I made was the operating system I use to develop the game. While I previously used macOS for this, I grew increasingly frustrated with that system and not long ago I lost the last firm reason I had for sticking with macOS. And so I decided to move to Windows.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/wsl.jpg">
    <img src="./images/2020-12-31/wsl.jpg">
  </a>
</span>
<p>
  I expected the switch to be a very painful process given that Windows CMD.exe tool is very limited & clunky. But that's when I discovered <a href="https://docs.microsoft.com/en-us/windows/wsl/install-win10" target="_blank">Windows Subsystem for Linux</a> and it changed everything. I now have the window management of Windows, the utility and drivers sorted for me all automatically by Windows and I get to have a terminal with a standard Linux commands and file system.   
</p>
<p>
  Microsoft also included a proper <a href="https://www.microsoft.com/en-gb/p/windows-terminal/9n0dx20hk701?activetab=pivot:overviewtab" target="_blank">terminal app</a> (installed separately), which makes the whole thing really come together. There are some small quirks I had to work around, but overall it's an amazing result for how little effort it required. 
</p>

<h3>Webstorm</h3>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/webstorm.jpg">
    <img src="./images/2020-12-31/webstorm.jpg">
  </a>
</span>
<p>
  Writing code requires using a text editor. Some people prefer a simple text editor (Sublime, vim), some prefer Integrated Development Environments (IDE). I found myself highly reliant on the tooling, controls and navigation that <a href="https://www.jetbrains.com/webstorm/" target="_blank">WebStorm IDE</a> provides. I still feel I use it in a rather simplistic manner, closer to a smart text editor, but there are features like multi-caret editing that can really shine.
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/tip.gif">
    <img src="./images/2020-12-31/tip.gif">
  </a>
</span>
</p>
<p>
  I also use some runtime options and of course extensive file search and navigation. It may require a little bit of setup to adjust the code inspections (highlights of possible problems in the code) and exclude folders to get good performance and accurate search results. Still, I take that as a part of the parcel of an IDE - I find all of them require some configuration to adjust them to best fit individual developer.
</p>
<p>
  I've been using it since before I started the first version of Soulforged and for a few years have a recurring subscription that now costs me around 32 GBP <b>per year</b>.
</p>

<h3>Jest</h3>
<p>
  One other tool that I find new appreciation for and start to rely more and more on is <a href="https://jestjs.io/" target="_blank">Jest</a>. It's a framework for writing what is known as automated tests in Javascript.
</p>
<p>
  To explain a bit what automated tests are I want to bring back what I said about writing code in the GitHub section of this article. The code is an ever-changing thing. As new changes are made to the codebase they may end up breaking some functionality that was written and manually verified to be working correctly earlier.
</p>
<p>
  That's where automated tests can be very, very useful. To ensure that the code works as expected, we can write a bit of other code that will verify this for us. For example - we write a simple test that when a character is at the same location as a hostile creature - they can attack that creature. Later on we may add a feature where creature needs to be first scouted before it can reveal its status, name, details and actions related to it. With that change in place - you would expect the test described earlier to fail, since character cannot attack a creature they haven't scouted - they don't even know if they are hostile. This failure may help you realize that you need to add code to make sure that creatures in the same location are always considered fully scouted.  
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/jest.jpg">
    <img src="./images/2020-12-31/jest.jpg">
  </a>
</span>
<p>
  While the developers still have to think of and write every single test themselves - what Jest allows me to do is make them much more robust & focused. It also provides a great summary of the results, error details and also includes coverage testing - which is checking how many of your lines of code are tested using the automated tests.
</p>
<p>
  Just recently I went through an exercise of upping the test coverage to 100% for all the back-end code. That enabled me to very quickly perform a refactor sprawling 17 files and around 200 lines of code. The tests gave me confidence that my change was complete and that all the features built so far are working as expected.
</p>

<h3>Codefresh</h3>
<p>
  And that brings me to the last tool I want to talk about today and that's <a href="https://codefresh.io/" target="_blank">Codefresh</a> - a continuous integration tool. A grandiose name, but the purpose is really quite simple - it helps me ensure that all of the automated tests are executed and are passing with any changes I'm about to integrate into the codebase.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2020-12-31/codefresh.jpg">
    <img src="./images/2020-12-31/codefresh.jpg">
  </a>
</span>
<p>
  I checked out a small number of solutions and it was the only one that I found that offered something practical at a free tier. For an indie dev with limited resources and also limited number of changes on daily basis - it's exactly what I need. Setup was rather minimal and modifying it is simple enough.
</p>

<h2>Wrapping up</h2>
<p>
  Unless there's something that slipped my mind, that basically covers all of the different tools that I use to develop game like this. I'm hoping it might provide some interesting insight into all the things that are involved and also highlight how one can keep cost of making a game like this relatively low.
</p>
<p>
  Have you used any of the tools I mentioned? Any particular issues or shortcomings you encountered? Do you know some really strong alternatives you'd like to recommend? Let's have a chat on our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a>!
</p>
`
};
