export default {
  title: `The price is right?`,
  date: `2022-01-03`,
  intro: `I have finally settled on the pricing model for Soulforged and I'm very excited to share it with you!`,
  fullText: `
<p>
  Before we dive into what the model is I just want to reiterate my commitment to having no predatory monetization models. That means there will be <em><b>no</b></em> micro-transactions, <em><b>no</b></em> macro-transactions, <em><b>no</b></em> in-game purchases, <em><b>no</b></em> purchasable cosmetic items, <em><b>no</b></em> loot boxes, <em><b>no</b></em> expansions nor "DLC". Also there'll be <em><b>no</b></em> up-front cost.
</p>
<p>
  But that is old news by now. The real news is - there will be <em><b>no subscription</b></em>. The game will remain free to enjoy, for everyone. I previously mentioned considering tying having subscription to patreon/donations amount, but that's no longer the case.
</p>
<p>
  What this means is that all of the content that follows will be available to all players, new and existing, without any fee or monetary cost.
</p>
<h2>Patreon goals</h2>
<p>
  The next question I expect to be what happens to Patreon and donations. These are staying, they stay open and there'll be goals attached to them.
</p>
<p>
  The goals will be tied to how much time I can spend developing Soulforged. Don't worry, unless there's some extreme emergency the future of Soulforged looks bright. I'm committed to working on Soulforged full time for the whole year of 2022. 
</p>
<p>
  Then, toward the end of 2022 I will be looking at the levels of funding and the options I'm looking at are: 
</p>
<ul>
  <li>If I get less than 1000 GBP per month via Patreon and Paypal combined, I'll be looking at finding myself a full-time job and will be moving Soulforged back to being a hobby project</li>
  <li>If I get between 1000 GBP and 3000 GBP per month via Patreon and Paypal combined, I'll be looking at finding myself a half-time job, leaving rest of the time for Soulforged</li>
  <li>If I get at least 3000 GBP per month via Patreon and Paypal combined, I'll be sticking with Soulforged working on it full time</li>
</ul>
<p>
  Additionally, any money that I may get beyond the last goal will be split with at least 80% of that money going directly into the game via assets or hiring additional members of the team. 
</p>
<p>
  Just to reiterate - the review would happen toward the end of 2022. Up until that point I'll just work on Soulforged full-time regardless.
</p>
<p>
  I also want to stress how much the support that some folks have shown me up to this point matters to me! It's one thing the joy I get from watching people engage with my game and have fun in there. But to those who pledged on Patreon or via Paypal - thank you, your vote of confidence and your support is what gives me the hope that this is worthwhile and valued and I can look to continue working on this project. Thank you!
</p>
<h2>Perks of being a patron</h2>
<p>
  I also want to talk a little bit about perks of being a patron. As I mentioned, I don't want to tie any in-game advantage to the Patreon tiers. But when implementing a login queue I felt it'd be unfair on the supporters to have to wait in the queue to access the game they show so much support for.
</p>
<p>
  At the same time, I felt it'd be unfair on the rest of the playerbase if supporters could jump in front of them in the queue - making it potentially impossible to access the game at all times. 
</p>
<p>
  That's why I decided to have the supporters skip the queue entirely and they don't count toward the queue. I believe this outcome is the best possible scenario for both free players and supporters. Supporters can expect reliable access to the game no matter its popularity. At the same time free players actually benefit from more people becoming supporters, because that means fewer people taking up space in the queue.
</p>
<h2>How we came to this?</h2>
<p>
  I'd also like to talk about how I came to this decision.
</p>
<p>
  As I mentioned before I believe that the game will benefit hugely from having no barriers of entry. This means community can grow freely and the game design is not conflated with having people stick around in the game for longer.
</p>
<p>
  I initially thought that free tier with subscription would be the way to go, but it'd still divide the playerbase. Then there's the problem that there's no single good amount for monthly subscription.
</p>
<p>
  I also felt that tying the donation goal to whether the game stays free creates this strange dynamic. Not only it feels like I'm keeping the game hostage, but also the folks who can support me financially then contribute toward me giving the thing they enjoy and support to others for free.
</p>
<p>
  The way I look at the current model instead is that those who support me financially enjoy this thing I created and would like to see it grow and become more.
</p>
<p>
  Finally, a quick word on where the numbers for the support goals come from. As you probably know I have background working professionally as a fairly competent software engineer, if I can say so myself. That kind of job pays well enough, but it needs to be said that no other job I've ever had gave me the kind of fulfillment that working on Soulforged does. Still, there's bills to pay and I'm a big fan of not starving, so in the end I'd simply be really happy if it could bring me enough income to get by, even if it's a fraction of what I could be making working corporate.
</p>
<h2>Closing thoughts</h2>
<p>
  I hope this is welcome news to any and all players of the game and my supporters. As a quick reminder - the game is now available to play in Early Access! Despite the label, it's the real deal and any progress may will be preserved going forward, so this might be the best time to jump into the game!
</p>
<p>
  Thank you for reading and hope you have a wonderful day!
</p>
`
};
