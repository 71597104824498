export default {
  title: `Early alpha takeaways and the way forward`,
  date: `2021-04-23`,
  intro: `It has been two weeks since the early alpha started, and I couldn't be happier about the outcomes.`,
  fullText: `
<p>
With over 20 registered players and 15 players active as of the time of writing, I am very glad I decided to start the tests with such an early version.
</p>
<p>There are several takeaways I'd like to go over and share with you.</p>
<ul>
  <li>
    <em>The action point system works!</em><br/>
    The one massive unknown going into this test was the action-points system. Would it feel quirky, disconnected or out of place? As it turns out - it works great! I had some really positive feedback about the impact action points accumulated overnight have on the enjoyment. I also saw several cases where the system helped foster collaboration as players could respond to calls for help right away, rather than it taking hours. While some questions do pop up, it seems that the solutions put in place are still pretty understandable.
  </li>
  <li>
    <em>Feedback helps me prioritize</em><br/>
No huge surprise here really, but the player feedback was absolutely crucial! On-point problem statements, good suggestions, great bug reports. All of these were very useful, but I feel what really helped the most was how feedback helped me prioritize tasks. I have a very long list of things I want to do and include, but at the same time I didn't want to delay this early alpha test until I had all of those bits in place. This includes some quality-of-life features that players were used to from the old game, but that's where feedback helped focus my attention on those features that really matter to the players.
  </li>
  <li>
    <em>The reworked architecture is performing well</em><br/>
The huge reason behind the major rewrite of the code from the original Soulforged Prelude was that the engine of the old game was really more of a proof-of-concept and wasn't performing nearly well enough to really allow large population of players. I'm very happy to report - this is indeed now solved in the current version. To give you some idea how they differ - old server was running a dual-core machine and an empty world would take 30% CPU, going all the way to 100% when busy; this new one is single core and while busy with players <em>peaks</em> at 5% utilization. On top of that - it feels like the new architecture is very stable, with the only downtime caused by my mismanagement of resources on the server.
  </li>
<p>
I want to use this occasion to send, once again, a heartfelt "Thank you!" to every person who engaged with this early alpha and even more so to everyone who provided the feedback!
</p>
<p>
A special thanks goes to my Patreon supporters - your vote of confidence is a tremendous deal for me and I can't thank you enough for your support!
</p>
<p>
This test definitely helped me build up more confidence in this game model and I continue to be fully committed to realizing the vision behind Soulforged.
</p>
<h2>The target feature list</h2>
<p>
I think now's a good time to talk a bit more about what is in store for Soulforged at a point where the game will be considered complete. I won't be going into details of every single interface panel, but speak more about the exciting things you can expect to see in the game.
Each item in this section will be divided into two fragments: the must-have and the nice-have. The must-haves are things I'm fully committed to deliver and I'm confident I can deliver as things are right now, all that is needed is time. The nice-haves are a little different - it's things I'd absolutely love to include in the game, but those are things that will require skills that I don't necessarily have, meaning they are subject to how much budget I will have available.
With that clarified, let's dive in!
</p>
<h3>Game map</h3>
<p>
<u>Must-have:</u> The current world map is fine, but it's missing one absolutely critical feature and that is displaying the infrastructure - showing the towns, roads, bridges and other features that players are able to construct. While this may sound like a lower priority, I believe it's absolutely crucial to really bring the world to life and allow players to enjoy seeing their community develop.
</p>
<p>
<u>Nice-have:</u> Confession time - I'm still undecided if I actually like the new way the map is presented, so I may want to tinker further with the representation. What I'd like is to flesh the map out more and make it overall a nicer thing to look at.
</p>
<h3>Game world</h3>
<p>
<u>Must-have:</u> I plan to have a large game world, with varied regions, climates, conditions, events, dungeons and lore.
</p>
<p>The game world is intended to be "tiered" meaning accessing and operating in some locations will be more difficult than in others. At the same time I will ensure that having your character perish doesn't mean you can no longer function in the place you chose to call home, even if it's dangerous and unforgiving. You won't need to climb back through all the tiers, as it's mainly your equipment and possessions that will be crucial for your survival.
</p>
<p>
<u>Nice-have:</u> While the world as a whole is an essential part of the game, the big question remains about its size and variety. I'd love to include an absolutely huge and varied world, filled with interesting locations and events, with unique look & feel, great writing, sound design and atmosphere. All of that is a question of how many resources I'm able to put into development of this world.
</p>
<p>
One little extra I'd like to add is to have some variety with the look of the interface to reflect the region you find yourself in, adding to the overall feel on the world. Leaves and vines decorating the interface in a lush, green land; icicles hanging from the windows in snowy, frozen wastelands; magma seeping through the cracks when you sit in a deep, underground cavern.
</p>
<h3>Player housing</h3>
<p>
<u>Must-have:</u> Absolutely crucial for me is for you to be able to call a place in this world your home. For that I plan to include the system similar to what Soulforged Prelude had where you can set up your own house, be it a small tent, a cottage or a part of a large villa. Next you can decorate it to your heart content and to your character benefit - gaining various buffs and positive effects based on the decorations you used. With sleep now absent from the game I still need to come up with a system of how these effects are applied to your character, but I want to keep it convenient and hassle free.
</p>
<p>
<u>Nice-have:</u> What I'd absolutely love to do here is to provide players with a full view of the inside of their house, with all the decoration on full display. Showing you the rug you've just put in or a banner you hung up on the wall. The technical execution is simple enough and I have the means to deliver on that front. What's challenging for me is having quality assets that can be used to display all those wonderful decorations.
</p>
<h3>Farming and animal husbandry</h3>
<p>
<u>Must-have:</u> I plan to include farming as one of the core roles that players can undertake. This time around I'll be doing away with fully-communal farms instead making farms private, but possible to be shared with your friends or wider community. I'll want to have farming be more involved, removing weeds, providing fertilization and watering your crops. Well-tended crops will have higher yield than a plant-and-forget would produce, possibly including some rare produce from some of the plants! Also in the store is animal husbandry - keeping, breeding and collecting produce of various animals. Same as with farming you can expect to have your own private space for your animals, which you will be able to share access to with your friends.
</p>
<p>
<u>Nice-have:</u> Again, the presentation is the highly desired point I'd like to include. The interface must still be quick, nice and convenient, but I'd want to be able to present to you how your weed-infested garden really looks like. Or a well-tended one, that'd be up to you! Showing different crops and different growth stages, your animals roaming around, making farming satisfying as well as profitable.
</p>
<h3>Research system</h3>
<p>
<u>Must-have:</u> This is as straightforward as a complex system can get. I want to include all of the joy that the original research system included in Soulforged Prelude brought, while solving the pain points and difficulties it posed - the perceived randomness of some outcomes, the limited ability to pursue research in specific direction (housing, armour, food, etc), the frustration associated with wasting resources over and over and the trivialization of the system that could be achieved using automation, scripts or spreadsheets. The exact design of this system is yet to be finalized, but I hope to bring all the mystery and excitement the original system had.
</p>
<h3>Player trading</h3>
<p>
<u>Must-have:</u> Next on the list is player trading. To start with it includes all the things you would expect and could have experienced in Soulforged Prelude: direct trades between players and trade listings that allow you to automate what items you have on offer for players to purchase. What's new will be a highly requested feature of player-owned shopping stalls. Convenience is the name of the game in this new incarnation of Soulforged, so you can expect to be able to place items in a stall you own, set the prices and have players be able to automatically purchase them, even when you're out of town.
</p>
<h3>Player pets and mounts</h3>
<p>
<u>Must-have:</u> The option to tame and befriend wild animals and beasts. Once they are made your friend you'll need to take care of them, feed them and tend to them. In turn they'll help you by defending you in battle, produce and gather resources or speed up your travel. By spending time and tending to your pets your character will be forming bonds with them, allowing you to further train them, making them ever more helpful.
</p>
<p>
<u>Nice-have:</u> In similar vein to previous points - there a lot that can be done to really bring a pet to life, with animations showing the various interactions you can have with them. Another aspect is customization, where you can provide additional equipment to your pets (collars, saddles, muzzles) to further enhance or train them.
</p>
<h3>Currency system</h3>
<p>
<u>Must-have:</u> The game will include an in-game currency system, earned by simply playing the game, with additional gains based on collaboration and helping other players. This currency can be used for trades between players as well as purchasing additional character perks, unlocked by exploring the world and growing your character.
</p>
<p>
<u>Absolutely critical:</u> This currency, like everything else outside the game subscription, will not be purchasable for real world money.
</p>
<h3>Naming system</h3>
<p>
<u>Must-have:</u> Everything in the game can be named. I mean - every single item type, monster type, town - all of these will start by showing them as unnamed and then it's up to the player to decide what label they want on it. Critical for this system will be item description - every item will have at the very least a brief description that will help you identify the item and its purpose. Whether you decide that this underground creature should be called a Lizard or a Salamander - that is completely up to you! To help players manage the names a little, an option will be included where you can browse what other players called it and how popular those other names are.
</p>
<h3>Expanded combat system</h3>
<p>
<u>Must-have:</u> I'm relatively happy with the combat system as it is now, so there's not much to be said here except of course expanding the arsenal of weapons, weapon types and armor available to the players.
</p>
<p>
<u>Nice-have:</u> This point is a unique one, since there are two different, separate angles in which I'd like to expand the combat system. As I am not ready to commit to guarantee that either of those will be able to make it into the game, both are considered "nice have".
</p>
<p>
First is the combat presentation - I'd like to display to players a fitting background, full character model, with all the equipment displayed on them as well as fully animating the attacks, hits and misses, by both player character characters and monsters. This will of course require considerable amount of work and also some expertise in animation. To animate something is a pretty simple thing, but to make it look nice and not jerky that's a whole different story.
</p>
<p>
Second for combat is combat moves. This means that combat becomes much more active, where you select attack target and pick an attack move from a handful of different moves you have learned. Depending on the move you select it could alter the damage you deal or receive, hit chances or apply status effects. The reason I'm not ready to commit to this just yet is that I would have to design a system that makes the combat engaging, fun and interesting. There are ways to implement it so that combat simply becomes an execution of a rotation or boils down to selecting the best move and spamming it. I believe this would make combat worse and the game would be better off not having it in at all in such case.
</p>
<h2>Immediate plans</h2>
<p>
I hope the above is getting you excited for what's to come, but at the same time - it'll take time to make all that happen. As such you might be curious to learn what you can expect to happen in the next few weeks.
</p>
<p>
My plan right now is to focus on adding the final core systems: research, naming, trading, housing, farming. I expect they'll require additional iteration as we go, but I feel the game needs to have the core in place to really tie-in the experience. Once these are in place I'll be working on the first region of the final game, which you can expect to be bigger than the island included in pre-alpha and is also intended to be the "demo" area that will be available to everyone for free, even once the game is fully launched.
</p>
<p>
I hope you enjoyed diving into the future vision for Soulforged and that you're eager to see it all come together. Anything particular that makes you excited? Any concerns you'd like to share about any particular feature? Please let me know on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server!
</p>
`
};
