export default {
  title: `Combat Update is here!`,
  date: `2022-04-05`,
  intro: `Today I'd like to share my perspective on the recent release as well as the immediate plans going forward.`,
  fullText: `
<h2>Thoughts on release</h2>
<p>
    As all the current players will likely know by now, the combat overhaul update was finished and released a week ago. I've been monitoring for errors and listening to feedback, and overall I feel this was time well spent and a good direction for the game.
</p>
<p>
    There's a couple of things that went well and I think it might be worth talking a bit about each of them.
</p>
<h3>The Stability</h3>
<p>
    Overall the game has been very stable with one crash that happened due to change to equipment system (adding utility slots). Last time I released a major combat update it was for SF:0 and admittedly it required a rollback since by accident all creatures were dealing 10x damage. But this time I have a large host of automated test and they did catch a bunch of irregularities, from server crash to breaking the balance in a few places.
</p>
<h3>The Balance</h3>
<p>
    Speaking of balance, really happy with this one as well! I had to make some post-release adjustments (particularly buffing cutting damage type), but overall the game kept its balance while at the same time added a bit of flavour. Large packs of rats are now slightly more troubling, the players feel more consistent about the result of each encounter (due to armor change) and some creatures were at first considered to be weakened really hard, only for some players to actually point out they can be as much threat as before or sometimes worse. Which is exactly what I was hoping for in that particular case!
</p>
<p>
    This is something that I really enjoyed when finishing the work on this overhaul before the release and that's adding abilities for each of the creatures. I was worried that I'll struggle with this, but in practice it felt great to add some specific mechanics, to almost flesh out the character of those monsters and animals, while also knowing that this adds the much-needed depth to the game! 
</p>
<h3>The Depth</h3>
<p>
    Speaking of depth - combat gameplay is now considerably more involved! When working on this system I had lots of doubt whether I'm making it too complex or keeping it too simplistic.
</p>
<p>
    For one I decided to keep things simpler by making all weapon types basically rely on a fixed set of combat moves. So while the mechanism is there for each type of, say, knife to have a completely different set of moves, I instead decided to keep them mostly uniform. This means that players have easier time reasoning which weapon is better for them between two of the same type, but it also means that it's easier for me to keep the game balanced.
</p>
<p>
    Another simplification I went for is that almost all moves that are sharing a name will all have the exact same effect - same duration of effects, same number of stacks, etc. This is, again, something that the game engine fully supports to customize for each and every weapon and move they have, but I decided against that.
</p>
<p>
    I made those calls for a few reasons. Most notably I didn't want to prolong the development of this overhaul and I was worried that additional variation would actually make it too complex to enjoy engaging with it. It felt like walking a tightrope, except the only way to know if I messed it up was to release it. And now that it's live the initial feedback suggests to me that it hits that sweet spot where it's both fun and engaging.
</p>
<p>
    There's still probably things that will come out that will beg for an adjusted, but I heard from some players that engaged in theory-crafting and from some that just want to bash enemies in the head - and both types of players appear enjoying their time in the game with this new system! Still, more work and more polish is needed!
</p>
<h3>The Polish</h3>
<p>
    Speaking of polish - I think this is another thing that went really, really well and that there's a bunch of things that's unfinished and in need of polish. I know, that just sounds plain stupid but please hear me out!
</p>
<p>
    When working on a feature like this, there's a level of satisfaction from completing all the little tasks and tickets needed to deliver it. The problem is that by focusing on the very minute details this postpones the release and this means that the feedback will arrive later. 
</p>
<p>
    So there are a couple of quirks that I was either aware of or I expected might be happening. For instance - the statistics for each of the moves show different things when looking at them from the item details vs character panel. Or the issue when the combat is over, but some turns still take place before it's all wrapped up (I thought I got that one, but wasn't sure; turned out I didn't).
</p>
<p>
    These are all real issues that I'd prefer to have addressed before the update is released. But not at the cost of postponing it for another 2 or 3 weeks. Now that the update is out and I went over the feedback I have much more confidence in a whole lot of things that were included in this update. This means that addressing the outstanding issues I don't run risk of fixing systems that are better off removed altogether but it also means it is so much easier for me to prioritize things that actually  matter to the players, based on all that feedback I'm getting! 
</p>
<p>
    So yeah, there's big part of me that wanted to postpone the release but I'm very glad I didn't.
</p>
<h2>What's coming next</h2>
<p>
    Now let's get to what I think will be the more interesting section - what happens next.
</p>
<p>
    There's a ton of stuff that I want to do as well as things I believe need to be done. Still, there's only so many hours in a day and so I need to prioritize.
</p>
<p>
    My first priority is to serve the amazing community that is currently engaged in the game. I have postponed working on lots of feedback and smaller bug reports while I was moving house and while working on the combat update, so I want to deliver on that now and going forward I plan to spend the first half of every day addressing feedback and bug reports.
</p>
<p>
    That means going over all the pinned messages in Discord's #feedback and #bug-report channels as well as the tickets raised in Discord (using the bot) and working on them one by one. I started with that yesterday already, today was another day and I plan to continue working on this every working day for as long as there are items there that I said I will address in one way or another. 
</p>
<p>
    That leaves me with the second part of each day and that's where I will be focusing on other things. Again, lots of competing priorities, but my top one will be growing the player-base. Reaching out to news outlets, communities, learning more about interactive and spreading the word about a game like mine. 
</p>
<p>
    Another thing that I will also be doing in the afternoons is starting the hacking bounty campaign. It's a bounty intended for more technologically-inclined individuals and it's about challenging them to try to get the information out of the game that they are not supposed to have at whatever stage they are. For instance - any recipes they haven't researched yet, finding out which items are needed for research without spending any items, getting images of items they are yet to discover - and so on. 
</p>
<p>
    The game engine is specifically coded around preventing this information from being retrieved, so I'm curious to see if I managed to achieve that and if not then learning where the gaps are.
</p>
<p>
    It is something that's been on my mind for a while and I think it might also attract attention of some folks who might be interested in playing the game as well, maybe partially because I hope I deliver on this front. I know there are some players right now that have quite some experience working with tech, so I imagine they might enjoy it as well! 
</p>
<p>
    That's already a lot, but there's way more. I could mention how I want to open-source the client code, how I plan to add lots of more content and new land, there's pets and husbandry to be done, there's the display of character equipment of the model, animate and add sound to fight and actions... and that's just literally off the top of my head.
</p>
<p>
    Ending today's article I want to give a massive shout-out to the very kind folks who are supporting my project through Patreon or PayPal - these are not large amounts of money, but I can't express how thankful I am and how much it matters to me, each and every person who contributes! I want to say - your contributions are adding so much to my resolve and they help me every day in realizing this dream. Thank you!
</p> 
<p>
    And finally - thank you for reading and I hope you have a wonderful day!
</p>
`
};
