export default {
  title: `My Inspirations`,
  date: `2021-09-24`,
  intro: `Today for something slightly different, I'd like to talk about all the games, media and events that inspired me to start this project in the first place.`,
  fullText: `
<h3>Lord of the Rings</h3>
<p>
  First off we have the Lord of the Rings movies. I know, I know, "why movies? didn't you read the books?". Truth be told I have yet to finish the books, but I think as far as inspiration goes for me the movies did something that book didn't capture that well - they presented a world I'd love to explore. Let's put the main storyline and the heroes of Lord of the Rings aside for a bit. The movie also presented locations like Hobbiton, Moria (before it fell) or Helm's Deep as compelling, sprawling places, full of "regular" people. I was always curious how one would go about portraying a living world like this, with every person having their own goals, wants and flaws. For me Middle-Earth is a world that is full of small and big stories and the One Ring is really just one of the elements of that world.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-09-24/hobbiton.jpg">
    <img src="./images/2021-09-24/hobbiton.jpg">
  </a>
  <span>Hobbiton. Isn't it dreamy?</span>
</span>
<p>
  When I first started exploring <a href="https://en.wikipedia.org/wiki/Massively_multiplayer_online_role-playing_game" target="_blank">MMORPG</a> games I quickly realized that all of those games were very mechanical and for me they lacked the freedom to play them in a way I'd really enjoy while also interacting meaningfully with the game systems. I'd love to play a role of a lonesome hunter in a small cabin deep in the woods, hunting for rare animals. Or become a trader in a large city, having my own shop and bringing people the things they need while working to make a profit. In many of those games you'll see "role playing" communities emerge, but it's a lot more of a make believe than actually filling those roles which is why I never found them satisfying.  
</p>
<p>
  Playing those games I kept thinking back to Middle-Earth and imagining what a truly role-playing focused game could be like.
</p>
<h3>Gothic</h3>
<p>
  I've played multiple RPGs in my life, but none of them struck me like Gothic did. I must admit - the clunky controls really discouraged me at first, but after some time I gave the game a second chance and I found gold.
</p>
<p>
  Playing the game I felt I am first and foremost a character exploring an unknown, living world. Game mechanics, skills, inventory - all that came second. The game didn't tell you what you must do, where you must go or even hint how safe you are at any given time - enemies had no levels, you knew how dangerous they were simply by trying to beat them. The world felt dangerous, the only relatively safe place were the cities you could find in the game. But even there you'd find people who'd want to take advantage of you, characters would lie or mislead you to further their goals in a way that felt genuine.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-09-24/gothic.jpg">
    <img src="./images/2021-09-24/gothic.jpg">
  </a>
  <span>Gothic. The game didn't age well</span>
</span>
<p>
   And there was a lot of small touches - for example people reacted negatively or even threateningly if you drew your weapon in town, starting a fight to disarm (and rob you while they're at it) if you didn't sheath your weapon back. If you went into people's houses they'd be getting upset, asking you to leave and again, threaten with violence if you didn't. Quests had multiple solutions and you could sometimes leverage particular character treating you favourably to get another piece of work done quickly. And you could, if you chose, solve a lot of problems with violence, even if it seems completely uncalled for.
</p>
<p>
  I feel Gothic was the game that made me appreciate how much immersion can make for an amazing experience.
</p>
<h3>Dwarf Fortress</h3>
<p>
  Ah, <a href="http://www.bay12games.com/dwarves/" target="_blank">Dwarf Fortress</a>. Where do I even begin? There's so much to unpack here.
</p>
<p>
  To those who may be unfamiliar - it's a simulation game where one of the main ways to play the game is having a small group of dwarves and building a home for them. You'd be digging in the ground, building structures, bridges, ramps, walls, traps, redirecting rivers and making items that your dwarves use an demand as grow your city to a point where everything inevitably spirals down as the dwarves go on murderous sprees or lose their mind for one reason or another.
</p>
<p>
  Let's start with the elephant in the room - at first glance, Soulforged seems like it has almost nothing in common with Dwarf Fortress. But for me there's a whole lot I learned from this game as well as its development process.
</p>
<p>
  The depth of the game is staggering. To give you some impression of how detailed the game is - characters in the game have body parts including individual eyelids, which can be damaged. This in turn means any dirt in their eyes has to be washed by them manually else it impairs their vision.
</p>
<p>
  Where most games just rely on health points, this game proved to me just how complex and involved a wound system can be, while still being legible, fun and interactive. And it also made me realize that while it may be tempting to simulate a whole boatloads of stuff, the fun lies in the systems fitting well with the game they're put in. Having a dwarf that's missing both eyes after a fish tore them off and using that dwarf to operate ballistas (since they are never scared of the incoming enemies as they can't see them) makes for a great story and neat addition to your fort. But if that were your character in a role playing game that would not make for a lot of fun or engaging gameplay.
</p>
<p>
  I was also immensely inspired by the community support surrounding Dwarf Fortress. The game itself is free and always was. The game first released in 2006 and since then it was in development by two people (brothers) who work on it full time. And as it turned out there's a lot of people who simply really enjoy playing this kind of game and don't mind showing their support. What happened is that the devs are receiving donations that amount to what I'd say is a fair monthly compensation.
</p>
<h3>Virtually every MMORPG out there</h3>
<p>
  As well as RPGs, I also played lots of MMORPGs. And sure - I enjoyed it well enough. From Tibia to World of Warcraft, I spent my fair share of hours touring the virtual worlds and engaging the game systems. But more than anything playing them I always yearned to what these games could be.
</p>
<h4>The systems</h4>
<p>
  The first disappointment is the systems. Almost all MMORPGs are combat-focused with maybe a few extra systems like crafting tacked on. Those other systems are rarely very meaningful and progressing the game is just killing more difficult creatures. The world surrounding you is very often just a set of different visuals where you do the same thing over and over, which is usually killing things. To drive this point home - the peak environmental diversity in many games is how swimming requires you to hold your breath, meaning you need to reach surface now and again.
</p>
<h4>The discovery</h4>
<p>
  Most of the game worlds are entirely explored, known and documented. In some you can still find some secrets and interesting interactions, but again they are more of a small side addition than the focus of the game. World of Warcraft routinely runs a Public Test Realm which includes new contents that is asking people to test and report bugs. This means that by the time a new expansion or content is added, almost everything about that new release is already known and documented by the players. I think an even more stark example is the upcoming MMORPG New World which is due to release in 5 days (at the time of writing) and because of the previous closed and open betas - it's all explored, documented and guides have been written about every facet of this game. It's really quite ironic for a game which theme is exploring new, uncharted world. 
</p>
<h4>The instancing</h4>
<p>
  Another problem for me is instancing. I get why it's done, it's because the game is meant to be a challenge and it's really difficult to balance a game where player groups can scale infinitely. Even harder to balance it without making it cheap (like scaling enemy hitpoints).
</p>
<p>
  But what this means is that many MMOPRGs become more of a regular multiplayer game with maybe an extensive lobby system - where the whole open world is just a lobby for people to hang out until they are ready to "play", meaning starting a new separate instance just for that group.
</p>
<h4>The grind</h4>
<p>
  Most of all what really bothers me is the <a href="https://en.wikipedia.org/wiki/Grinding_(video_games)" target="_blank">grind</a>. Granted not all games suffer from this equally, but at the end of the day, to progress in any game is to spend time playing that game. The additional challenge MMORPGs have is that they need player to stick around for a long time to create more of a time window for players to meet and play together.
</p>
<p>
  If an MMORPG could be "finished" in something like 10 hours, that means for every new player picking up the game, there are thousands of players that are already done with it. This means they can only really meet and play with other players that decided to pick up the game at the same time. Next weekend, they'll join the group of players who are done with the game and any new player going forward is unlikely to cross their paths with them in that game.
</p>
<p>
  All this leads to game design that includes grinding. Meaning spending lots of time doing tasks you don't really care for, but what you care about is what they enable you to do later. Daily quests, weekly quests, grinding mobs, grinding dungeons, grinding materials... This in turn creates a whole lot of other issues with the game, particularly where suddenly people find themselves forced to do a certain level of grind to continue playing with their friends.
</p>
<h3>Tribal Wars</h3>
<p>
  Last but not least I want to mention Tribal Wars - which for me was the first game of its kind that I encountered.
</p>
<p>
  Tribal Wars is a type of game where you build up your village or town, recruit troops and then raid smaller towns to keep your growing. Virtually all of the end-game content is player-generated, with alliances, drama, betrayal and other typical human behaviours as far as conflict is concerned.
</p>
<p>
  The important mechanic of this game is that everything takes real time. Building a new structure means waiting few minutes, hours or even days, depending on structure. Your armies would march and take hours to reach other settlements.
</p>
<p>
  I think at this point you can see where the inspiration for Soulforged comes in. Soulforged Zero operated exactly like that and while it had its charms, it was not without issues. As I now moved to action-point based system, I feel the benefits are still there - namely creating that large time window for players to interact with each other, while at the same time avoiding the worst of the grinding.
</p>
<h2>Honorable mentions</h2>
<p>
  There's a couple other things I'd like to mention here, but I feel they don't really contribute as much as the other titles mentioned here. 
</p>
<h3>Rimworld</h3>
<p>
  Similar to dwarf fortress, although more simplified but also more approachable. I particularly like the bits around the wound system and how simple yet effective they made it.  
</p>
<h3>Fallout 1 & 2</h3>
<p>
  Brilliant and deep RPG games. Big fan of their SPECIAL system as well as the world building.
</p>
<h3>Baldur's Gate</h3>
<p>
  One of the first proper, fully-fledged RPGs that I played. 
</p>
<h3>Drizzt saga</h3>
<p>
  A long series of books I've read, placed in the same world as the Baldur's Gate games. Loved the world building and character presentation.
</p>
<h2>Summed up</h2>
<p>
  I'm sure this is not an exhaustive list of all of the things that inspired me to begin this project in the first place, but I have to draw a line somewhere. Are there some other games you feel Soulforged is close to? Maybe something I forgot about or perhaps something made by people with similar inspirations to mine? Always welcome a discussion on the <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>! 
</p>
`
};
