export default {
  title: `The Journey Continues`,
  date: `2020-11-24`,
  intro: `I would like to take a moment to talk about where we were, what's happening now and where we're headed.`,
  fullText: `
<h2>What was</h2>
<p>
    When Soulforged was first created the looming question about its game design was: can a game like this be engaging and fun? A game players spend a couple of minutes playing throughout the day, where everything develops seemingly so slowly? It's hard to imagine this right now, but when the game was starting out there were a lot of doubts about whether a game like this can be enjoyable at all, whether it'd hold player's attention, have people come back to it regularly to take care of their character and continue exploring the world.
</p>
<p>
    I feel that with great confidence I can now say - yes, it can be immensely enjoyable! While not everyone found this game model to their liking, I saw a relatively large number of players, many finding something they really enjoyed about the game, some of which stuck around for almost the entire year the game was up! The feedback that I received when the day came to shut down the server was absolutely overwhelming, it really felt like the game and the community around it had a hugely positive impact on many of the players.
</p>
<p>
    With that question answered there was just one outstanding problem: as I was working full time on another job, the project suffered from the small amount of time I could dedicate to continuously develop the game. On top of that the original architecture that was still in use was really created as a proof-of-concept for this type of game and the scaling issues were becoming painfully obvious.
</p>
<h2>What follows</h2>
<p>
    Not too long ago I decided to start a new incarnation of the Soulforged game, solving the scaling problems and also wanting to experiment with some new concepts that I think the game may benefit a lot from. At the time when this new project started I was still working full-time on another job and the plan was to slowly, over the next few years, continue developing this new game as a hobby.
</p>
<p>
    This changed when the working arrangements on my full time job hit couple of serious bumps and when trying to resolve them turned out fruitless I decided it's time for me to move on. Only then I didn't think I'd be ready or willing to go full-time indie game dev, but here we are!
</p>
<p>
    So I find myself with the strong belief that a game model like this can lead to something absolutely amazing and I now have all the time needed to make it happen. And so the project is being kicked into overdrive and I hope to have a first, very early playable version with small amount of content ready mid-2021!
</p>
<h2>What changes</h2>
<p>
    The new game intends to be, at its core, the spiritual successor to the original Soulforged (now called "Soulforged: The Prelude"). What this means is it'll be an RPG, with a focus on cooperation, "idle-like" gameplay, exploring and shaping the world. That said, There are definitely areas in which I'd like to innovate and improve and I'd like to share with you a couple of highlights of the changes I'm planning.
</p>
<p>
    <em>Please note that everything listed here is still under consideration and may change depending on how each of the features pans out!</em>
</p>
<h3>Action Points</h3>
<p>
    It has been considered as well as suggested a couple of times that a game play based on action points that you accumulate over time and then spend may work better than the old system of ongoing activity. It'd be a massive change to the core gameplay loop. Where previously you'd react to any action finishing so that you can queue up the next action, in this model you would just pick the game up at a convenient time to you and use up some or all of the action points you accumulated since the last time you interacted with the game - be it travelling, gathering, crafting or any other action.
</p>
<p>
    It does create some interesting design challenges, but it means that we can make all in-game actions more interactive, varied and feel more rewarding. And I hope this goes without saying - there will be no way to pay to get more action points.
</p>
<h3>New currency</h3>
<p>
    Another thing I want to experiment with is a new type of ephemeral currency. The currency would be bound to your account (instead of character), would not be directly affected by death and can be exchanged between players alongside a trade exchange. The way to earn this currency (and the only way to do so) would be by "contributing" to what other players are doing. Have you built a road that's frequently travelled? Did you contribute to a smithy that's now operated by a number of smiths full time? Have you cooked a number of meals that other players get to benefit from? Or perhaps a tool you made is extensively used by another player? All such actions would in some way contribute to you earning an amount of this new currency.
</p>
<p>
    Of course some measures will have to be taken to ensure that this system is not being abused, but I have a number of ideas on how to do that.
</p>
<p>
    Now, what good is currency if there's no good way to spend it? Well, that's where we get to...
</p>
<h3>Perks instead of traits</h3>
<p>
    I think the traits system was the most lukewarm received system in all of Soulforged. It felt like it had so much potential but as players unlocked higher and higher Soul Levels, most perks brought little more than just disappointment.
</p>
<p>
    That's why I plan to scrap the traits system and replace it with perks. The core differences are:
</p>
<ul>
    <li>You can acquire perks at any point in your character's life, instead of only on character creation. Whether you're willing to risk your young character getting a number of them or have a very old one still get a perk to push what you can achieve - that's a choice you'll be making.</li>
    <li>Perks don't really include a drawback. Traits were intended to be balanced, you gain something, you lose something, keeping your overall character power level. Perks will bring just the good stuff to your character, increasing their power.</li>
    <li>Perks can be obtained by paying using the new currency mentioned above. You should expect that getting multiple perks for a single character will be increasingly costly thou, so don't expect to deck out all of the available perks.</li>
</ul>
<p>
    Probably worth mentioning that this system will replace the Soul Levels altogether. I hope this system will address all of the pain points while giving some really though, meaningful choices to the game.
</p>

<h2>Summed up</h2>
<p>I hope you enjoyed this brief overview of what's coming and that you are at least half as excited as I am. I plan to share many more updates with you over the course of next weeks and months so stay tuned!</p>
`
};
