export default {
  title: `Soulforged: status update 2`,
  date: `2021-02-26`,
  intro: `A short update on where things are with Soulforged&nbsp;2`,
  fullText: `
<h2>Avatars</h2>
<p>
  First, let's take a look at the avatars. Initially, I asked myself the same question I ask myself with every thing I would re-implement: do I want to change how avatars work/look like? I'd definitely want to add some more depth to character looks (scars, additional features, eye color). The question was open on how I want the avatars to look, the overall style. Doing a bit of research on the assets available, I decided to stick with the avatar look that I know players (and myself) were quite fond of. 
  <span class="image-frame right" style="width: 20%;">
    <a target="_blank" href="./images/2021-02-26/avatar.png">
      <img src="./images/2021-02-26/avatar.png">
    </a>v
  </span>
</p>
<p>
  While the look will be very familiar to anyone who played Soulforged&nbsp;1, there are two notable changes that I've done for the Soulforged&nbsp;2. First - the way the resources are shipped to the players - now these resources are managed by the game server, meaning I can include surprise looks, features and races that cannot be data-mined (some explanation of the term can be found <a target="_blank" href="https://www.engadget.com/2014-04-10-what-is-data-mining-and-why-is-everyone-talking-about-it.html">here</a>). 
</p>
<p>
  Another important change that I implemented regarding avatars is that all the processing regarding skin color and hair color is now done on the server, instead of the client. This probably sounds very technical again, but what it means is that if you select a particular hair color at character creation, all players will see exactly the same color regardless of the browser or device they use. And yes, funnily enough this was not the case with Soulforged&nbsp;1. 
</p>
<h2>Combat encounters</h2>
<p>
  I've progressed with the implementation of the combat encounters. There's still more to be done there, but in a nutshell what it means is that when you face an encounter, either initiated by you or by the hostile creature, you may get additional choices to interact with the hostile and it provides other players an opportunity to join the encounter. Once the combat starts, no new players can join in and the fight will continue until there are no hostile forces, either by knockout or fleeing. Another benefit of this system is that if you're victorious, then the spoils can be claimed only by people who took part in the encounter. Any disregarded items will be dropped on the ground like before.
</p>
<p>
  There's still more work to be done, particularly as far as tending wounds and using equipment is concerned, but I feel I have a solid base to handle combat encounters. 
</p>
<h2>Character development</h2>
<p>
  Next on the line are skills and statistics. This one gave me quite a pause, since there's a couple of things I really disliked about Soulforged 1 skill and statistics progression.
</p>
<p>
  With regard to skills - leveling up skills on a new character required steady access to a variety of resources and materials with different difficulties. This worked alright in Soulforged&nbsp;1, but with the idea of making the regions more varied in Soulforged&nbsp;2 that would be problematic. Another skill-related issue is the tooling and how getting better tools meant faster work. That works great in terms of helping gaining higher levels with the better tools, but it creates a problem where the weaker tools are inferior in all regards. It also falls apart when those super-fast tools are brought to low-level areas making new player work irrelevant - you can do it all by yourself and so much faster.
</p>
<p>
  What I'm changing with how skills work is that tools will no longer progress the work speed, but instead they'll be much more varied, providing skill bonuses and penalties, while altering speed. For instance - a basic woodcutting axe can be speed 100%, where as a seesaw may have the speed of 150%, but a penalty of 30% to success chance. There could also exist a small hatchet that while it has only 70% of the speed, it reduces the chance of an injury by 20%. With enough tools to select from, this will provide players multiple ways to progress their skills effectively in many different environments.
</p>
<p>
  Statistics (like Strength or Dexterity) balance also had its quirks in the first game. With the idea being that the average value is around 50 and game being balanced for values between 1-100, the experience of getting close or above 100 could have been somewhat disjointed. Gaining points above 100 felt very slow but at the same time the benefits of these gains were very substantial.
</p>
<p>
  This time I'm looking to simplify the progression while also removing the artificial ceiling, where the amount of statistic you have provides the exact percentage bonus to your speed or other properties. For instance - with base carry capacity of 40kg, having a Strength of 75 means you get additional +75% of your carry capacity, putting you at 70kg.
</p>
<h2>Tests</h2>
<p>
  I have mentioned in a previous update my increased emphasis on automated testing of the software that I'm building. In the same spirit I was working to further expand the type of testing I have set up by adding image testing and end-to-end testing.
</p>
<p>
  For those interested - image testing means I have a simple way to ensure that all the interface looks the way I want it to look. This boils down to a script that renders elements of the interface and then captures a screenshot and compares is to a screenshot I have made initially. End-to-end testing means I can run a script that will start a game server, create a new hand-crafted world and execute multiple steps as if a player was interacting with the game, including character creation, gathering, crafting, navigating menus, etc., using the browser and all the same interfaces players would use. Then it checks that the outcomes are all as expected. Both of these are also part of my integration pipeline, meaning they are checked for me automatically whenever I'm about to incorporate changes. 
</p>
<p>
  In simple terms, what this means is as I continue developing the game I spend less time re-testing the systems I implemented previously while having the confidence that they continue to work as I expected them to.
</p>
<h2>Closing thoughts</h2>
<p>
  There's still a whole host of small elements I implemented, but most of them are either very technical or a re-implementation of a system from the original Soulforged, only using the new engine and making it work with the new Action Point based system.
</p>
<p>
  I hope you continue to enjoy reading through these articles! If there's a particular topic you'd like me to cover in this form please let me know on <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>!
</p>
`
};
