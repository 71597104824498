export default {
  title: `Release date is set!`,
  date: `2021-12-24`,
  intro: `The game will release in Early Access on 28th December 2021!`,
  fullText: `
<p>
  This release will include all content prepared up to this point. The game will continue being developed and improved, taking onboard the feedback provided by the players of the game.
</p>
<p>
  Even in the Early Access state of the game you can expect the game to be fully maintained and any progress you make to be persisted and kept for the entire life of the game.
</p>
<p>
  It's a very exciting time for me and I hope you will join me in the game and will have a great time with the game!
</p>
`
};
