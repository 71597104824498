export default {
  title: `Testing vs Discovery`,
  date: `2021-10-22`,
  intro: `Today I'd like to talk about a question that's been on my mind a lot lately - how much testing is the right amount of testing for Soulforged?`,
  fullText: `
<p>
  In my <a href="https://soulforged.net/#/updates/17" target="_blank">previous article</a> I mentioned how other MMORPGs remain a big inspiration for me, particularly because of what I found lacking in them. And discovery is definitely one of the aspects where I yearn for something better than what most MMORPGs deliver right now.
</p>
<p>
  As an individual player you are free to embrace the game without looking up any information whatsoever. But the sad reality of MMORPGs is that as you encounter other players, they will interact with you assuming you know all of the information they do. And this quickly builds up an expectation that is hard to shake off, especially since it eventually becomes a roadblock to participating in certain activities and exploring parts of the content. 
</p>
<p>
  And often all of those expectations end up pilling on from day one of a new release or new content being added to the game they play - and that's because of Public Tests. Because by the time the game content is released it was all played through, documented, <a href="https://en.wikipedia.org/wiki/Glossary_of_video_game_terms#Min-Maxing" target="_blank">min-maxed</a> and solved.
</p>
<p>
  The most exciting part of new content being delivered in this case is the Public Test itself, but it comes with multitude of issues. First of all - playing Public Test you can never be sure if a feature is intentional or a byproduct or maybe just work-in-progress. Features get added and scrapped from Public Tests with little notice and shortcuts are added to allow players to jump into whatever needs testing at the given timeframe. And of course there's no real progress being kept from anything that happens on Public Test, other than what players learn about the "upcoming" content.
</p>
<p>
  So, how can we begin to solve this problem?
</p>
<h2>Better engineering</h2>
<p>
  It seems like there's an obvious solution and that is to engineer the game without any bugs in the first place. I assume it's no surprise but that's easier said than done. We are all not without flaws or defects and the software we build is only as precise as the people creating it. I myself am making a lot of effort in that direction, but I know better than to presume I can achieve that. And even if there is someone who can deliver complex code with no faults whatsoever - just let them know that <a href="https://en.wikipedia.org/wiki/Single-event_upset" target="_blank">Single-event upset</a> is a thing and watch them descend into madness.
</p>
<h2>Matter of scale</h2>
<p>
  So if the bugs and defects have to be assumed, maybe the next solution is to have enough testing to catch all of the important issues? While it sounds like a good plan, I believe we need to look at this problem from the perspective of scaling. A single person play-testing a game will find some of the bugs present. Adding more people is likely to continue unearthing problems the previous play-testers missed or skipped over. With Internet at our disposal it's possible to expose the game to hundreds of thousands of people and it's almost a given that they will find issues that no in-house testing team, no matter their size, would've found.
</p>
<p>
  What this means is that no amount of in-house testing will get us to a point where we can be reasonably confident there are no bugs in the game. What's left?
</p>
<h2>Accepting the faults</h2>
<p>
  And we are now looking at going full circle and back to Public Tests. Are they then inevitable?
</p>
<p>
  In my mind right now the answer is yes. They are inevitable. But I think we still have a lot of choice in how we go about it. As I mentioned multiple times exploration and discovery is a key part of Soulforged and I believe having test realms with shortcuts and constant resets would be hugely detrimental to the game experience overall.
</p>
<p>
  My solution to this is that the "Public Test" is when the game is released. Of course this can be hugely negative to the game experience as well, with many "what the hell" and "I quit" moments caused by bugs and defects. And yes, this gives me a lot of anxiety.
</p>
<p>
  But there are certain steps that I am taking to make this easier on both myself and the players. First and foremost I look to prioritize certain aspects and create contingencies where they matter most. The most important faults that I want to avoid or be able to mitigate are:
</p>
<ul>
  <li>Data loss - in a game like this, players losing their progress is an absolute deal-breaker</li>
  <li>Stability - having extended periods of downtime would be forcing players to adjust their schedules and I want to avoid it at all cost</li>
  <li>Misinformation - this is less technical bug and more of a game design defect, but it's about providing the player with the wrong information, effectively teaching them the wrong thing</li>
</ul>
<p>
  Misinformation is particularly tricky since it can have offshoot effects on many facets of the game and affect heavily how players engage and enjoy their experience with the game. What makes it worse is it's unlikely to be brought to light, since finding out what players mental models is very difficult in itself. For instance if players started to believe that being online and clicking/tapping any controls in the game actually generated Action Points faster, this would have catastrophic implications to how players enjoy the game. 
</p>
<p>
  Those may seem like very broad stroke items, but there's still a number of defects that while important to prevent are, I believe, less impactful. In the other category I'd put graphical glitches, temporary glitches (that go away after refresh), typos, some interface clunkiness and the like.
</p>
<p>
  Now, please don't read it the wrong way, I don't mean to say they are unimportant, that I don't care about them or that I don't really want them fixed. I absolutely do want to fix them and every single report of an issue, no matter how small, gives me more humility while is also greatly appreciated! There's a cost to every single issue like this being left in the game and that cost is trust - finding issues in a game you may start wondering what else could be broken and that rips the immersion into pieces.
</p>
<p>
  What I am trying to say is that if those "less important" issues are reported, I don't consider that a personal failure, just something that needs fixing and hopefully I can improve on and do better over time. On the other hand, if any of the top three defect types were to happen, that's the point where I'd have to take a long, hard look at my overall approach to this project. 
</p>
<h2>What this means for Soulforged</h2>
<p>
  In a nutshell this means that come December when I'll be setting up the first proper server, with all of the initial contents - it'll be <em><b>the</b></em> version of the game to play. This means persistence, continuity and as good quality of a product as I can make using my limited resources.
</p>
<p>
  I want to be open and admit - it scares the hell out of me. But I still believe this is the way to go and it'll leave everyone better off.
</p>
<p>
  What do you think? Do you agree my approach is the best given the situation or do you think there's a better way? Maybe you disagree with some of the assumptions I made? Why not head over to <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> and let me know what you think!  
</p>
<p>
  Thank you for reading and hope you have a wonderful day!
</p>
`
};
