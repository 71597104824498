export default {
  title: `Battling it out`,
  date: `2022-02-08`,
  intro: `Recently there's been some interesting discussions regarding combat on our Discord server, including some recurring concerns. I'd like to present you my point of view on the subject as well as my intent behind the system, current and future.`,
  fullText: `
<p>
    While combat in Soulforged is just one of the facets of the game and not the main focus it's an important area nonetheless and so I put a lot of thought and effort to make it understandable, feel fair and provide players with choices.
</p>
<p>
    This is a complex topic and I'll try to stick to one of each of the individual aspect of the combat system at any one time. There's lots of ground to cover and inevitably some of those will intertwine, but I'll do my best to keep it focused. 
</p>
<p>
    First, let's define what combat system is right now and how it works.
</p>
<h2>System outline</h2>
<p>
    We start off by having a character player. They will be bringing into the fight whatever effects they currently have on them, they can use consumables, they can equip items and pick a weapon.
</p>
<p>
    Before the actual combat starts we have what I call a "Hostile encounter". This happens either because a hostile monster interrupts some player action or the player chose to attack a particular monster. Player can opt to add additional monsters to the fight at this stage, they can also arrange for some other players to join them or attempt to use one of the items they have to affect or resolve the encounter.
</p>
<p>
    After that we have the actual fight starting, with each monster or player character taking turns. During a turn a character can either make an attempt to flee or perform an attack. If they choose to flee, the fleeing counter increases. If they decided to attack, it resets the fleeing progress, picks a random enemy and the character and the enemy exchange blows.
</p>
<p>
    In addition to that player characters can opt to use a consumable item or swap weapon for another one (including fighting unarmed) at almost any point during combat.
</p>
<p>
    Once all characters have taken a turn the next round begins and again each of the characters takes another turn.
</p>
<p>
    If a character flees, dies or has no more valid targets they are removed from combat. Once all characters are removed, the combat is considered to be concluded. At this point player characters that didn't flee or die will have access to whatever loot is available. Any loot they forego will be dropped on the ground when all players leave the loot screen. If there are hostile monsters about when the combat ends, these monsters will claim the loot instead.
</p>
<p>
    So the stage is set, a simple system of rounds, turns and exchanging blows. Let's start picking it apart one by one.
</p>
<h2>Counter attacks</h2>
<p>
    Starting out with the real heavy-weight - the counter attacks. Any attack a character performs triggers the enemy to also perform a counter-attack and this applies to both player characters, monsters or NPCs.
</p>
<p>
    I am a proponent of this approach as it helps tackle one of the biggest challenges in designing combat encounters - that is to having them work well with a scalable number of players or enemy characters. It's not perfect, it's not without its issues. I believe that the biggest problem this system faces is the perception of it and how counter-intuitive it seems at a glance. This is evidenced by several players who shared with me their dislike of this system, often raising concerns how they feel they are penalized by fighting in a group. 
</p>
<p>
    But the reality of the situation is, the counter-attack system is very fair. Yes, it's counter-intuitive, it's not what players expect, it makes many vs one fight seem like the group doesn't really have an advantage and it adds a bunch of noise when battle is happening. But it still is very, very fair.
</p>
<p>
   One thing I really want to stress before I delve into this - the counter-attack only really works well when the counter-attack is done without any penalties or bonuses and has no limits (in how many counter-attacks can be performed per round). It could be argued that there's room to do that, but adjustments like this only change <em>when</em> the system breaks, not whether it breaks. 
</p>
<p>
    All those issues that I mentioned about this system? These are very real issues. I'd love to improve the intuitiveness of this system, I'd love to cut down on the clutter. But right now I am very happy to pay that price given what counter-attacks bring in. So, how do counter attacks help keep things balanced?
</p>
<p class="small">
  Note: If you are fully convinced and in support of the counter-attack mechanic you may want to skip the case studies and continue with the rest of the article.   
</p>
<h3>Case 1: Strong enemy, players with similar power</h3>
<p>
    Let's say we have a strong enemy and when being fought by a well-equipped player they take 12 hits to be killed. The player will barely survive as on average they'd be taken down in 15 hits. We're going to skip over the effects wounds have, they don't change the math that much here (and they will benefit the group most of the time). I'm also going to ignore chance to hit and resistances (as we're looking at the final, average result). 
</p>
<p>
    1 vs 1 the combat will result in the following:
</p>
<ul>
    <li>Player character will attack the enemy 6 times (triggering counter-attacks)</li>
    <li>The enemy will attack the player 6 times (triggering counter-attacks)</li>
</ul>
<p>
    This will result in 12 hits on each of the characters. The enemy will be dead and the player will be 3 hits away from dying, meaning they can't hope to attack another one. Now let's add 1 more player character of roughly the same power. The fight will go as follows:
</p>
<ul>
    <li>Player 1 (Alice) will attack the enemy 4 times (triggering counter-attacks)</li>
    <li>Player 2 (Bob) will attack the enemy 4 times (triggering counter-attacks)</li>
    <li>The enemy will attack the Alice 2 times and Bob 2 times (triggering counter-attacks; on average)</li>
</ul>
<p>
    The enemy will be hit a total of 4 + 4 + 2 + 2 = 12 times. Each of the players will be hit 4 + 2 = 6 times. That means those 2 players are now 9 hits away from being dead, meaning that if they attack another enemy like this in all likelihood they will both survive.
</p>
<p>
    In summary, in this scenario, the two players together can take on two of those enemies. This scales further as you add more players, with 4 players being able to take on 4 enemies like this, etc. This also applies to fractions - if a single player can take about 30% of health of an enemy before they themselves die, having 4 players fight that single creature together they are likely to bring it down without anyone dying.
</p>
<h3>Case 2: Strong enemy, a poorly armoured player joins in</h3>
<p>
    In our second case study we'll look at a scenario where a strong player (Alice) is just barely able to kill a tough mob and we'll see what happens when another player (Bob) without sufficient armour joins in. The numbers we'll work with here are as follows:
</p>
<ul>
    <li>Alice kills the enemy in 30 hits, the enemy kills Alice in 40 hits</li> 
    <li>Bob kills the enemy in 30 hits as well, but the enemy kills Bob in 3 hits</li> 
</ul>
<p>
    The argument I often hear in this case is that Bob joining in means that players receive more damage than if Bob didn't join in. And while it's true that overall amount of damage will be greater, Alice would still benefit from Bob joining in (although probably not worth the price Bob will pay).
</p>
<p>
    If Alice fights alone the fight will be over in 15 rounds, each character attacking 15 times, triggering counter-attack, Alice is left 10 hits short of being dead. Now let's take a look at what happens when Bob joins in.
</p>
<ul>
    <li>In the first 2 rounds Alice will attack 2 times, Bob will attack 2 times and the enemy will attack each of them once each</li>
    <li>At this point Alice received 3 wounds, Bob received 3 wounds (and died) and the enemy was a total of 6 times</li>
    <li>Alice is now 37 hits away from dying and the enemy is 24 hits from dying</li>
    <li>The combat continues for another 12 rounds, where each perform 12 attacks (and 12 counter-attacks), each being hit a total of 24 times</li>
</ul>
<p>
    The combat ends with Bob being long dead, the enemy just killed and Alice being 37 - 24 = 13 hits away from being dead. So while Bob did indeed die, Alice received fewer wounds than when fighting alone. And now the argument could be made that it'd be better if Alice could tank the enemy and Bob help out without risking life, making for a better game. This I don't agree with and let me elaborate on this by taking a look at the next case study...
</p>
<h3>Case 3: Players forming a mob</h3>
<p>
    The enemy this time is exceptionally powerful. They kill a player character in 2 hits and they themselves take 200 hits to be downed. Quick question - how many players are needed to take down that beast? And how would the number change if there were no counter-attacks? Another follow up question - how do those number change if the enemy can sustain double the punishment? Let's break this down. 
</p>
<h4>With counter-attacks</h4>
<p>
    First let's look at having counter-attacks as normal. All we want is for the enemy to be dead, no matter the losses. The number of player characters it takes is 101. Each of the players will attack once, leaving the enemy 99 hits away from being dead. They will all receive one wound by that time (from counter-attack), being just 1 more wound away from dying. Then the enemy attacks one more player, kills the player and the enemy is now 98 hits away from being dead. What happens next is the next 98 out of 100 currently alive characters attack, dealing one wound and dying in the process. The end result is the enemy is dead and 2 players are left standing. 
</p>
<p>
    What happens when we double the health of this enemy? As expected we'll need almost double the group of players to deal with them, bring the number to 201 (the one extra is to assure there's survivors).
</p>
<h4>Without counter-attacks</h4>
<p>
    And now let's take a look at what happens when we remove counter-attacks altogether. How many players would you expect is needed to take down that enemy that takes 200 hits? The answer is 14, assuming players have the worst possible luck in who gets targeted. If players have best possible luck over half of them will get out of this alive.  As you can picture one player has virtually no chance of killing that enemy, but all it takes is 14 of them and it's dead. How do I get that number? I simply count that on the first two rounds each player will attack the enemy twice (14 * 2) and then one player character will perish (assuming worst luck). Then the next two rounds the 13 remaining will attack twice (13 * 2) and another player will perish. Continue until very few player characters are left to finish the job. 
</p>
<p>
    So now, what happens when we double the strength of the enemy? Now we'll need a whooping <em>6</em> additional characters, bringing it to a total of 20. The trend will continue as we continue going up.
</p>
<p>
    At this point for the toughest fights out there players' best bet is to simply find enough warm bodies to handle whatever weapon can be mass-produced and throw them onto the enemy to bring them down. Not only that, but adding or missing just one player can make a massive difference on the outcome of the fight.
</p>
<h3>Looking at it upside-down</h3>
<p>
    Looking at this choice (counter-attacks or no counter-attacks) an important consideration is how much do I want the group size to matter vs how much individual power increase matters. Without counter-attacks the making of a strong enemy will mean making an insanely overpowered creature that no single player can ever take down. With counter-attacks the fight results are more consistent and the impact of adding more players sways the results less, while still providing great benefits of banding together.
</p>
<p>
    If you ever played a more traditional MMORPG like World of Warcraft or Final Fantasy XIV, in all likelihood you've seen firsthand how from playing solo and being careful about what you pull, the game changes drastically if you have one or two players join you in open world turning fights into non-affairs.
</p>
<p>
    Still not convinced? Maybe there's something I'm missing? I'd love to hear all that on our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a>! But wait, there's more!
</p>
<h2>Damage types</h2>
<p>
    Next I'd like to glance over the different damage types and how they correlate with the choice of equipment. The three basic damage types are: Blunt, Pierce & Cut. Generally speaking each of those damage types translates to a wound type: Bruise, Stab & Cut, respectively. 
</p>
<p>
    Each of these wound types have some lethal capacity, some more some less. Bruises reduce endurance, cause pain and cause minor vitality loss. Stabs have relatively minor impact except for how much they reduce vitality directly. And finally cuts are an inconvenience until enough of them are applied and the blood loss starts to matter, reducing all stats and eventually leading to death.
</p>
<p>
    The gist of it is that blunt weapons tend to weaken the enemy as the fight goes on, making it more one-sided. Cuts can be used to kill enemies over time (including fleeing from combat) and finally stabs are the go-to if you want the enemy dead as soon as possible with little additional fluff.
</p>
<p>
    On top of that is the consideration where different enemies may have different chance to hit/avoid attacks as well as different resistances. An enemy that has low Cut resistance, high avoidance and low hit chance are perfect to be tackled with cutting weapons - all the turns that result in missed hits on both sides still add up to blood loss based on the cuts made so far, meaning the wounds inflicted keep paying off as the fight goes on. A heavy hitting enemy might be best to be brought low with blunt weapon first before their hits start causing real trouble, maybe to be later finished off with a pointy weapon.
</p>
<p>
    In general most of the enemies that can be encountered in the wilds are meant to succumb to player attacks, no matter the weapon they pick, provided the gear is strong enough. If you have your favourite weapon, for the vast majority of animals you are pretty safe sticking to that one weapon type. In all likelihood you'd still benefit from picking the better weapon type, but it's unlikely to turn a "make" situation into a "break" situation.
</p>
<p>
    But then there are some monsters out there where the choice of weapons will not only be impactful, it may be critical. Monsters that may be near-unbeatable using some of the weapon types, even when you get your hands on a very high tier one. These monsters are designed to be more of a challenge and are usually placed some way away from inhabitable spaces where players will often wander around gathering resources. 
</p>
<p>
    So how does my intention translate to how the game works? Well, it's really something that you, the players, would be best suited to answer. From what I can tell  players are using a variety of weapons and some of the weaknesses of some creatures are being picked up on, so there are parts that are working well. The bit that's most dissatisfying for me is the injury variety, particularly how bruises affect endurance (and how that translates to vitality drop) so you can expect some small adjustments in that area.
</p>
<h2>To hit or not to hit</h2>
<p>
    Next I'd like to briefly go over hit rating and defense rating. These are some of the most obscure numbers for players to work with, with only a tiny ounce of information provided on them by the game. While I won't be providing the exact formula, I'm hoping the below description will help get a feel of what those numbers mean and maybe inspire some suggestions on how to better surface that.
</p>
<p>
    First of all - the names "hit rating" and "defense rating" are very purposeful. By avoiding using "hit chance" I avoid the risk of conflating these with direct percentage chance, as that's not how that works. At least not exactly.
</p>
<p>
    You can picture it like this - if your hit rating is equal enemy's defense rating then the end result is a basic "to hit" chance. This means that your chances of scoring a hit are as if they were 50/50, in a way. In practice, the ratings translate to a curve that translates a roll die (0-100) into how well the hit connected. If you roll high enough number, it'll result in dealing max damage. Roll around 50 and you'll deal half of your max damage. Roll low and you will hit for 0% of your damage, effectively meaning you missed.
</p>
<p>
    With that in mind you can look at hit rating as a value that pushes the curve in favour of low rolls still counting to deal more damage and defense rating pushes it in opposite direction. 
</p>
<p>
    There's effectively a cap on either of the ends at which point the curve no longer moves. If the defense rating is that much higher than hit rating, you are very unlikely to land a hit and even then the damage will only be up to 50%. On the contrary if the hit rating is that much higher, you will almost always deal full damage, only sometimes dropping to about half the damage. The change is not linear, so while you may notice a difference of the first 10 hit rating advantage, going from 80 to 90 is not going to be as impactful.  
</p>
<p>
    My reasons for going with that convoluted mess of functions and computations is I wanted to have strong elements of randomness, while avoiding potentially lopsided fights purely off of randomness. Most notably achieved by tying the damage caused to the hit roll. I really dislike situations where you may hit with 1% hit chance for full damage, or potentially critical hit, in other games. Or worse yet, having 100% chance to hit only to deal minimal damage 3 times in a row.
</p>
<p>
   With this system I hope that while incredible results like killing a dragon with a stick can technically happen, it requires alignment of so many random factors that it's a non-concern. More importantly thou, we don't need to worry about a silly rat killing a well equipped character. 
</p>
<h2>Resistances</h2>
<p>
    Next we have a perfect segue into damage resistances. The damage dealt by an attack can be reduced by stacking resistance against that damage type. The resistances simply add together and the result is maximum possible amount of damage it may prevent. If you have 10 Blunt resistance it means that any attack that includes blunt damage will have that blunt damage reduced by up to 10 points. The randomness is included to do away with the scenario where you can get enough armour to guarantee you won't be hurt. But bearing in mind how random results can result in lopsided fights the actual distribution of this roll is close to <a target="_blank" href="https://en.wikipedia.org/wiki/Normal_distribution">normal distribution</a>.
</p>
<p>
    This random element was actually added following the pre-alpha phase of the game where the resistances included there were fixed and always at max. This led to players pretty quickly figuring out which pieces of gear are sufficient to not take any damage whatsoever from each of the monsters that were included. This in my eyes created the strange dynamic where the combat result felt totally guaranteed since players knew they'd take no damage. I'm still mostly alright with that, but another consequence was this system encouraged players to pick cheaper armour that would exactly match the enemy damage to make fighting more cost-effective. That juggling of armour pieces is not something I wanted to encourage at this scale and so I opted to include a random element.
</p>
<p>
    This randomness of resistances is not without consequences, including some I need to keep in mind when designing any new enemy. If the damage numbers start going very high, I run the risk of creating a monster that may outright kill a character decked out in a full set of the best available armor - suffice to say that'd be absolutely disastrous. Even if the chances are as small as 0.1%, engaging in a fight you're well equipped for and dying to a single hit would make for an utter failure on my part. The fact that this may happen to a hostile monster doesn't really make it any better either. With combat overhaul I'm likely to take another look at the randomness of resistances and perhaps make certain small adjustments.
</p>
<h2>Moods</h2>
<p>
    Next component to look at is the character mood. Mood is a core mechanic to the game where the happier your character is the faster their actions are. This translates to combat as well, where happier characters will deal more damage. This is most notable when we look at unhappy characters and how their combat efficiency drops leading to a snowball effect where as you deal more damage while keeping your mood up, the enemy attacks become weaker meaning you gain advantage. Of course the same applies to your enemies. 
</p>
<p>
    For me this is absolutely critical from a game's "feel" point of view, but I also think that it works well particularly in this type of game. The "feel" part is how in many games a character might be on their last sliver of health, but their damage output is exactly the same as if their health was full. Put it simply - I don't like that. It often turns health into this odd resource that is totally and perfectly spendable without any consequence so long as you don't spend too much. But for Soulforged what this also means is that players get additional incentive to consider their options and their position. If you're badly beaten up, your chances of scoring that final, big hit are now much smaller (because of how resistances work too) so you may really want to consider fleeing. Speaking of...
</p>
<h2>Fleeing</h2>
<p>
    Fleeing is absolutely core to the game. In a game like Soulforged forcing a player to resolve combat by killing or being killed would have dire consequences. This would mean that a player would want every potential encounter to be a certain win or risk losing your character. For this reason fleeing was added to the game (and is even included as part of the tutorial) and is considered crucial to game balance. When exploring it's a tool that is intended to help you stay alive when facing a new, unknown creature. It's there to help you gauge the strength of an enemy or group up to relatively safely take down stronger enemies with wounded players withdrawing from fighting.
</p>
<p>
    On top of that fleeing is affected by your character's efficiency and there's a good reason for that as well. The fleeing takes a bit of time, more time if your efficiency is low and this turns it into another very impactful choice. If you start fleeing early, you are likely to be gone in just a few rounds. If you decide to take on the fight, you might find yourself in a situation where it's much harder for you to escape. And on top of that - if you're far away and stranded in dangerous lands, each wound you collect is worth considering as they make near-future fleeing attempts that much harder.
</p>
<p>
    The second important mechanic about fleeing is that if you decide to switch from fleeing to attacking again, the fleeing progress resets. This is to avoid situations where players are incentivised to start combat by racking up some fleeing progress first so that in case they want to flee at any point, it's a snap process. 
</p>
<p>
    And the last bit about fleeing is that it stops the counter attacks and that's important for one particular reason - fleeing is meant to be a decisive action. If your character kept counter-attacking, it's easy to come to conclusion that the best strategy is to keep fleeing, then reset the timer right before you flee completely and start fleeing again. Since your counter-attacks in this case would still perform well, this means that if you do decide to flee, you're already some percentage ahead in that and you just stop cancelling the fleeing action.
</p>
<p>
    One thing that may be worth adding that is easy to miss and that's fleeing actually gains additional advantage in mob fights. If you group up with a bunch of characters and you all attempt to flee, the enemy will only perform a single attack for every time fleeing progresses for all members of the group. This is a welcome consequence in my eyes since it adds that much of a safety net if players decide to group up to take down a tough enemy.
</p>
<h2>What's next?</h2>
<p>
    What do I have in store next for Soulforged and its combat system? I want to make it more interactive and involved, provide more utility to combat skills and at the same time slow it down while in practice speeding it up. It all makes sense, I promise!
</p>
<p>
    Let's take a look at the interactivity first - the plan is to include actions that you will be able to pick for each of the rounds of combat. Actions may cause additional effects on both the enemy and the character using it and most of them will have some cooldown. Each round you'll be able to select one combat action and your character will perform it when their round comes. If you make no choice in allotted time, it'll default to standard attack (always available) or flee (if you fled last time). This will also have an impact on consumable items as using them will also become an action.
</p>
<p>
    The actions will be mostly based around status effects. For instance you may opt to use a powerful swing, adding damage to your attack for a cost of reduced defense for the next round or two. Or you may attempt to knock the enemy down, increasing everyone's chance to score a hit on that enemy. The exact list and number of effects and abilities is yet to be drafted.
</p>
<p>
    Now the important part is where those abilities come from and that's what will add additional layer and flavour to each individual weapon, as well as consider your skills. When you equip a weapon you'll be granted additional abilities that this weapon provides. For example one sword may enable you to go for a decapitating move while another gives you a move that increases your defenses. A particular knife may give you a special attack that hits 3 times instead of 1, but each hit has a reduced hit rating. Or maybe a particular mace has a spike attached at its handle, providing you with a unique, Stab-dealing attack. To be able to use those abilities your character will require a certain skill level. Lower "tier" weapons will require lower skill for each of the abilities they provide, while better weapons will require a higher skill level. I also picture that each weapon will have different requirements for each of its abilities, so as your skill improves you will unlock additional options when handling each weapon. The abilities will be visible from the get-go, so once you have your hands on a piece of equipment you will know what to expect of it as your skill improves. You can also expect some abilities to be provided by a small number of weapons, so they are not uniquely attached to particular item type.
</p>
<p>
    In addition to all that I'll be looking to extend the use of items, likely including some penalties for using some of them - like using medical item prevents you from counter-attacking until your next turn.
</p>
<p>
    With the abilities making each attack more impactful, it'll become increasingly important which enemy character is being targeted by an attack. To that end, with this system in place, player characters will be able to pick which enemy they want to attack.
</p>
<p>
    Last but not least let's talk about combat speed. Right now each player character takes 2 seconds to perform their round. This is fixed and balanced around enabling players to see what's happening and make a choice between fighting and fleeing. In this new system this timer will be increased for player characters to around 5 seconds (more testing required), which as I said earlier will result in fighting being slower. However, this time around when player picks an action, that action will be executed immediately on the next round, meaning that timer for player character may go down all the way to 0, meaning the combat would resolve faster. The timer doesn't actually affects things like effects ticking, these are round/turn based, so taking the time to make a decision doesn't actually affect the outcome.
</p>
<p>
    There you have it, the plan for the combat overhaul! I'd love to hear how this makes you feel! Excited? Concerned? I want to hear it all so why not pop into <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a> and share that with me and the community? But wait, there's more!
</p>
<h2>Things that could've been</h2>
<p>
    There's a saying I have about game design that I often mention and that's making a design decision is often less about what you bring in and more about what becomes locked out. What I mean with this is that while adding something small and cool that costs next to nothing to implement may seem like a good idea, it really needs to be considered more from perspective of what other options in the future this locks out. This is also the case with the combat system in Soulforged and there's a number of fun, interesting mechanics that lots of games implemented that are for one reason or another locked out.
</p>
<p>
    So, what are the things that we're missing out on and what are the reasons?
</p>
<ul>
    <li><em>Positional combat</em> - having characters move on a plane or a grid. It's an old but nevertheless a nice concept, opens up room for things like backstabbing or flanking. But to me most importantly it just doesn't translate well enough to an MMO setup where you may gather a group of 20 players and standing next to an enemy becomes a privilege. It also creates all sorts of weirdness when it comes to fleeing.</li>
    <li><em>Attack speed</em> - it makes little to no sense to include weapon speed in a turn-based game. Some games translate that to initiative which I often find is considered a useless stat. Instead I just skip past this altogether.</li>
    <li><em>Player's own mechanical skill</em> - one thing that's very tempting is including some form of a mini-game that determines perhaps the chance to hit, dodge or the damage dealt. The practicality of the situation is however that with a variety of devices used for playing the game, performance consideration and how open it'd be to automation and botting I chose not to include that. On top of that I'd feel really bad it if turned out that someone with minor motor impairment would be prevented from effectively engaging in this playstyle because of this.</li>
    <li><em>Ranged combat</em> - it's an interesting one, because I wouldn't say it's entirely excluded from future options, only that the most typical implementation of it is not something I'd want to include. By typical I mean things like being able to hit a creature in another location or even as simple as being able to attack a creature without triggering counter-attack - the reason is the same as to why counter-attacks were included in the first place. That said there may still be some room to explore that, we'll see.</li>
    <li><em>Terrain consideration</em> - again, not saying nothing like this will ever be included, but the most typical use-cases of terrain affecting combat just doesn't have any legs in the current setup. I'm thinking of things like elevation (height advantage) or surfaces affecting movement speed</li>
</ul>
<p>
    There's definitely more that could be included, considered or said about combat in Soulforged (and in video games altogether). Do you agree with my takes? Is there something you think is worth closer consideration? As always, I very much welcome a discussion on our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a> server!
</p>
<p>
    Thank you for reading and I hope you have a wonderful day!
</p>
`
};
