global.NEWS_PATH = "updates";
global.REVIEWS_PATH = "reviews";
global.ROADMAP_PATH = "roadmap";
global.SCREENSHOTS_PATHS = "screenshots";

export default {
  home: {
    label: "Home"
  },
  [global.NEWS_PATH]: {
    label: "Devlog"
  },
  [global.ROADMAP_PATH]: {
    label: "Roadmap"
  },
  [global.REVIEWS_PATH]: {
    label: "Reviews"
  },
  [global.SCREENSHOTS_PATHS]: {
    label: "Screenshots"
  },
  hackTheGame: {
    label: "Hack the Game"
  },
  legal: {
    label: "Legal"
  }
};
