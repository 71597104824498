export default [
  {
    name: "Core features",
    items: {
      "Game engine": {
        description:
          "The core engine on which the whole game runs. This includes persisting all the data about the world, keeping the world running, handling communications with the client running in the browser and ensuring that all the resources are kept secure and cannot be data-mined.",
        fragments: {
          Design: 1,
          Persistence: 1,
          Security: 1,
          Client: 1
        }
      },
      "Player character": {
        description:
          "Everything that really defines your character - their skills, statistics, state and perhaps most importantly - their looks.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      Inventory: {
        description:
          "Your character's inventory and equipment holds all the items necessary for your character's well-being - all the food, equipment, tools, materials and other treasures.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      Travel: {
        description:
          "Travel is part of the core experience, where with your character you'll be exploring new areas as well as traverse the known ones looking for resources your character needs.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      "Resource gathering": {
        description:
          "Resources strewn across the world are there for your character to find and gather.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Construction: {
        description:
          "Building the infrastructure in and around your town is another important way of unlocking new possibilities and make your character's life better and safer.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Crafting: {
        description:
          "To prosper in the game world your character will need to utilize their crafting abilities to create a whole host of new equipment, food, consumables, decorations and other useful items. Most of the items in the game will be craftable by players.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Research: {
        description:
          "Research system is at the center of finding out all the useful items your character can make and all the buildings they can construct.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      "Dynamic map": {
        description:
          "All the structures that player built will reflect and show up on the world map. As the players dot the world with the roads, bridges and towns they build, they all become part of what the game world looks like.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Housing: {
        description:
          "Settle your character down by constructing one of the housing options and decorate it with various items to bring style and benefits for your character.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Farming: {
        description:
          "Living off the land, growing crops and tending to your animals to produce sustainable source of food and some of the materials.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      Climate: {
        description:
          "Different areas of the world will represent vastly different environments. The game will include a system that communicates the climate and the consequences to the player.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 0.5,
          Sounds: 0
        }
      },
      "Combat system": {
        description:
          "Everything to ensure that your character and their enemies can exchange blows swiftly and establish the winner.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 0.5,
          Sounds: 0
        }
      },
      "Player trading": {
        description:
          "Exchanging items between the players will be an essential part of the game, including player-owned stalls and shops.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      "Wound system": {
        description:
          "Injuries from combat and accidents and all the things needed to tend to them and prevent lethal outcome.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      "Naming system": {
        description:
          "Every item type, monster type, every town and region - all there for the players to name.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      "Non-playing characters": {
        description:
          "Include NPCs (Non-Player Characters) enabling players to interact with them, chat and progress the world narrative.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      "In-game currency": {
        description:
          "An in-game currency, earned through in-game actions with emphasis on supporting other players. The currency can be used for trading as well as increasing your character power.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      },
      "In-game chat": {
        description:
          "An in-game chat enabling all players to communicate with each other without relying on 3rd party tools.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1
        }
      }
    }
  },
  {
    name: "Game world",
    items: {
      "Overall world": {
        description: "The vision for the whole game world of Soulforged.",
        fragments: {
          Map: 1,
          Lore: 0.5
        }
      },
      Tutorial: {
        description:
          "A tiny region that will include in-game tutorial for new players and a short, introductory experience.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 1,
          Lore: 1
        }
      },
      "Chapter 1": {
        description:
          "The first region of the new game world, including diverse locations, resources, creatures and storylines.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0.5,
          Lore: 1
        }
      },
      "Chapter 2": {
        description:
          "The second region of the new game world, including known and new resources, creatures and a new major storyline.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 1,
          Lore: 1
        }
      },
      "Additional chapters": {
        description:
          "This will be divided into separate sections as the overall world design nears completion.",
        fragments: {
          Lore: 0.5
        }
      }
    }
  },
  {
    name: "Support systems",
    items: {
      "Multi-account detection": {
        description:
          "Fair playing field is crucial for the game experience and nothing can ruin it quicker than a player using multiple accounts. While specifically disallowed by ToS, it's important to ensure the game admin team has tools to discover and action such offences.",
        fragments: {
          Design: 0.5,
          Code: 0.5
        }
      },
      "Bot detection": {
        description:
          "A system that prevents automating character actions using bots or other automated tools.",
        fragments: {
          Design: 0.5,
          Code: 0
        }
      },
      "Moderation tools": {
        description:
          "Set of tools that simplifies moderation, making reporting and reviewing character names and chat messages easier.",
        fragments: {
          Design: 1,
          Code: 1
        }
      }
    }
  },
  {
    name: "Additional features",
    items: {
      "House view": {
        description:
          "Building and decorating your house is nice, but seeing that house in all of its glory, with the rug stretches across the floor, the cosy fire crackling in the fireplace and trophies hung across the wall - that's what really bring the place to life and makes it cosy.",
        fragments: {
          Design: 0.5,
          Code: 0,
          Visuals: 0,
          Sounds: 0
        }
      },
      "Farm view": {
        description:
          "Showing off all of the various crops and animals you are taking care of.",
        fragments: {
          Design: 1,
          Code: 1,
          Visuals: 1,
          Sounds: 0
        }
      },
      "Pet companions": {
        description:
          "Taming wild animals to accompany you, defend you and ease your travel.",
        fragments: {
          Design: 0,
          Code: 0,
          Visuals: 0,
          Sounds: 0
        }
      },
      "Dynamic interface": {
        description:
          "Sprucing up the interface to reflect the area you find yourself in - be in the warmth of your cottage, the buzzing wetlands or icy mountaintops.",
        fragments: {
          Design: 0.5,
          Code: 0.5,
          Visuals: 0.5
        }
      }
    }
  }
];
