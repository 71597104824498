<template>
  <div>
    <div class="roadmap-container">
      <div class="timeline">
        <h1>Timeline</h1>
        <div class="ornament separator top alt" />
        <div class="timeline-line">
          <div class="today-marker" :style="todayMarkerStyle"></div>
          <div
            v-for="(event, idx) in timelineData"
            :key="idx"
            class="event"
            :class="'importance-' + event.importance"
            :style="event.style"
          >
            <div class="text">
              <div class="title">{{ event.title }}</div>
              <div class="date">{{ event.displayDate }}</div>
              <div class="description">{{ event.description }}</div>
            </div>
          </div>
        </div>
        <div class="ornament separator bottom alt" />
      </div>
      <div class="progress">
        <h1>Progress</h1>
        <div>
          <div v-for="(progress, idx) in progressData" :key="idx">
            <div class="ornament separator" />
            <h2>{{ progress.name }}</h2>
            <div class="items">
              <div
                v-for="(definition, item) in progress.items"
                :key="item"
                class="item"
              >
                <div class="item-details" v-if="definition.description">
                  <div class="ornament separator top" />
                  <div class="text" v-html="definition.description" />
                  <div class="ornament separator bottom" />
                </div>
                <h3>{{ item }}</h3>
                <div class="fragments">
                  <div
                    v-for="(stage, fragment) in definition.fragments"
                    :key="fragment"
                    class="fragment"
                    :class="[fragment, 'stage-' + 100 * stage]"
                  >
                    {{ fragment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ornament separator" />
        </div>
      </div>
    </div>
    <div class="footer">
      Resources:
      <a href="https://www.freepik.com/vectors/vintage" target="_blank">
        Vintage vector created by asmaarzq - www.freepik.com
      </a>
    </div>
  </div>
</template>

<script>
import progressData from "../script/progressData.js";
import timelineData from "../script/timelineData.js";

const today = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
const start = timelineData[0].date;
const lastTimeline = timelineData[timelineData.length - 1].date;
const lastTimelineDate = new Date(
  lastTimeline
    .split("-")
    .reverse()
    .join("-")
);
const end =
  lastTimelineDate.getTime() > new Date().getTime()
    ? timelineData[timelineData.length - 1].date
    : today;
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const formatDate = date => {
  const values = date.split("-");
  return monthNames[+values[0] - 1] + " " + values[1];
};
const getMonth = date => {
  const values = date.split("-");
  return +values[0] + +values[1] * 12;
};
const getPlacement = date => {
  return (
    99 *
    ((getMonth(date) - getMonth(start)) / (getMonth(end) - getMonth(start)))
  );
};

export default {
  data: () => ({
    progressData,
    timelineData: null,
    todayMarkerStyle: null
  }),

  created() {
    this.todayMarkerStyle = {
      height: `${getPlacement(today)}%`
    };
    this.timelineData = [
      ...timelineData,
      {
        date: today,
        importance: 1,
        title: "Today"
      }
    ].map(event => ({
      ...event,
      displayDate: formatDate(event.date),
      style: {
        top: `${getPlacement(event.date)}%`
      }
    }));
    console.log(this.timelineData);
  }
};
</script>

<style lang="scss">
$shineSize: 6rem;

@keyframes expand {
  0% {
    max-height: 0.5rem;
    opacity: 0;
  }
  99% {
    max-height: 10rem;
    opacity: 1;
  }
  100% {
    max-height: initial;
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    background-position: $shineSize;
  }
  16% {
    background-position: -$shineSize;
  }
  16.01% {
    background-position: $shineSize;
  }
  30% {
    background-position: -$shineSize;
  }
  100% {
    background-position: -$shineSize;
  }
}

.roadmap-container {
  display: flex;

  .timeline {
    width: 48%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    padding-bottom: 8rem;

    .timeline-line {
      width: 50%;
      border-right: 1px solid #ccc;
      flex-grow: 1;
      position: relative;

      .today-marker {
        border-right: 3px solid #aaa;
        margin-right: -2px;
      }

      .event {
        position: absolute;
        right: 0;
        cursor: pointer;
        z-index: 20;

        &.importance-0 {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          background: #444;
          border-radius: 50%;
          transition: background 0.2s linear;
        }

        &.importance-1 {
          &::after {
            $size: 7px;
            width: $size;
            height: $size;
            margin: -$size / 2 + 0.5px;
          }
        }

        &.importance-2 {
          &::after {
            $size: 11px;
            width: $size;
            height: $size;
            margin: -$size / 2 + 0.5px;
          }
        }

        &.importance-3 {
          &::after {
            $size: 17px;
            width: $size;
            height: $size;
            margin: -$size / 2 + 0.5px;
          }
        }

        .text {
          position: absolute;
          left: 0.9rem;
          top: -0.8rem;
          width: 8rem;
          font-size: 80%;
          padding: 1rem;
          margin: -1rem;

          .title,
          .date {
            position: relative;
            z-index: 2;
          }

          .date {
            font-size: 80%;
            color: goldenrod;
          }
        }

        &:nth-child(even) {
          .text {
            position: absolute;
            left: auto;
            right: 0.9rem;
            text-align: right;
          }
        }

        .description {
          opacity: 0;
          padding: 0.2rem 0.2rem;
          box-shadow: 0 0 0.4rem 0.5rem white;
          transition: opacity 0.2s linear;
          background: white;
        }

        &:hover {
          z-index: 120;

          &::after {
            background: goldenrod;
          }

          .description {
            opacity: 1;
          }
        }
      }
    }
  }

  .progress {
    margin-left: 2%;
    width: 48%;

    .item {
      cursor: pointer;
      position: relative;

      h3 {
        transition: opacity 0.2s linear;
      }

      .item-details {
        pointer-events: none;
        z-index: 1000;
        background: white;
        position: absolute;
        right: 110%;
        width: 20rem;
        top: 0;
        opacity: 0;
        border-radius: 0.8rem;
        box-shadow: 0 0 1rem 1.1rem white;
        transition: opacity 0.2s ease-in;
        overflow: hidden;

        .text {
          padding: 0.5rem 1.2rem;
        }
      }

      &:hover {
        .item-details {
          opacity: 1;

          .text {
            opacity: 0;
            animation: expand 0.4s ease-in;
            animation-fill-mode: forwards;
          }
        }

        h3 {
          opacity: 1;
          color: #444;
        }

        .fragment {
          &::before {
            opacity: 0.4;
          }

          &::after {
            opacity: 1 !important;
            animation-play-state: running !important;
          }
        }
      }
    }

    .fragments {
      display: flex;

      .fragment {
        position: relative;
        color: white;
        text-align: center;
        flex-grow: 1;
        padding: 0.3rem 0.6rem;
        border-radius: 1rem;

        &::before {
          transition: opacity 0.2s linear;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
        }

        &.stage-0::before {
          background: rgba(255, 255, 255, 0.9);
        }

        &.stage-50::before {
          background: rgba(255, 255, 255, 0.9);
          background: linear-gradient(
            240deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 48%,
            rgba(255, 255, 255, 0) 52%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        &.stage-100::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;

          background-image: linear-gradient(
            60deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.23) 45%,
            rgba(255, 255, 255, 0.6) 50%,
            rgba(255, 255, 255, 0.23) 55%,
            rgba(255, 255, 255, 0) 60%
          );
          background-repeat: no-repeat;
          background-clip: unset;
          background-position: -10rem;
          animation: shine 4s linear infinite;
          animation-play-state: paused;
          transition: opacity 0.2s linear;
        }

        + .fragment {
          margin-left: 0.3rem;
        }

        background: goldenrod;

        + .fragment {
          background: firebrick;

          + .fragment {
            background: forestgreen;

            + .fragment {
              background: steelblue;

              + .fragment {
                background: rebeccapurple;
              }
            }
          }
        }
      }
    }
  }

  h2 {
    font-size: 150%;
    font-weight: bold;
    text-align: center;
    margin: 0 0 1rem;
    padding: 0;
    color: goldenrod;
  }

  h3 {
    font-size: 120%;
    font-weight: bold;
    color: #444;
    text-align: center;
    margin: 1rem 0 0.3rem;
    padding: 0;
    opacity: 0.5;
    display: flex;
    justify-content: center;

    &::before,
    &::after {
      content: "";
      top: 0;
      width: 2rem;
      height: 1rem;
      margin-top: 0.2rem;
      background-image: url("../assets/roadmap/ornaments_asmaarzq.jpg");
      background-position: -951px 363px;
      background-size: 542px;
      z-index: 1;
    }

    &::after {
      left: inherit;
      transform: scaleX(-1);
    }
  }
}

.footer {
  font-size: 80%;
}

.ornament {
  background: url("../assets/roadmap/ornaments_asmaarzq.jpg");
  margin: 0;

  &.separator {
    border: none;

    height: 2.1rem;
    width: 18.46rem;
    margin: 1rem auto 0;
    background-position: -341px 335px;
    background-size: 964px;

    &.bottom,
    &.top {
      margin: 0 auto 0;
      height: 2rem;
      background-position: -37px 416px;
      background-size: 1093px;

      &.alt {
        background-position: -322px 602px;
        background-size: 927px;
      }
    }

    &.bottom {
      transform: rotate(180deg);
    }
  }
}
</style>
