export default {
  title: `A moment of reflection`,
  date: `2022-07-10`,
  intro: `We are now half a year away from when I'll need to take a hard look at the finances and this brings a whole swirl of thoughts to my head. Today I'd really like to share them with you.`,
  fullText: `
<p>
  First let's get one thing out of the way - financially the game is doing rather poorly. I am currently getting about 5% of the lower-tier goal that I set and I don't think there's a big chance this will change in the near future.
</p>
<p>
  Now it's crucial to me that I immediately follow this by saying how grateful I am for the community that is built around this little game of mine. You folks are absolutely amazing, extremely supportive, patient and on top of that quite a bunch of you opted to support me financially! I am really, truly humbled and most appreciative of everything you've done for me folks! 
</p>
<p>
  How does that pair up in my mind with the financial performance? Well, it's all result of the choices I made, of the effort I put in. I think the game is something unique and I saw many players really enjoy and have awesome moments playing the game. But at the same time I know that the game has its shortcoming and limitations. There are things that don't work that well or that took a long time to get them to a good spot (shout-out to folks who helped me get them there with their feedback!). I struggle with many different facets of game development, publishing and community management, not to mention my poor performance around spreading the word.
</p>
<p>
  So in the end then I really hold myself accountable for the game's performance. But at the same time it's not all bad - I am happy to say I am proud of what I have done so far and I know that I have put forward my best work.
</p>
<p>
  I still find myself thinking that I could've done more, could've worked more, put more hours. On the weekends I tell myself that "maybe I'll just take a moment to check something out" or do some quick tweaks or write an article - and hell, I still often do that. The curse of self-employment - the work truly never ends.
</p>
<p>
  But somehow the perspective that I am half a year away from this assessment makes me realize that for this project to work, to sustain itself - that's not really an option. If I need to spend every waking moment pushing this game forward to get the income I'd be comfortable with, then is it really sustainable? And there's hubris in thinking this way as well - it hinges on a belief that this idea for the game is really that good and that I have all the skills necessary to make it that successful. And I know well enough that at the least on some fronts, like marketing, I am doing really quite poorly.
</p>
<p>
  I have mentioned on Discord that I consider to take 1-2 weeks off this summer, a holiday so to speak. Part of me wanted to "postpone" this, push it back and just use that time to continue pushing the game forward. But I believe that I need that time off and chances are the game itself will also be better off with me taking a moment to breathe.
</p>
<p>
  Well, now that we have most of this doom and gloom out of the way, what's next for Soulforged?
</p>
<p>
  First of all - the monetization does not and will not change. Not that I even considered it, but I do believe it's worth re-stating. The game will continue to be totally free with no micro or macro-transaction bollocks of any kind.
</p>
<p>
  Second - the game development will continue at the current pace at least till the end of this year. I sometimes think that maybe I should be turning my attention to promoting the game and spreading the word, but I also know that a good product speaks more than thousands of marketing messages - and I am determined to make Soulforged the best game that I can. So most of my energy will be going into the game itself - user interface, performance, systems & content.
</p>
<p>
  Finally - I now brace myself that by the end of this year I am most likely going to be looking for a "regular" job. But that does not mean that the game will shut down at that time, not at all! The cost and work required to run and maintain the game server is currently extremely low and I am confident I am able to keep it running for the years to come. The way I approach designing the game and new content also means that I can be very much hands-off and the game can continue to operate without my direct intervention.
</p>
<p>
  All in all I can say - I am having a hell of a time! It can be quite challenging and I am disappointed with myself more times than I'd like, but there's nothing I'd rather be doing right now. A massive thank you to each and every person who's on this journey with me!
</p>
`
};
