export default {
  title: `The game is growing!`,
  date: `2022-08-24`,
  intro: `This year's summer I was exploring some new frontiers and it appears this led to playerbase growth! Big time!`,
  fullText: `
<p>
  Many a time players have asked me about whether the game is or will be available via AppStore and GooglePlay. It's a fair question, but I am inclined to think that having website shortcuts on mobile home screen there's really not that much of a benefit for the player to have an app vs using the website.
</p>
<p>
  But utility aside there's the "exposure". It's a curious thing - I still have a vivid memory of watching a <a href="https://en.wikipedia.org/wiki/Game_Developers_Conference" target="_blank">GDC</a> talk about marketing strategies and how an experienced game studio came to conclusion that store listing alone doesn't really do anything to bring in new players. And he wasn't alone either - other devs shared that sentiment in other talks. And so based on that I  assumed that having my game published on mobile stores wouldn't really do much. 
</p>
<p>
  But with so many players raising it with me and mentioning how they feel "safer" about using an app than just visiting a website - I felt inclined to challenge my assumptions.
</p>
<p>
  Building a webapp these days is relatively simple. What I did was definitely the path of least resistance - simply having an app that opens a seamless, fullscreen browser and points it to the game. Lots of oven-ready tools that can basically do that for me and do it for many platforms. It also meant that as I update the client - I don't need to worry about releasing a new version on the store - they access the same exact client that players did through the browser.
</p>
<p>
  But then I found out how that's only a small part of the work needed. Next I learned about Google policy blocking in-app browser from using login page, working around it (simple enough, just mocking <a href="https://en.wikipedia.org/wiki/User_agent#Use_in_HTTP" target="_blank">browser user agent</a>), creating a developer account and fill in all of the information needed to publish on game store. Fun fact: Soulforged is both for 7 year olds plus and for mature audiences only, simply depending on the country.
</p>
<p>
  With the fees paid, screenshots uploaded, descriptions created, binaries prepared and tags picked I was now waiting for the game to be reviewed for GooglePlay and have it published. Another interesting thing I learned - mobile apps definitely go through a review by a person, as I saw game accounts created that clearly correlated to GooglePlay and AppStore reviews. One of them even joined on Discord.
</p>
<p>
  Anyway, with all that done the game was published and first bugs started to stream in. There is not that much to the app itself, and so there's only so many bugs it may have, but fixes were needed all the same.
</p>
<p>
  I then turned to Apple's AppStore and aside from the much steeper fee there was not that much of a difference setting it all up. But when the review came around one issue was flagged as a show-stopper and that was lack of automated account deletion. Fortunately with me working in an indie "studio" the approval process was super-quick and 3 hours after the review came back I was already re-submitting it pointing to where the account deletion option can be found.
</p>
<h2>Time off</h2>
<p>
  With both apps now being available, I was approaching a short, planned holiday. Over the next two weeks I was mostly being either very lazy or working on refurbishing this cosy old house we call home. During that time I was staying very well off of Discord, not checking "Feedback" channel and other general discussions, only looking at "Bug reports" if anything popped up. It was a very uneventful time for me game-wise and I really feel I disconnected well from my work. It was good.
</p>
<p>
  So when the time came for me to get back to work, I was very eager to see how I'll feel about it. After all, it's very common for people to decide it's time to move to another job during a long holiday. Something about having proper distance and perspective. And I was delighted that I found myself very eager to get back at it and continue my work! Human brains are strange like that, but I was glad that what I have for this project is my continued passion, rather than doing this purely out of obligation.
</p>
<h2>Reap what you sow</h2>
<p>
  When I finally came back and took a glance over Discord, it was clear we got a handful of new faces popping up and so I glanced at the download stats for both stores. There was a whooping 250+ downloads over those two weeks! That's over four times the size of the active playerbase at that time and that's all with absolutely zero marketing other that the store listings.
</p>
<p>
  Clearly my previous assumptions that listings don't do anything was quite inaccurate. I'm thinking that the one thing that they didn't account for in those talks is the factor of "truly free". With this many downloads having those apps is definitely a vector and it was great to see new players coming in!
</p>
<p>
  But there was one more consequence to publishing those games for mobile stores and a slightly shameful one I'd say. It didn't take long to get users who promptly pointed out that asking to log in to your Google account in some new app doesn't feel secure, not at all. I was not surprised - I myself would not want to use an app that asks me to enter my Google credentials (or any other accounts for that matter). So this needed fixing and fast.
</p>
<p>
  Again, benefiting from the short development cycle I worked tirelessly to update the authentication process. The goal was so that the apps use existing accounts that players already have on their mobiles, without entering the password at all. A week later that change was live and I started pondering - what if I were to publish this game on <a href="https://en.wikipedia.org/wiki/Steam_(service)" target="_blank">Steam</a>? Can a webapp be published on Steam at all? 
</p>
<p>
  Couple searches later and I am working hard on wrapping the game into an executable, paid the fee and started prepping the store listing. Of course the authentication issue had to be sorted out as well and I had to build a whole separate method for Steam alone.
</p>
<p>
  Following reviews, uploading dozens logos and graphics, I had the listing approved and the game coming up as "Coming soon". Time to upload the build for one final review and that's where I bumped into another wholly new issue. It was not very apparent while setting up the listing, but it turns out that Steam demands that for a game to be published it must have a gameplay trailer.
</p>
<p>
  Dang. I was working on a trailer already, but it was cinematic/story trailer, not a gameplay one. That was a bit troubling, but I buckled up and started planning. Maybe this is an opportunity? Maybe I should have done one some time ago and it's high time to finally make one? 
</p>
<p>
  I conceptualized what I want to show: It'll be a short video going over several systems - travel, crafting, combat. Some semblance of a story where some scenes show related characters. And finally - a narration to fill in the air while the shots are flowing. Now I already had one person working the voiceover for the cinematic trailer, but I wanted to have someone else provide voice for this one (for couple reasons). And that's where I decided to do a little something that's very unusual for me - I contacted a <a href="https://en.wikipedia.org/wiki/Online_streamer" target="_blank">streamer</a> whose stream I often watch and I thought the voice would fit very well. I don't know her well, other than the streams and I am just one of many viewers on her channel. But where's the harm in trying?
</p>
<p>
  On the same day the cheerful <a href="https://www.twitch.tv/2dkiri" target="_blank">2DKiri</a> agreed to lend her voice for a modest fee and I was working hard to get the script to her. And just the very next day she already delivered the recording to me and by the end of the day the video was ready! It seemed to get a positive reception and enabled me to re-submit my Steam listing. All that was left was waiting for the Steam release day which could be no earlier than 18th August.
</p>
<h2>Full steam ahead</h2>
<p>
  On the 18th of August I have triggered a steam release. And that seemed to open some floodgates. Before this release the previous biggest boost to playerbase was after <a href="https://massivelyop.com/2022/01/07/soulforged" target="_blank">MassivelyOP published an article</a>. The number of registered accounts jumped from a little over 200 to almost 500 at that time. Months later and I was still looking at only about 600 registered accounts total.
</p>
<p>
  We are now 6 days after the steam release and the game has over 1.1k registered players and almost 300 characters total, at the time of writing.
</p>
<p>
  This is also visible through Discord, as new people joining Discord is an almost daily occurrence and many new players engage in discussions and pursue new discoveries and explore the game!
</p>
<p>
  It's not all good stuff however, as almost immediately the new wave included players who were very quick to point out that the game is "unplayable" because of the Action Points system and the game would simply be better by removing that mechanic. I am very glad that I am at a point where thanks to the game's community I have enough confidence to stick to this design choice. But I still try my best to engage with all folks and explain my philosophy. Maybe some folks will decide to give this approach a try? 
</p>
<h2>Closing thoughts</h2>
<p>
  These are very exciting times for me. I am very happy I decided to give those stores a go and very appreciative of everyone who nudged me in this direction. 
</p>
<p>
  There's always a bit of risk exposing a niche game like this to a more general audience - not everyone will find the game appealing, but I already met some new folks who appreciate this design so I think in the end it's well worth it.
</p>
<p>
  Also - in some ways I also feel like having Soulforged on these stores helps me project more confidence about the project, show my commitment to developing it and maintaining it as we go. And of course - all that keeping the game absolutely, completely and totally free.
</p>
<p>
  Onwards and upwards! Thank you for reading!
</p>
`
};
