import news31 from "./news/31.js";
import news30 from "./news/30.js";
import news29 from "./news/29.js";
import news28 from "./news/28.js";
import news27 from "./news/27.js";
import news26 from "./news/26.js";
import news25 from "./news/25.js";
import news24 from "./news/24.js";
import news2022_01_14 from "./news/2022_01_14.js";
import news2022_01_03 from "./news/2022_01_03.js";
import news2021_12_24 from "./news/2021_12_24.js";
import news2021_12_17 from "./news/2021_12_17.js";
import news2021_10_22 from "./news/2021_10_22.js";
import news2021_09_24 from "./news/2021_09_24.js";
import news2021_08_27 from "./news/2021_08_27.js";
import news2021_07_30 from "./news/2021_07_30.js";
import news2021_07_02 from "./news/2021_07_02.js";
import news2021_06_18 from "./news/2021_06_18.js";
import news2021_06_04 from "./news/2021_06_04.js";
import news2021_05_07 from "./news/2021_05_07.js";
import news2021_04_23 from "./news/2021_04_23.js";
import news2021_03_26 from "./news/2021_03_26.js";
import news2021_02_26 from "./news/2021_02_26.js";
import news2021_02_12 from "./news/2021_02_12.js";
import news2021_01_29 from "./news/2021_01_29.js";
import news2021_01_15 from "./news/2021_01_15.js";
import news2020_12_31 from "./news/2020_12_31.js";
import news2020_12_18 from "./news/2020_12_18.js";
import news2020_12_04 from "./news/2020_12_04.js";
import news2020_11_24 from "./news/2020_11_24.js";
import news2020_11_23 from "./news/2020_11_23.js";

window.news = [
  news31,
  news30,
  news29,
  news28,
  news27,
  news26,
  news25,
  news24,
  news2022_01_14,
  news2022_01_03,
  news2021_12_24,
  news2021_12_17,
  news2021_10_22,
  news2021_09_24,
  news2021_08_27,
  news2021_07_30,
  news2021_07_02,
  news2021_06_18,
  news2021_06_04,
  news2021_05_07,
  news2021_04_23,
  news2021_03_26,
  news2021_02_26,
  news2021_02_12,
  news2021_01_29,
  news2021_01_15,
  news2020_12_31,
  news2020_12_18,
  news2020_12_04,
  news2020_11_24,
  news2020_11_23
];

window.news.reverse();

window.news = window.news
  .map((n, key) => ({
    ...n,
    newsId: key
  }))
  .reverse();
