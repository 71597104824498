export default {
  title: `Creative work`,
  date: `2021-07-30`,
  intro: `Today I'd like to talk a bit about the creative side of work as well as share some updates on the upcoming release.`,
  fullText: `
<h2>Quick status update</h2>
<p>
  I continue to progress with the development of Soulforged and almost every day I'm thinking about the self-imposed deadline of the September's release. I've set up that estimate all the way back in early May, when I first published the <a href="https://soulforged.net/#/roadmap" target="_blank">Roadmap</a> and I must say it feels good that it still doesn't seem much off, if at all.
</p>
<p>
  I'm now finishing up the last bits around the trading system. Exchanging items between players was pushed to the pre-alpha server earlier this week and what's left is the "shop" system - where you can set up a shop (or multiple shops in different locations) and offer or ask for items to be traded with other players for essence while you're away.
</p>
<h2>The creative bits</h2>
<p>
  I also continue to work on the game world and it's specifically this kind of work that made me realize how much one of the GDC (Game Developers Conference) <a href="https://www.youtube.com/watch?v=u9P20Jskwmo" target="_blank">talks on the subject of creative work</a> resonates with me. Particularly the part that talks about processing stuff in the background of your mind. When presented with a problem we often feel prompted to just focus and figure out the solution right there and then. And it appears that an ability to carry on with the discomfort of having a problem without a solution in your head is something that greatly helps with the creative process. 
</p>
<p>
  Just in the last month I had a few very clear examples of this process at work. One of them is very recent and quite mundane - I was designing the shop interface and while I settled on the simplification that both sales and purchases are made strictly with Essence (in-game currency), I was still struggling to come up with an interface that is even remotely understandable that provides the player with the option to put items up for sale as well as list items they want to buy.  
</p>
<p>
  My head was filled with a mess of different problems and angles: what happens when you list to buy something, but then you don't have enough essence; how do you access the inventory of the shop of the items you sell; how can you easily add more items; what if the items have different deterioration stages; what happens when an item is all sold out; how do you manage the inventory of your shop; and many other questions. But instead of forcing myself to answer all of those questions right that moment, I decided to divert my attention to writing some automated tests for code I produced earlier instead and let the shop problem linger in my head for a bit.
</p> 
<p>
  It was discomforting, I'd find myself questioning whether I can come up with something useable at all and maybe the solution requires me to re-engineer some big parts of the existing codebase. But on the next day when I sat down to work in the morning, I knew what I wanted to do. I had the answers to most of those burning questions and I had a clear way forward. It's a really strange feeling where all those questions I had now seem almost wrong, like the answer is obvious and always was. Even now as I write this, I have to think really hard to picture what my conundrum was in the first place.   
</p>
<h2>Creative world building</h2>
<p>
  This process is only more prominent and more impactful when I'm working on the world building. You might have seen a screen I shared in the last update that outlines the world map and the different colors there represent different biomes - having clear lines between different areas helps make them feel more distinct and better inform the player what to expect as they traverse the world. This design choice was made in accordance to one of the principles I adopted when starting this project which is to put the gameplay experience front and center. That means that realism may be thrown out of the window at times for the sake of the game experience, as it was the case here. 
</p>
<p>
  There are lots of benefits to designing the world this way, but one challenge is - how would you ever explain it? What kind of worlds would be so chaotic & arbitrary? How does it make sense for player characters to find themselves there? While ultimately these questions could be brushed aside, I feel like it makes for a better experience when you have sensible answers to them.
</p>
<p>
  And again - the answers were somewhere in my head already, what I did was just keep this problem at the back of my mind and let it mull and fester. I'd come up with solutions that themselves have their issues and create a whole bunch of nonsense, but I'd just add them to mess that was happening in my head.
</p>
<p>
  Few days later I'd go to bed with the familiar discomforting feeling in my gut, questioning if I am cut out to even attempt to answer questions like that only to wake up next day and start writing down something that actually outlines a cohesive, robust and I hope interesting world. Of course all this wouldn't be half as good as it can be without people who are supporting me and who let me bounce my ideas off of! Then it's a matter of just writing it down and... resisting the urge to flat out share the whole thing with the rest of the world. I'm confident that a story like this is best told via the game itself and I'm super excited about the prospect of players engaging with this world and discovering the little things I sprinkle all over that tell the story of how this world came together.
</p>
<h2>On the horizon</h2>
<p>
  As I mentioned, I remain optimistic about the September estimate, with the assumption that all major work will need to be wrapped up and concluded by the end of August, leaving September to work out final quirks and put everything together.
</p>
<p>
  I feel one very important thing to state about the September release is that it'll include most of the content that I plan to have in the game available for free. And for me the logical consequence is that this version of the game will also be accessible completely for free to everyone.
</p>
<p>
  Following that release I'm hoping to get the attention of more players to join the game world and it's when I'll start working on the additional content that is meant to be accessible with the subscription fee.
</p>
<p>
  I hope everyone who supports me via Patreon already knows how much I appreciate their support and how much it means to me! And with Patreon in mind I'm thinking that if I manage to garner enough support there, I might explore having the game be kept free-to-play in it's totality - that is without the subscription fee at all. 
</p>
<p>
  I'd love for this game to be freely accessible, without the paywall and I recognize that while something like 5 dollars a month isn't often considered to be a lot, for many people that could still be the barrier they'd not be able to overcome. It only becomes more painful when friend groups are affected and some people end up left out. I'd love for the game to simply stay free but at the same time I do not want to be a constant financial burden to my biggest fan and supporter that is my wife. So a subscription would simply be means to an end and if I could achieve it in another way without compromising the game and player experience, I'd definitely like to explore these options.
</p>
<p>
  And of course it goes without saying that whether there will be a subscription or not in the end - there will not be any other form of in-game purchases and benefits to be had. Should I get enough support on Patreon and have Patreons decide to keep the game free-to-play, that means the game will be free in its entirety, with no in-game shop or any other such bollocks.
</p>
<h2>Closing thoughts</h2>
<p>
  I'm super excited, terribly anxious and at times terrified about the upcoming release, but I do my best to try and focus on the positive emotions. Next few months will be very interesting, but in the end I just hope that what I'm cooking will bring joy to a bunch of people.
</p>
<p>
  As always, I'm very keen to hear your thoughts, be it on the parts of this article, the game direction in general or otherwise! Please feel invited to join us on the <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> for a conversation!
</p>
`
};
