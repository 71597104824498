import "@/script/news.js";
import "@/script/reviews.js";
import Page from "./page.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import sections from "./script/sections.js";

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    ...Object.keys(sections).map(sectionId => ({
      path: `/${sectionId}/:anchor`
    })),
    ...Object.keys(sections).map(sectionId => ({
      path: `/${sectionId}`
    })),
    {
      path: "/" + global.NEWS_PATH + "/:id"
    },
    {
      path: "*",
      redirect: "/home"
    }
  ]
});

// enable html-based anchoring-like behaviour
let interval;
router.afterEach(to => {
  clearInterval(interval);
  if (to.params && to.params.anchor) {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
      setTimeout(() => {
        history.scrollRestoration = "auto";
        clearInterval(interval);
      }, 1000);
    }
    interval = setInterval(() => {
      const el = document.getElementById(to.params.anchor);
      if (el) {
        location.hash = `#/${to.path
          .replace("/", "")
          .split("/")
          .shift()}`;
        el.scrollIntoView();
        clearInterval(interval);
      }
    }, 5);
  }
});

new Vue({
  router,
  render: h => h(Page)
}).$mount("#app");
