export default {
  title: `Telling a story`,
  date: `2022-06-04`,
  intro: `Recently Soulforged received an update that included a new, unique storytelling feature. Let's explore what this means for the game!`,
  fullText: `
<p>
  I find storytelling very challenging. I don't really have any experience with it outside Soulforged, I don't have any formal education in the field and to top it all I am not a native English speaker. Then coupled with the fact it's just one of the very many things I work on for Soulforged, my expectations are relatively low. I am not going to compete with accomplished book authors, competent movie producers or other game writers.
</p>
<h2>Soulforged Zero</h2>
<p>
  That doesn't discourage me from doing my best and it's maybe because of that that I am keen to explore new and innovative ways of storytelling. Running Soulforged Prelude I included some non-playable characters that I'd create Discord accounts for and role-play as those characters chatting directly with the players. 
</p>
<p>
  It created some really great interactions, allowed the story to evolve with direct involvement from the players, allowed some relationships to form and it included some very specific "quests" that rewarded players with some very unique rewards - such as becoming an orc or reshaping the land by flattening a mountain.
</p>
<p>
  It was great, it created some of the best, most memorable moments. It also created some of the worst divisions with how the players felt being treated differently. It also included a host of challenges for me, scheduling around those chats, while also being unable to really interact with players in all of the timezones equally.
</p>
<p>
  Running the old Soulforged was a pretty stressful ordeal. Don't get me wrong, I loved it, but it was still very stressful. And the way I was role-playing these characters definitely played a part of what made it stressful.
</p>
<p>
  But to have Soulforged without such a feature felt like a massive step backwards. I spent some time thinking on which parts were more stressful and impractical. Could I keep the feature in in some way, but without all those negative consequences? 
</p>
<h2>Engineering a solution</h2>
<p>
  So there's couple priorities for me to juggle. First - I want players to be able to leverage anything they know that they can ask about. They found an unusual ruin? Maybe some NPC knows something useful about it. A strange item? Maybe someone could point you to where this could be useful. I can't predict absolutely everything that players can come up with, so in all likelihood I can't just build a full library of questions/answers. I will need to let players ask any question they want, completely free form.
</p>
<p>
  Assuming I provide a way to ask questions, I now need to find some way of "constructing" an answer. Anyone who attempted to contact some company's support system only to be greeted by a chatbot will likely agree with me that those bots - they don't really cut it. And even then it's really only as good as the library of information it's built with, looping back to the first concern of how I'd need to predict all questions. It can be theorized that an artificial intelligence could be employed, but we're basically talking passing <a target="_blank" href="https://en.wikipedia.org/wiki/Turing_test">turing test</a> and somehow I doubt I'd be able to whip that up, and definitely not in timeframe of keeping the game relevant.
</p>
<p>
  So we can employ what some refer to as "artificial artificial intelligence" - that is involve a human. Basically it seems the best way of generating quality answer is simply having someone write it.
</p>
<p>
  This sounds to be doable, pretty simple even - almost like a glorified chat. The hitch is - there is potentially a tons of questions to answer and the game development team is really small (i.e. just me) and the budget continues to be near non-existent. 
</p>
<p>
  So what can I do about this? There's two things that come to my mind. First - I can try and reduce the amount of duplicate questions. I can do that by providing some way to review previously asked questions and show the answers that I have previously provided. Second - make sure that reviewing these questions and providing answers is as seamless and effortless as I can.
</p>
<h2>Enter "Ask Me Anything" chat option!</h2>
<p>
  What I settled on is a brand new feature - one that I am, to my knowledge, pioneering! Right now, when playing Soulforged and chatting with most (of admittedly very few at the moment) non-player character you will find a "More..." button in the dialogue window. There may be some additional constraints for you - like you may need to be friendly with the character or know the language they are using.
</p>
<p>
  Once you meet that requirement, clicking the button you will open a dialog which allows you to enter a question and it will list similar questions previously asked by you or other players.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/news/26/ama.jpg">
    <img src="./images/news/26/ama.jpg">
  </a>
  <span>Asking an example question</span>
</span>
<p>
  You can then either click any of the similar questions to review the answer that was previously provided, or if you decide your specific question isn't already answered you can submit it! What happens next is the dev team (i.e. me) gets a notification via Discord and I try to answer those questions as promptly as I can, while staying in character.
</p>
<p>
  Imagine playing your favourite game, being able to tell that particular NPC that what they did was extremely stupid! Or ask them if your suspicion about their romantic involvement with someone are true. You could ask them to explain and maybe change their rationale about something! It'd enable an unprecedented way of connecting with characters and I'd be very excited to see more games adopt a similar system.
</p>
<p>
  For some folks this may have a clear resemblance to how pen-and-paper role-playing games can play out - where the Game Master enables players to hve their characters act and talk freely in any way they want and have the world react to that. It's definitely parts of where the inspiration for this came from! I don't think that games can really provide the kind of freedom that pen-and-paper games enable, but there's no reason not to explore including elements like this!
</p>
<p>
  The room for this to grow are enormous. At some point it's possible to explore open-ended quests being issued and then played out. Some risks of the previous solution still persist, like some players will see bias in the character attitudes, but in the end I don't think this can be avoided. Most importantly it gives players the options while at the same time keeping the pressure to respond low. I am very excited to see where this could go next!
</p>
<p>
  The best part? Like I said earlier - this is already implement and can be found in the game right now! Some of the players have already used it and got their responses too. I am very excited to see where this takes us and I welcome each and every of you to give Soulforged a go - with the updated introduction and new features like this one now might be the best time to give the game a go! 
</p>
<p>
  Whatever you decide to do, I want to thank you for reading this article and I wish you a wonderful day!
</p>
`
};
