export default {
  title: `Website goes live!`,
  date: `2020-11-23`,
  intro: `Here you will find all of the progress updates and various articles regarding Soulforged`,
  fullText: `
<h2>What to expect</h2>
<p>
    On this website I'll be posting news, updates and articles related to game development of the next incarnation of Soulforged - updates on the progress made so far, upcoming game systems, previews, the way the game world is shaping up and occasionally an outline of failed experiments!
</p>
<p>
    Don't worry about missing any of the news as any new article I post here I'll mention in the announcements channel on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>!
</p>
`
};
