export default {
  title: `Soulforged: status update`,
  date: `2020-12-18`,
  intro: `Today I'd like to share with you some progress made on the Soulforged Project!`,
  fullText: `
<h2>The map</h2>
<p>
  First I'd like to go over the map - which is one of the main ways you'll experience and explore the world. I think to give you the best grounding, let's start with a sample, small island I created (click for a detailed image):
  <a target="_blank" href="./images/2020-12-18/map.jpg">
    <img class="fit" src="./images/2020-12-18/map.jpg">
  </a>
  Gone are the hexes, the map is now completely free-form. On this map I included the locations (white spots) and paths (dotted lines) that are connecting them. Similar to Soulforged 1, your character will be present in one of these locations, having access to the infrastructure and resources present in that location, and they will be able to travel along the paths to the other nearby locations.
</p>
<p>
  The features you can see on the map (like the trees) will correspond to resources available in nearby locations. Some of the other features like the chasm or the cliffs - you can also expect them to be more interactive than just flavour!  
</p>
<p>
  If you look closely enough on this map, you will most likely notice what looks like a cave entrance. Not only is it a feature on the map, but if you walk in that direction from the nearest location you'll be transported to a new, separate map! Here's a very quick & dirty sketch of what a tiny cave would look like:
  <a target="_blank" href="./images/2020-12-18/Cave_PoC.jpg">
    <img class="fit" src="./images/2020-12-18/Cave_PoC.jpg">
  </a>
</p>
<p>
  This means that one of the features that you might have seen in Soulforged 1 will be going away - and that's the completely open underground area, where almost every wall could have been dug up. But don't worry, it's not all going away! You can expect digging and maintaining underground tunnels to still be a thing, they'll just be a bit more handcrafted.
</p>
<p>
  I believe the real amazing value these maps bring is the potential to surprise and provide unique, distinct areas for players to explore. And this is only possible given the amazing (licensed) tool that I'm using to make these maps - the island overground took me only about an hour from conceptualization to the version of the map you can see today.
</p>
<h2>The main screen</h2>
<p>
  Next - let's take a look at how the game looks like right now!
  <a target="_blank" href="./images/2020-12-18/Main_UI.jpg">
    <img class="fit" src="./images/2020-12-18/Main_UI.jpg">
  </a>
  There's a whole lot to unpack here!
</p>
<p>
  Starting from the left hand: the map and the ugly (placeholder) "arrows". That's the slice of the world map you will be seeing to help you orient yourself where you are in the world. Currently there's no plan to include a world map as a whole (scary!) but that's only enabled by the new action point system. With action points, you don't need to plan ahead a path for a long trek and can move across several locations in one sitting, very quickly.
</p>
<p>
  Now onto the right side of the image: we have the main status section at the top. That includes the action points bar (blue bar), the current amount of "essence" currency you have (13.1k) and your character status (currently showing only hunger).
</p>
<p>
  What follows further down is just very much a work-in-progress - basically a dump of all of the things surrounding you, stuff in your inventory and even the crafting recipes. I still need to come up with a nice way to organize those (trying to avoid the double-tab that Soulforged 1 UI suffered from), but it gives you some idea of how things will be presented.
</p>
<p>
  One more note on the look and feel of the interface - this is what I came up with so far and I think it does the job rather well. Not sure if it isn't leaning too much into a "dark" feeling, but I think it does work well with the map view. I'd love to hear what you think about this UI - the borders, background, buttons & scrollbars. Aye or nay? Let me know on Discord please! :)
</p>
<h2>Ongoing action</h2>
<p>
  Finally, the last item I'd like to bring to your attention today is the concept of an ongoing action. But to do that, I need to first cover action points system in greater detail. 
</p>
<p>
  The use of action points impacts every single aspect of the game and its design. The core idea behind action points is that you play the game when you want, you don't need to follow the exact schedule or react to numerous notifications on something happening to your character. But what this means is that there are some... potentially dubious design choices to be made.
</p>
<p>
 Let's take a look at hunger, for instance. One simple implementation is to make your character go hungry over time, requiring you to instruct your character to eat every couple of hours or so. That would inevitably create some weird scenarios thou. Imagine you're stranded and you're all out of food. You may be able to forage some, but you're out of action points at the moment. Now you end up waiting a few hours to get the action points back, but during that time your character became increasingly malnourished, reducing your statistics and as a result - the efficiency and the chances you will manage to forage some food. You now find yourself regretting you didn't check the game every 10 minutes and use your action points as they came in, giving you a better chance to survive. 
</p>
<p>
  This is just but one example, the same would apply to getting cold or hot, bleeding out or recovering from wounds - all of these, if they progressed real-time, would end up putting the players in a place where there's an optimal time to play - which in a worst case scenario could end up being in the middle of the night! And that just won't do.
</p>
<p>
  Solution to the problem? These effects will only progress when you are either spending action point or when action points are wasted if you are at maximum action points already. Will this make for some bizarre scenarios? Certainly. But I'm happy to have bizarre over toxic game design.
</p>
<p>
  Let's now circle back to the concept of an ongoing action. With the above explained, I imagine it's starting to become clear why a gathering action cannot simply involve selecting the amount of attempts/action points you want to spend gathering and have it just executed - you could find yourself freezing to death or getting hungry. That's why when you are gathering, you enter a new, separate mode that then allows you to collect the items you want and act on any interruptions that may happen (including accidents!).
</p>
<div>
  <video src="./images/2020-12-18/SF2_demo.mp4" controls autoplay loop />
</div>
<p>
  What you can see in the above video is how clicking the "Gather" enters into gathering mode, where you can select the amount of attempts, which also shows how many action points will be spent on the action bar in the top right.
  When the action is commenced the character manages to gather couple of the berries before they end up feeling hungry. But a quick snack and the character can resume gathering the berries they wanted to pick up.
</p>
<p>
  This system will also extend to cover wounds or other mood-altering effects.
</p>
<h2>Technical comments</h2>
<p>
  What I've shown you today is all work in progress, but it's all a working game engine! The video recorded was done live (had to cut out a short section where nothing was happening), the UI is all functional and it's using the all new engine, meaning vastly improved performance and also lowered amount of data sent between the server and the client. I'm also looking to improve client performance - by simplifying the page structure and reducing reliance on png file format.
</p>
<h2>Closing words</h2>
<p>
  I hope the stuff I showcased today makes you as excited as I am about this new incarnation of Soulforged! There are still many systems that I need to re-invent with the action points in mind (combat, pets, buildings, etc.) so there's still a long way to go. But I do feel we're headed for something absolutely amazing!
</p>
`
};
