export default {
  title: `The Hacking Challenge begins!`,
  date: `2022-04-28`,
  intro: `The preparations were made, the security tightened and the challenge is now live!`,
  fullText: `
<p>
    I've been planning to include this for a very long time and I am very excited it is now ready to go live!
</p>
<p>
    I really hope I covered all the angles and as always, I have huge appreciation to the player community for providing their feedback about the rules for this challenge.
</p>
<p>
    There's so much more that can be said, but you can find all that information on the page dedicated to this challenge: <a href="#/hackTheGame">click here</a>.
</p>
`
};
