export default {
  title: `World building - progress update`,
  date: `2021-07-02`,
  intro: `Today I'd like to share with you updates regarding the world building, include a sneak peek and talk about some changes you can expect.`,
  fullText: `
<p>
  Quick note that everything shown here is still work-in-progress and as such - subject to change. 
</p>
<h2>The starting point</h2>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-07-02/outline.jpg">
    <img src="./images/2021-07-02/outline.jpg">
  </a>
  <span>World outline</span>
</span>
<p>
  I started building the world by making a very quick outline of the overall planned game area - drafting the world map, splitting it into zones and defining each zone's core feature. On the attached image you can see the map I created, stripped of all features & descriptions as to avoid spoiling too much at this point. The small circle of mountains in the middle - that's the starting zone, the focus of my world design work over the last few weeks.
</p>
<p>
  For anyone playing the pre-alpha and who explored the island available in that version of the game - the starting zone itself, that tiny little circle in the middle, it is over 10 times the area of the island we're playing on today.
</p>
<h2>Quality over quantity</h2>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-07-02/world1.jpg">
    <img src="./images/2021-07-02/world1.jpg">
  </a>
  <span>Craggy cliffs surrounding entrances to underground caves</span>
</span>
<p>
  Making a huge world is simple enough - the challenge is all about making each of the corners of that world interesting and significant. I want the starting area jam-packed with distinct locations and landmarks, many of them telling you a story about this world. All of the screenshots that you can see attached to this article are showing terrain features that are included in the starting zone alone. And that's only a part of it, there's more features in that starting zone for you to discover!
</p>
<p>
  And speaking of features, I'm cooking something special to be included in this very first zone. Not to spoil everything outright, but I can say it will include a mega-project that players can complete to reshape a major part of this land, uncovering some new content and secrets. 
</p>
<h2>Game tuning</h2>
<p>
  One major thing I should mention is changes to the tuning of the game. Right now in the pre-alpha it is relatively easy to accumulate significant bonuses to your base statistics making your character very powerful and efficient. That won't be the case once the game reaches this new release. You can expect that bonus as small as few points to your base statistics will be considered significant.
</p>
<span class="image-frame left" style="width: 40%;">
  <a target="_blank" href="./images/2021-07-02/world3.jpg">
    <img src="./images/2021-07-02/world3.jpg">
  </a>
  <span>A small, peaceful island on a lake</span>
</span>
<p>
  Another thing worth mentioning is that the initial game balance will tend to lean toward under-tuning. What this means is that when making balancing decisions I'll be looking to make the conservative choice and lean toward making bonuses smaller and costs higher. 
</p>
<p>
  I appreciate how at first glance this may sound unwelcome, highly undesirable even. But I believe I have good reasons to do it this way and that's because I want the first game server that goes live with this new realm to be persisted. 
</p>
<p>
  What this means is that any progress you make on that realm is there to stay. It means I want to commit to not wiping the progress from that point onwards, it means that at that point - you can treat Soulforged as a game released, in early access, if you will. 
</p>
<p>
  By keeping the tuning on the underpowered side it gives me more room to make adjustments on the go and keeping a larger margin for error. If I make the roads too cheap and very beneficial to begin with, it means the area can end up quickly being covered with roads completely and there's no going back from that, not really. By making the roads weaker I keep the option open to add better road later down the line or even simply buff the road effects. If the roads end up being too expensive, I have room to make them cheaper or provide other ways to make building them faster. 
</p>
<p>
  As with everything, this is a double-edged sword and I also need to be careful to avoid devaluing effort that players already put in the game. Still, in my experience it is easier to manage than nerfing aspects of the game.
</p>
<h2>Developing world</h2>
<p>
  Another challenge that I face as game developer is making the early zone work as a part of a world that is, effectively, incomplete. In this regard I want to implement an explicit communication policy. What this means is that the game will be very explicitly informing players that an area is not yet accessible.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-07-02/world2.jpg">
    <img src="./images/2021-07-02/world2.jpg">
  </a>
  <span>A cave entrance by the riverside</span>
</span>
<p>
  There are other ways I could go about this, like placing a very strong enemy in your way, making travel impossible without severely injuring oneself, placing a locked door without a key and others. Personally, I really detest all of the above solutions and the reason is simply it makes the game really annoying to play at times.
</p>
<p>
  The enemy that you face - are you not strong enough to defeat them just yet or were they designed to be impossible to defeat? If you can find a key to one door, how much time do you need to spend in search for a key to another door before you can assume it's not meant to be open? If a path is meant to be impossible to climb because it leads to unfinished area - what happens if you're determined enough to skill up and scale it anyway? How disappointing would it be to find out there's absolutely nothing beyond it?
</p>
<p>
  By having the game explicitly inform you that a given path cannot be travelled yet, you can have confidence that any challenge that is open to you leads somewhere, that there is something to discover or learn something new once you beat it.  
</p>
<h2>When can we play it?</h2>
<p>
  At this stage I make no promises, only estimations. There's still a very long road ahead of me, lots of bugs to squish, improvements to make and systems to implement. And a whole zone and world lore to design. That said, I continue to be optimistic about the initial estimation that places this alpha version somewhere around September this year, as I have previously included on the project's <a href="https://soulforged.net/#/roadmap" target="_blank">Roadmap</a>.
</p>
<h2>Closing thoughts</h2>
<p>
  I wanted to thank you wholeheartedly for reading this article and for taking part in this journey with me. I also want to include special thanks to my Patreons - your support is a huge deal for me!
</p>
<p>
  If anything in this article prompts any thoughts you'd like to share with me, you can always find me on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server, where I welcome discussion, questions and criticism.
</p>
`
};
