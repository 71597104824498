export default [
  {
    date: "04-2018",
    importance: 0,
    title: "Timeline start"
  },
  {
    date: "05-2018",
    importance: 1,
    title: "Journey begins",
    description: `Work begins on the first prototype. Known only as "G the game" at the time, I work on it as a hobby outside of my day job.`
  },
  {
    date: "07-2018",
    importance: 2,
    title: "First gameplay",
    description: `The prototype is played for the first time, by my family and friends.`
  },
  {
    date: "01-2019",
    importance: 1,
    title: "Work starts on Soulforged: Prelude",
    description: `The prototype is reworked into what is later known as Soulforged: Prelude.`
  },
  {
    date: "05-2019",
    importance: 3,
    title: "Soulforged: Prelude public launch",
    description: `Soulforged is shown to the world for the first time. Discord Server is created.`
  },
  {
    date: "05-2020",
    importance: 3,
    title: "Soulforged: Prelude shuts down",
    description: `Soulforged server is shut down with a send-off event. AMA event followed the day after.`
  },
  {
    date: "08-2020",
    importance: 1,
    title: "Soulforged rekindled",
    description:
      "The work begins on a complete rework of the game engine, still working on the game only as a hobby, alongside of my day job."
  },
  {
    date: "11-2020",
    importance: 1,
    title: "Full speed ahead",
    description:
      "Work on Soulforged is kicked into overdrive as I decided to move to game development full-time."
  },
  {
    date: "04-2021",
    importance: 3,
    title: "Early pre-alpha",
    description: "Early pre-alpha version is released to the public."
  },
  {
    date: "12-2021",
    importance: 1,
    title: "Pre-alpha closed down",
    description: "Early pre-alpha version server was shut down."
  },
  {
    date: "01-2022",
    importance: 3,
    estimate: true,
    title: "Early access released",
    description:
      "Estimate. Release an Early Access version which includes basis for all core systems and the starting region, including the tutorial."
  },
  {
    date: "03-2022",
    importance: 2,
    title: "Combat overhaul released",
    description:
      "A large update that overhauls how combat works, making it much more interactive."
  },
  {
    date: "05-2022",
    importance: 1,
    title: "Data-mining challenge",
    description:
      "A Data-mining Challenge was issued! The game is specifically coded to prevent accessing any information about unknown locations, items or monsters. Can anyone gain access to this information by other means than gameplay? I'm eager to see!"
  },
  {
    date: "06-2022",
    importance: 1,
    title: "Client made open-source",
    description:
      "The client code for Soulforged was made open-source, enabling members of the community to directly contribute and find out more how the game works!"
  },
  {
    date: "07-2022",
    importance: 1,
    title: "Mobile App release",
    description:
      "An app version of the game was released on both Google Play and AppStore."
  },
  {
    date: "08-2022",
    importance: 1,
    title: "Steam release",
    description:
      "A stand-alone client was released on Steam, bringing in tons of new players into the game!"
  },
  {
    date: "12-2022",
    importance: 3,
    title: "Soulforged financing is decided",
    description:
      "It was decided that starting April 2023 I will be going back to a more standard job and Soulforged will be moved to become a hobby for me again. Still, Soulforged will stay accessible and free for everyone!"
  },
  {
    date: "02-2023",
    importance: 3,
    estimate: true,
    title: "Chapter 2 goes live!",
    description:
      "Chapter 2 was released on 17th Feb 2023, adding a whole host of content and new systems to the game!"
  }
];
