export default {
  title: `A fair game`,
  date: `2021-01-29`,
  intro: `Today I'd like to talk about some events that transpired in the original Soulforged game.`,
  fullText: `
<p>
  Building a game that is fair is a very difficult thing. Building one that <em>feels</em> fair is incredibly more difficult.
</p>
<p>
  In this article I'll be going over the rivalry that developed between some players in the original Soulforged, highlight what it meant for the game fairness, share my perspective on those events and how they prompted me to make some adjustments going forward. Before I begin there is a small bulk of disclaimers I need to put forward.
</p>
<h3>Disclaimers</h3>
<p>
  Please note that the events described here took place a while ago and during that time there were a large number of conversations and events happening all the time. It's entirely possible I might be misremembering or forgetting parts of it. This is not intended to be a complete record of the events, as there are likely dozens of things I never even learned in the first place.
</p>
<p>
  This article here is to provide you with my perspective, as a game developer and gamemaster. At no point do I claim to know what players felt or thought at any time, I can only say what the situation seemed to me to be.
</p>
<h2>The setup</h2>
<p>
  Next I'd like to go over couple of things that may be familiar to long time players, but are important to highlight for the purpose of this article.  
</p>
<p>
  When the game was first made available to the public it was very far from being complete. As more and more players engaged with what was included in the game so far, I continued to work on adding a whole host of features, systems, contents.
</p>
<p>
  Some of the systems were somewhat underdeveloped and I'd often prop them up with direct intervention. To give a good example of that I can bring up the climate system. There were several biomes that player characters could live in - hot/moderate/cold/wet, etc. These biomes were decided based on the presence of powerful dragons, different types of dragons spreading an aura transforming the land. This could be used to transition the biomes occasionally, by moving the dragons around, causing winters, hot summers or dry seasons, but these movements were not fully automated and so I'd end up ordering dragons to move around using my gamemaster controls.
</p>
<p>
  Another integral part of the game were the so called "Entities of Great Power" - non-playing characters that were controlled by me that'd issue some quests and tasks to the players willing to engage with them. First time the players would encounter one of them was during the scripted tutorial where they could have very brief, automated conversation with that character. Later on, they could find this character listed and available to message them on the Discord server. By interacting with these entities players could unlock access to new races or even get a chance to transform the world itself.
</p>
<h2>Player expansion</h2>
<p>
  As the playerbase grew larger and characters grew in power, more and more land became accessible to the players and they'd start to spread around the world. As the starting location was seemingly limited by the sea to the east, players started exploring south, west and north.
</p>
<p>
  Southern expansion was fairly quickly brought to a halt, due to harsh winter conditions, limited unique resources and limited prospects for further exploration. The main obstacle in settling in the north were the orcs and an Entity of Great Power known as Chur. The main obstacle going west was the swamp, which was difficult to cross and riddled with creatures too dangerous for inexperienced characters.
</p>
<p>
  As the game progressed, players made a considerable dent in the orc forces to the north and well as developed a road to help cross the marshes to the west. As you can imagine, this quickly led to two somewhat distinct communities, one living to the north, the other to the west. 
</p>
<p>
  But there's more to this split. Not helping the matter was the fact that playerbase was divided over their attitudes toward orcs. Some players, mostly those who later chose to settle in the west, believed Chur when they spoke of peace between players and the orcs and were willing to accept the concessions that Chur demanded in order to keep the peace. Other players were distrustful of Chur and expected a betrayal, feeling uneasy living this close to orcs or simply found orcs inferior and considered abandoning the lands the orcs wanted to be wasteful. For one reason or another a large group of players banded together and engaged in a war effort to dominate the orcs. In the meantime, players who opted for peace would often prefer to live far from the frontlines of this conflict, prompting them to go west more than anything before.
</p>
<h2>Gamemaster perspective</h2>
<p>
  I should highlight that at this point, I was perfectly happy with all of the approaches players were taking. I can understand why they'd distrust Chur, even if the character was written and committed to be completely straightforward and never tell a lie. I can understand why players would opt to move out of the way of the conflict, even if that meant threatening the starting area for all future characters. The war with orcs wasn't planned as an important plot point, nor was it expected to be avoided. It was entirely left in players hands to manage that and decide for themselves.
</p>
<p>
  Nevertheless this split created some problems for me, as a game designer and gamemaster. As I mentioned the game would still be considered incomplete, with some of the resources or whole biomes remaining underdeveloped. With now two factions, occupying two distinct parts of the world, I had to consider that every change I make would likely be more popular or unpopular with part of the playerbase. It was important for me to keep the game open-ended and avoid systematically supporting one group over the other, but it was another added difficulty on top of all the other things I would struggle with already.
</p>
<p>
  I should also point out that during this time I also had a character I was playing with that was known to the playerbase, it was no secret which character that was. To get a better feel of what it was like to live in a more remote location I decided to move west, as I didn't want my character to interact with orcs in any way, and potentially influence the outcome. Then again, as much as I'd like to think this was the more neutral approach, it was in the end similar to the "pacifist" way and so I expect I was inevitably painted in some players mind as a supporter of that option.
</p>
<p>
  All of this meant that any change I would make to the game balance or any event that I would trigger - I wanted to very carefully consider how it'd impact different groups as I didn't want players to feel like some of them were favoured or worse yet that some players were being "punished". This actually meant that on some occasions I'd hold off a balance change or a feature addition until I had something prepared that'd work for the other group. 
</p>
<p>
  This also meant that I altogether stopped moving the dragons around - as again, whichever way the pendulum swings I felt that some players would consider any such action biased in some way.
</p>
<p>
  Further to that it posed massively increased challenge of roleplaying the Entities of Great Power. The players in the north would be dealing with Chur, who provided very few boons and struggled to keep the orc hordes any challenge for the players, sometimes employing cheap tricks to help maintain the war effort. The players in the west made contact with Yarlo, who at first glance was a more sympathetic character, yet his inherent distrust meant that players had sometimes difficult time to get to any sort of agreement. 
</p>
<p>
  Throughout those conversations I tried to stay unbiased and neutral toward players, making any judgement that those non-playing characters would apply to players to be based solely on their direct interactions and messages and the in-game actions. That means, disregarding any and all behaviours and chats the players would engage with each other while the Entity was not present. At the end of the day - I'm only human and I am sure, however much it'd pain me, that I let some bias in on occasion.
</p>
<p>
  Nothing cautioned me more and re-affirmed me with my concerns about being perceived as biased as learning that some of the players created a separate, invitation-only Discord server. I had no problem with players banding together, nor have I had any issue that they started a Discord server separate from the rest of the player base. But I took that a signal that for some players, the divide was real and very tangible and it cautioned me to be even more careful about the changes I was making.
</p>
<h2>Closing thought</h2>
<p>
  At the end of the day what I wanted was to create an immersive world where player choice matters and I like to think I did succeed in that regard - the choices players made felt important to them, the outcomes tangible and desirable. I don't think I've been accused of biased behaviour as often as I expected to be and I'm happy to wear that as a badge of honor to all the effort I made to not make biased decisions. I'm happy that the world I created was evocative enough to create ground for such rivalry (even if sometimes it was getting a bit too heated) and I can only wish that players felt that challenges posed in front of them were fair.
</p>
<p>
  When I think about all this and what it means for Soulforged - I'm happy to be able to take the time to deliver a more complete game from the get go. I expect this to alleviate some of the pain of balancing not just the game but also the bias. And as much as I love the concept of Entities of Great Power and the interactions we had, I think it poses a great risk of ruining the feeling of fairness, so I'm looking to explore different systems, more automated but hopefully just as engaging. 
</p> 
<p>
  Thank you for reading through this article, I hope it provided you with some interesting insight on what my thinking was while managing the first Soulforged game. Any particular pieces I missed out? Anything you'd like to share or add? Please join us on the <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server for further discussion!
</p>
`
};
