<template>
  <div id="main">
    <div id="header">
      <div id="logo">
        <div id="logo_text">
          <h1><a href="index.html">Soulforged</a></h1>
          <h2>Persistent Multiplayer Online Role-playing Game</h2>
        </div>
        <div id="contact_details">
          <a
            href="https://play.soulforged.net/"
            target="_blank"
            class="action-button"
          >
            Play the Game!
          </a>
          <!--          <a
            href="https://patreon.com/ethnar"
            target="_blank"
            class="action-button patreon"
          >
            Support via Patreon
          </a>
          <a
            href="https://www.paypal.com/donate?hosted_button_id=8YXKJUZKP3RHE"
            target="_blank"
            class="action-button paypal"
          >
            Donate via Paypal
          </a>-->
        </div>
      </div>
      <div id="menubar">
        <ul id="menu">
          <li
            v-for="(section, sectionId) in sections"
            :class="{ selected: currentPage === sectionId }"
            :key="sectionId"
            v-show="!section.noTopLink"
          >
            <a :href="'#/' + sectionId">{{ section.label }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div id="site_content">
      <div class="sidebar" v-if="includeSidebar">
        <!--        <iframe-->
        <!--          src="https://discord.com/widget?id=556169801589063682&theme=dark"-->
        <!--          width="190"-->
        <!--          height="200"-->
        <!--          allowtransparency="true"-->
        <!--          frameborder="0"-->
        <!--          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"-->
        <!--        />-->
        <br />
        <div class="sidebar-header">Devlog</div>
        <div v-for="(news, idx) in shortListNews" :key="idx">
          <h4>{{ news.title }}</h4>
          <h5>{{ formatDate(news.date) }}</h5>
          <p>
            <span v-html="news.intro"></span>
            <br />
            <a
              v-if="news.fullText"
              :href="'#/' + NEWS_PATH + '/' + news.newsId"
            >
              Read more
            </a>
          </p>
        </div>
      </div>
      <div class="content" v-if="listNews">
        <h1>Devlog</h1>
        <div v-for="(news, idx) in listNews" :key="idx">
          <h4>{{ news.title }}</h4>
          <h5>{{ formatDate(news.date) }}</h5>
          <p>
            <span v-html="news.intro"></span>
            <br />
            <a
              v-if="news.fullText"
              :href="'#/' + NEWS_PATH + '/' + news.newsId"
            >
              Read more
            </a>
          </p>
        </div>
      </div>
      <div class="content" v-if="newsPiece">
        <h2>{{ newsPiece.title }}</h2>
        <h5>{{ formatDate(newsPiece.date) }}</h5>
        <p class="intro" v-html="newsPiece.intro"></p>
        <component :is="newsPiece.component"></component>
      </div>
      <div class="content" v-if="listReviews">
        <h1>Reviews & Impressions</h1>
        <div v-for="(review, idx) in listReviews" :key="idx">
          <div v-if="idx > 0" class="ornament separator"></div>
          <p class="review">
            <span v-html="review.text"></span>
          </p>
          <div class="author">
            {{ review.author
            }}<span class="author-title" v-if="review.authorTitle"
              >,
              <span class="author-title-text">{{
                review.authorTitle
              }}</span></span
            >
          </div>
        </div>
      </div>
      <Screenshots v-else-if="currentPage === SCREENSHOTS_PATHS" />
      <component
        v-else
        class="content"
        :class="{ 'no-sidebar': !includeSidebar }"
        :is="contentsComponent"
      ></component>
    </div>
    <div id="footer">
      Copyright &copy; simplestyle_2 |
      <a href="http://validator.w3.org/check?uri=referer">HTML5</a> |
      <a href="http://jigsaw.w3.org/css-validator/check/referer">CSS</a> |
      <a href="http://www.html5webtemplates.co.uk"
        >design from HTML5webtemplates.co.uk</a
      >
    </div>
  </div>
</template>

<script>
import sections from "./script/sections.js";
import RoadmapComponent from "./components/Roadmap.vue";
import Screenshots from "./components/Screenshots.vue";
import Vue from "vue";

// utility
Array.prototype.toObject = function(keyGetter, valueGetter = i => i) {
  const object = {};
  this.forEach((i, idx) => {
    object[keyGetter(i, idx)] = valueGetter(i);
  });
  return object;
};

// simple xhr request
const xhr = url => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.onload = function() {
      resolve(this.responseText);
    };
    request.onerror = reject;
    request.open("get", url, true);
    request.send();
  });
};

// fetching html of a selected page

const cache = {};
const getStaticHtmlComponent = name => {
  name = name.split("/").shift();
  if (!cache[name]) {
    const componentName = `dynamic-${name}`;
    cache[name] = Promise.all([xhr(`content/${name}.html`), getStats()])
      .then(([content, stats]) => {
        Vue.component(componentName, {
          data: () => ({
            stats: stats
          }),
          render: function(createElement) {
            return createElement("div", { domProps: { innerHTML: content } });
          }
        });
      })
      .then(() => componentName);
  }
  return cache[name];
};

const statsPromise = Promise.resolve({});

function getStats() {
  return statsPromise.catch(() => "{}");
}

export default {
  data: () => ({
    NEWS_PATH: global.NEWS_PATH,
    SCREENSHOTS_PATHS: global.SCREENSHOTS_PATHS,
    sections,
    currentPage: null,
    contentsComponent: "",
    newsPiece: null,
    listNews: null,
    listReviews: null,
    shortListNews: window.news.slice(0, 3),
    stats: null,
    includeSidebar: true
  }),

  components: {
    Screenshots
  },

  watch: {
    "$route.path": {
      handler: function(newPath) {
        this.newsPiece = null;
        this.listNews = null;
        this.listReviews = null;
        this.contentsComponent = null;
        this.currentPage = null;
        this.includeSidebar = true;

        const name = (newPath || "").replace("/", "");
        let promise;
        if (name.indexOf(global.ROADMAP_PATH) === 0) {
          Vue.component("Roadmap", RoadmapComponent);
          this.currentPage = global.ROADMAP_PATH;
          this.contentsComponent = "Roadmap";
          this.includeSidebar = false;
        } else if (name.indexOf(global.NEWS_PATH) === 0) {
          const newsId = name.replace(global.NEWS_PATH + "/", "");
          if (newsId === global.NEWS_PATH) {
            this.currentPage = global.NEWS_PATH;
            this.listNews = window.news;
            return;
          }
          const originalNews = window.news.find(n => +n.newsId === +newsId);
          promise = getStats().then(stats => {
            this.newsPiece = {
              ...originalNews,
              component: {
                data: () => ({
                  stats
                }),
                render: function(createElement) {
                  return createElement("div", {
                    domProps: { innerHTML: originalNews.fullText }
                  });
                }
              }
            };
          });
        } else if (name.indexOf(global.REVIEWS_PATH) === 0) {
          this.currentPage = global.REVIEWS_PATH;
          this.listReviews = window.reviews;
          return;
        } else if (name.indexOf(global.SCREENSHOTS_PATHS) === 0) {
          this.currentPage = global.SCREENSHOTS_PATHS;
          return;
        } else {
          this.currentPage = name;
          promise = getStaticHtmlComponent(name, this).then(componentName => {
            this.contentsComponent = componentName;
          });
        }
        if (this.$route && this.$route.params && this.$route.params.anchor) {
          promise.then(() => {
            this.interval = setInterval(() => {
              const el = document.getElementById(this.$route.params.anchor);
              if (el) {
                el.scrollIntoView();
                clearInterval(this.interval);
              }
            }, 5);
          });
        }
      },
      immediate: true
    }
  },

  methods: {
    formatDate(date) {
      return date;
    }
  },

  mounted() {
    getStats().then(data => {
      this.stats = data;
    });
  }
};
</script>

<style lang="css">
@import "./style/style.css";
@import "./style/custom.css";

.content.no-sidebar {
  width: auto;
}

.review {
  white-space: pre-wrap;
  font-style: italic;
  line-height: 20px;
}
.author {
  text-align: right;
  font-size: 110%;
  color: #a4aa04;
  font-style: italic;
  font-weight: bold;
}
.author-title-text {
  font-size: 80%;
  color: #676b00;
  font-weight: normal;
}
</style>
