export default {
  title: `Combat systems`,
  date: `2021-01-15`,
  intro: `Today I want to delve into what makes a combat system really great. Well, at least what <i>I think</i> makes one great.`,
  fullText: `
<p>
  The idea behind Soulforged is to create an expansive, deep and living fantasy world for players to immerse themselves into. There's a whole lot of systems to be put together to create that experience and only one of those systems is the combat. I like to think of Soulforged as a game that is not combat-centric, but at the same time I believe that a good and deep combat system would compliment the game very well.
</p>
<p>
  This article is less about the specifics of combat system in Soulforged and more about the different factors I take into account when working to design one.
</p>
<h2>Player choice</h2>
<p>
  Virtually all game systems hinge on the choices they present to the players. The general idea is that player is provided with the information and the choice options, they make a decision and then the game provides them with the results and usually a follow-up set of choices. 
</p>
<p>
  In massive role-playing games like
<a href="https://store.steampowered.com/app/228280/Baldurs_Gate_Enhanced_Edition/" target="_blank">Baldur's Gate</a> or 
<a href="https://store.steampowered.com/app/373420/Divinity_Original_Sin__Enhanced_Edition/" target="_blank">Divinity Original Sin</a> there's plethora of choices to be made. From your character's race, gender, class, equipment, journey destination to dialogue options and which quests you tackle to how you resolve them, there's a choice for the player to be made every step of the way.  
</p>
<p>
  <span class="image-frame right" style="width: 40%;">
    <a target="_blank" href="./images/2021-01-15/arkanoid_preview.gif">
      <img src="./images/2021-01-15/arkanoid_preview.gif">
    </a>
    <span>Arkanoid clone. Copyright by noobtuts.com</span>
  </span>
  In smaller games the choices may be less obvious. In the classic <a href="https://en.wikipedia.org/wiki/Breakout_(video_game)" target="_blank">Breakout</a>/<a href="https://en.wikipedia.org/wiki/Arkanoid" target="_blank">Arkanoid</a> title it may seem at first that the only objective for the player is to make sure the ball bounces off the paddle instead of falling down the pit. But for anyone who played the game to some extent, they will realize that you can actually control where the ball will go depending on which part of the paddle the ball hits. Without that mechanic the game would often lead to frustration as player would just hope the ball eventually hits the last few blocks remaining in the game, having no control over where it goes. With that mechanic skilled players will leverage it further to break a path through the initial shape and land the ball on top of the remaining blocks, clearing large parts of the level very quickly. And just by that mechanic alone player is put in control and will end up making a choice every time the ball is approaching the paddle.
</p>
<p class="small">
  Sidenote: It could be claimed that it might be possible to create an engaging game that is purely a game of luck or a game of skill (that is - without the element of "choice"), but I'd argue that even in those cases the core element to these experiences will be player choice, only that those choices would not be as apparent.
</p>
<p>
  Thinking what this means for Soulforged and its combat system - I tend to look at these choices broadly under two categories: preparation and execution.
</p>
<h3>Preparation</h3>
<p>
  Preparation encompasses everything that the player would do before the actual fight starts. This is still a very broad category and it includes a whole host of choices, many of which may not be perceived as combat-related. These would include: 
</p>
<ul>
  <li>Obtaining the equipment, like armours, weapons and consumables</li>
  <li>Picking which equipment to carry with you at the time</li>
  <li>Training up your relevant combat skills and increasing related statistics</li>
  <li>Measuring your character's fit for combat, consider their wounds and other temporary effects</li>
  <li>Deciding which path to travel across or which location to visit</li>
  <li>Teaming up with other players</li>
  <li>And finally - whether you can take the risk and pay the cost of a particular engagement that you see in front of you</li>
</ul>
<p>
  This list is not meant to be exhaustive, but just outline the many choices player can make that will influence or flat out determine the outcome of a combat encounter.
</p>
<p>
  If you have been playing Soulforged 1, I imagine most of these choices you're familiar with, whether you were fully aware you're making them or if some were done more on subconscious level. In Soulforged 1 - that was basically all the choices you had that you could use to influence the outcome of a combat encounter. Once you decided to engage in an encounter, there was very little choice left and for the most part - you were now a passive observer waiting for the outcome. Which gets us to what could have been instead and the second category...
</p>
<h3>Execution</h3>
<p>
  Execution is all about the choices that players can make while the encounter is ongoing. Looking back at Soulforged 1 - there were very few things you could choose during that time. You could swap your weapons, you could use some consumables and you could decide to flee. The choice was so drastically stripped down that you couldn't even decide which particular enemy you want to hit, assuming there were more than one target to choose from.
</p>
<p>
  But the kicker is - that's exactly how the game was designed to be. It's no accident that players couldn't influence the combat more - and that's because of the real-time nature of the game. What it meant is that encounter may start at any point. A creature may wander into your location and start the fight while you're busy or asleep. And yes, there was a mechanic that helped players control when exactly an encounter would be resolved, but it was still limited to just a few hours. That is why the combat was designed to be mostly determined by the preparations.
</p>
<p>
  I was always keen to explore having a more active combat system in the game, but I was acutely aware of the tradeoff it'd cause - punishing players for not being available to play the game nearly 24/7. If your actions during an encounter would usually result in receiving fewer wounds - any time an encounter happens without your input, it would feel like you're being punished for not playing. Worse yet - even with the precautions I took, that could still very well happen in the first iteration of the game, where player character would die to a fight they would be able to handle fine if only they took some painkillers at the start of it.
</p>
<p>
  But all of that is now being changed, with how the time progression happens in this new system. As you might have read in the <a href="/#/updates/1">article</a> on the current plans for Soulforged 2 - the time flow will work somewhat differently than usual and player capacity for action will be determined with Action Points. This enables me to create a game where a combat encounter would only ever start because of what player is doing. There's a lot of moving parts and design decisions that need to be made for this to actually happen, but this means that in Soulforged 2 I can actually build a combat system where execution is an active process.
</p>
<p>
  I have yet to decide what this will mean exactly, but to give you a small glimpse - you can picture combat playing more like a card game (think <a target="_blank" href="https://store.steampowered.com/app/646570/Slay_the_Spire/">Slay the Spire</a>) or a JRPG - you select an offensive or defensive move, select target and it gets executed. Enemies do the same, statuses of the combatants are updated and you are then presented with another choice. The actions you could perform would likely be tied to your character development and their skills, meaning more tie-ins with the "Preparation" and more choices there as well.
</p>
<p>
  The moves could include things like a weaker attack, but one that exposes enemy weakness, increasing damage taken going forward. Or another attack that has a lower chance to hit the enemy, but also makes you harder to hit. Depending on the equipment you use, your skills and the enemy you face - some attacks will be better than others and therefore some strategies will be better than others.
</p>
<p>
  One of the main reasons why I think the game would benefit from a combat system like that it provides new ways to approach both new and familiar foes. You may opt out to dispose of a basic threat with one massive, but otherwise risky move. At the same time - when facing a completely new and unknown enemy, you can utilize your defensive moves to get a better feel of just how dangerous they are and possibly get away before things start to get too serious.
</p>
<p>
  Another reason is the depth this could add, particularly about synergies between 2 or more characters - so that cooperation is further rewarded.
</p>
<h3>Player choice - summed up</h3>
<p>
   Whether through preparation or execution, player choice is what drives the whole experience and for me that's always first on my mind when designing a system like this. With any new weapon type, new move, new mechanic or a new enemy type I will ask myself "How does that affect player choices? Do the choices that are included so far stay meaningful with this new change?" 
</p>
<h2>Player power and balance</h2>
<p>
  Next on the list of things to consider - power and balance. There are usually three avenues which enable characters to tackle more difficult foes:
</p>
<ul>
  <li>Improving character skills - unlocking new abilities, increasing chance to land a blow or damage dealt</li>
  <li>Improving character equipment - new equipment may provide damage prevention or improve damage dealt; often the equipment may have some requirements before it can be used</li>
  <li>Improving player skill - as player gets more familiar with the mechanics and the available options, they can make better decisions and utilize their character and equipment better</li>
</ul>
<p>
  The player skill is inherently connected to the topic of player choices and I've covered that in in the previous section. 
</p>
<p>
  The more intriguing aspect here is the character skills & equipment. In many games these are strictly interconnected - progressing and improving at similar pace, almost indistinguishable. Usually, there's hardly any good reason to keep these apart, as characters are in some way immortal (save system or respawn) and they rarely part with their equipment (aside from small sub-plots). 
</p>
<p>
  In Soulforged I want characters to feel like they achieve something, rather than inevitably move forward, however fast or slow, toward a maximum power level. The way I've done this in the past and intend to continue on is through permadeath - once dead the character is done for, removed, all their skills lost. All that's left behind is what they made or what the player learned. Through permadeath mechanic, we very naturally split what the character power is compared to the equipment power. All that's left to decide is how much power comes from the skills and how much from equipment - and that is trickier question than I initially thought.
</p>
<p>
 If the power is coming primarily from the character skills - that means death becomes a massive setback for the player, effectively cutting off access to some areas player could reach earlier.
</p>
<p>
  On the other hand, if the power comes primarily from the equipment - that means any new starting player can, provided they get their hands on the right equipment, access virtually any area regardless of the combat threats present there.
</p>
<p>
  Neither of these scenarios sound good to me, personally. I want players to feel a sense of achievement based on what their characters can handle. At the same time I want players who unlocked access to a more difficult region - to retain most of that access, even when they spawn on a new character.
</p>
<p>
  One way I see this problem could be addressed is that the equipment is what enables players to hang around in the open spaces of a region (harder regions requiring better gear), while skills are required to handle the creatures that are tucked away in more dangerous parts of the region (harder regions still requiring better gear). This could be achieved by having weapon damage offset by armour - meaning tougher creatures require better weapons, while the skills would be used to determine hit/dodge chance. Creatures in open spaces would require very little skill to hit them, but still require good enough gear to survive and beat them. The creatures tucked away in the corners of the region - they would still require similar gear, but without the skill - you'd end up getting hit a whole lot, while landing very few blows yourself. Another outstanding challenge about this approach is how to make sure players can tell the difference, but even there a simple solution might just do the trick - like making the basic monsters animals and the skill-based ones humanoid.
</p>
<p>
  This leads to another couple of questions that I must ask myself. How is damage expressed, hit chance, dodge? How can players understand what those values mean? Using percent for hit/dodge might sound very tempting - it'd be easy for players to understand. But it adds more problems than it solves - like the ceiling and the need for diminishing returns. Adding 5% dodge gives you very little damage mitigation when your starting dodge was 0%, but it halves the damage you receive if your starting value was 90%.
</p>
<p>
  And to make things even more complicated - a combat system like this would likely also benefit from strength/weakness dynamic - where for instance certain armours are only really good at protecting from certain types of attack or certain moves. But again, nothing comes free and there are massive considerations to make when designing system like this - most notably on my mind is the player perception and making sure players don't feel coerced to perform some very arbitrary and strange actions. It could be something as innocent as rendering some consumables to be perceived as mandatory or players feeling the need to carry 3 different sets of armour everywhere they go.
</p>
<p>
  Creating a balanced combat system is difficult and I feel that's one of the areas where I will still be learning a lot.
</p>
<h2>Rewards and setbacks</h2>
<p>
  Next in line when designing a combat system is the matter of a reward and a setback.
</p><p>
   A reward is anything that prompts the player to engage and hope to defeat a foe. This could be the materials that can be harvested off of them or access to a location - which may in turn provide access to some unique resource or a shortcut between two regions.
</p>
<p>
  A setback in this sense would be anything that happens to a player that overplayed their cards and took on a more difficult encounter that they can handle. One extreme case is, of course, character death. This would often be the case in the original Soulforged 1, but this time I'd like to explore some other options - instead being killed, the character may be knocked out and become afflicted with a permanent wound. 
</p>
<p>
  While rewards and setbacks may seem only remotely related to combat design, both are strictly intertwined with it, even if it's due to very different reasons.
</p>
<p>
  For setbacks it's important that they are proportional to the risk taken. For instance - if your character can safely dispatch a wolf with barely any scratch, it'd be really hard to accept getting flat out killed when attacked by three wolves at the same time. Whether that happens or not is really down the combat design - in many RPG systems having numbers advantage is absolutely massive, even when there are no specific bonuses involved. This is because of how combat works in systems where each creature attacks a fixed number of times in a given time - having numbers advantage really quickly adds up. That can be offset using for example a counter-attack system (each attack triggers both parties to exchange blows), keeping the damage distribution in check. 
</p>
<p>
  On the reward side you want to keep the rewards proportional to what kind of threat the creature poses, of course. But there's another, completely different and I'd argue much more important aspect to consider and that is enemy trivialization. As your character grows in power, they can more and more easily dispatch enemies that are much weaker than them. If the game has nothing to discourage them from doing so, the most profitable way for them is likely to continue collecting the rewards from the most difficult monster that they themselves find trivial. While there's nothing inherently wrong with that, it does make for a not very engaging gameplay. The idea of growing player power is better realized when they push their upper limits and uncover new places they can access, rather than just going up a tier of trivial monsters they are farming. This is a very multi-faceted problem and it's not just combat that is affected by it, but the combat balance will heavily play into how viable farming trivial monsters becomes.
</p>
<h2>Closing thoughts</h2>
<p>
  There's much more I'd like to say about how combat system ties to all the other aspects of the game, from character statistics and equipment to accidents and wounds. I'd love to talk more about the non-violent options I plan to include to resolve a potential combat encounters. But I feel like this is a quite lengthy article already. I expected that'd be the case even before I started writing it and that prompted me to ask myself - does the combat system really deserve so much consideration? Wouldn't this time be better spent on other systems?
</p>
<p>
  I feel I am pretty aware why violence is often taken as the answer in game design (recommend watching <a href="https://www.youtube.com/watch?v=cYnylXvk65s" target="_blank">Why We're Wrong About Violence In Games</a> by Adam Millard) and I suppose growing up with the works like <a href="https://en.wikipedia.org/wiki/The_Lord_of_the_Rings" target="_blank">Lords of the Rings</a> or <a href="https://en.wikipedia.org/wiki/The_Legend_of_Drizzt" target="_blank">Drizzt series</a> probably made me more biased to lean on combat. But I can still appreciate the truly harrowing stories of <a href="https://www.frostpunkgame.com/" target="_blank">Frostpunk</a> where combat is basically absent and yet the struggle is real.
</p>
<p>
  What do you think? Is combat essential to the world of Soulforged? Do you think the game requires a deep combat system or would it do better with a simplified one? Are there things I'm missing or might be overlooking? Even more than any other article before, this is an invitation to a conversation. I'd love to hear your thoughts on this on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>!
</p>
`
};
