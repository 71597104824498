export default {
  title: `The Research system`,
  date: `2021-05-07`,
  intro: `Today we dive into the "Research" system.`,
  fullText: `
<p>
  In this article I'll be going over what the Research system was in Soulforged Prelude and how it panned out.
</p>
<h2>Soulforged: Prelude</h2>
<p>
  Research was central to unlocking all crafts and buildings in the game. Players started knowing no crafting recipes and not able to construct any buildings. As they collected resources they could spend them on research. Each research required that player provide one or more items and if the game recognized that specific assortment of items as a valid recipe they'd unlock it. Resources used in this way were consumed.
</p>
<span class="image-frame right" style="width: 40%;">
  <a target="_blank" href="./images/2021-05-07/research-process.png">
    <img src="./images/2021-05-07/research-process.png">
  </a>
  <span>Sample output of a short research</span>
</span>
<p>
  For instance - to research stone hammer a player would need to select a stone, a twig and bark rope and perform the research. The materials would be used and as a result the player would now be able to craft a stone hammer.
</p>
<p>
  The system provided additional feedback to make what would be pure guesswork into a somewhat deterministic process. First - if the player provided some material matching a recipe, but not all of them - the game would inform them that something was missing. If they provided all the required materials and some extras - the feedback would be there were surplus items used. Researching using an item that led to no further discoveries would mark that item as a "dead end" and the player would then retain the information that said item won't be used in any of the remaining researches. And finally to help manage player expectations - when some items were missing the feedback would be divided into two categories: whether the player has seen all of the items required or not.
</p>
<p>
   In reality the system was even more involved, as players had to select tools and the result depended on their skills, but that's not really pivotal to the system as a whole.
</p>
<span class="image-frame right" style="width: 60%;">
  <a target="_blank" href="./images/2021-05-07/difficult-research.png">
    <img src="./images/2021-05-07/difficult-research.png">
  </a>
  <span>On of the hardest items to discover in Soulforged: Prelude</span>
</span>
<p>
  To put it in some perspective the game had over 400 recipes that could be researched. Some were as simple as using a piece of meat to discover a cooked version of it, while the most complex ones would require 8 relatively high-level materials that were also crafted.
</p>
<p>
  I should also mention that this system evolved quite a lot during the time Soulforged Prelude was playable, so please note that the above is only describing how the system worked in its final version. 
</p>
<h2>The takeaways</h2>
<p>
  I think this system had some really good points, but also some really bad ones. Most of them was actually both good and bad at the same time. Let me go over some of my takeaways and explain what I mean.
</p>
<h3>The feel of discovery</h3>
<p>
  Because nothing was really provided upfront, all you knew about the game was what you found so far or what other players found and shared with you. In other games where there's a list of researchable technologies everything is disclosed upfront. Rather than travelling the map and finding a new enemy or a new building that someone else built - you could just open the list of technologies, stumble upon a recipe that requires, say, ivory and you can then just assume that elephants are part of that world. By having all of the recipes in Soulforged undiscovered by default leaves a lot of room for exciting moments and breakthroughs to be made.
</p>
<p>
  The downside of that approach is that it really heavily undersells the game. If you started playing early or you avoided all the spoilers - it was really impossible to know how big the game was, how many things were included and whether the content was there to make the game feel worthwhile in the long run.
</p>
<p>
  It also creates this unrewarding experience for <a href="https://en.wiktionary.org/wiki/completionism" target="_blank">completionists</a>. You could never actually know for a fact that you discovered everything there is in the game - they could always be some new creature or new resource you haven't found yet that could yield more recipes.
</p>
<h3>Powerful items</h3>
<p>
  Because of how the system worked there was quite a lot of room for me to design recipes that are really difficult to discover, costing rare resources and making players who found them getting the recognition. It technically had the potential to have recipes that could be left undiscovered for months or years, even enabling a game-changing mechanics and possibilities.
</p>
<p>
  This could be all nice and exciting, but in practice it also risks missing some essential piece of technology without which some aspects of the game feel near-impossible to engage or perhaps straight out broken. Example of such technology was the windmill - before the players discovered it all they had was mortar and pestle and recipes using that tool would be very slow and considered not worth the time.
</p>
<p> 
  Having some technologies hidden away also risks alienating the players who spent a long time playing the game who only later discover that the game includes elements they are really not fond of.
</p>
<h3>Parallels to real world</h3>
<p>
  One of the things I wanted to capture with the research system is a parallel with our real world. I feel like very often people will, in pursuit of efficiency and given the overflow of information, be discouraged from researching something themselves. Why would you waste perfectly good ingredients trying to cook something completely new if there are so many recipes to find and follow online? What I tried to capture was that no real breakthrough happens without a cost, without some sacrifice.
</p>
<p>
  And I must say it played out even better than expected. If by better I mean players felt the same uncomfortable feeling many people feel in real life, making the research feel more punishing than rewarding. So overall - not a great feeling. The players expressed the regret over using up materials a fair number of times and I know some players preferred not to engage with this system at all.
</p>
<h3>Fair difficulty</h3>
<p>
  When thinking about perfect game difficulty, a lot of people immediately will think of an old adage:
  <q>Easy to learn and hard to master</q>
  I feel the research system was pretty close to it, but I guess we need to rearrange some words and change others to make it a bit more accurate. It'd then go more like this:
  <q>Hard to learn, harder to master, but trivialized once mastered</q>
  Ok, so it is a bit different in the end I guess. 
</p>
<p>
  The system was pretty hard to start with for a number of players who shared it felt that it's all guesswork and it's about figuring out what I had in mind designing each recipe. I can completely understand where that was coming from and I know it's not a good feeling.
</p>
<p>
  Things were not really getting any easier in the middle of the learning curve of this system. You'd require expensive materials and you'd want to find ways to minimize wasting them, so you'd be narrowing down the recipes using the cheaper materials. Soon you'd probably find yourself making a spreadsheet and marking what works with what and helping you figure out what to do next.
</p>
<p>
  And it's at this point we arrive to the level where using this clever approach, coupled with some scripts and spreadsheets - you could easily create a way to find out all the recipes, subject to availability of materials, and minimize the amount of materials wasted. You could then couple that with the distinction between recipes requiring "new items" and those that you've seen all of the materials for. Then by getting some help from your friends or using multiple accounts suddenly the whole ordeal turns into a trivial exercise in finding out the most complex of recipes, even those that were using the most expensive materials without actually wasting any of them. 
</p>
<p>
  All in all, suffice to say I'm really unhappy with the difficulty curve of that system.
</p>
<h3>The quirks</h3>
<p>
  And finally there's the problem of agency. With no structure and no hint on what you're building toward, players would sometimes find completely unexpected results. And it would not necessarily feel like a cause for celebration. This would happen as many items had multiple uses, some of which were very diverse. 
</p>
<p>
  Infamously there was a research that led to a "Bat mawkin", which was sort of a scarecrow that'd discourage bats from travelling to specific location. This research used a fairly popular items that included the wooden pole, bone and leather rope. And many a times players researching something involving one of those items would be throw off course with feedback that was actually related to the mawkin recipe. With this particular recipe being quite unusual it was left undiscovered for a little while and all throughout that time causing players to be thrown off track.  
</p>
<h2>The race</h2>
<p>
  As players engaged with Soulforged Prelude I was still actively developing the game and adding more content. Initially I kept things well ahead - a large number of recipes to be discovered with very few items identified as "dead end" by the players. But as the playerbase grew larger and some more determined players engaged with the system it was harder and harder for me to keep up with adding new content in time before players go to the point where they researched everything there was in the game already.
</p>
<p>
  Eventually, I was completely outpaced. I recall pushing a large update, adding couple dozen of recipes, including new player housing options. I am absolutely delighted with how players engaged with the game and I completely respect that all that the players really did was engage with systems I created. But at the same time my heart sunk a little when most of the recipes I added were discovered in just a few days. I knew I am falling behind and I knew that I can't really outpace that.
</p>
<p>
  I still feel this was an amazing lesson and I'm really eager to finalize the design of the research system for the upcoming version of the game. 
</p>
<h2>Closing up</h2>
<p>
  Thank you for reading! I hope you found this article interesting. If you'd like to ask questions, reminisce of the good old times or perhaps you have some suggestions - why not head over to our <a target="_blank" href="https://discord.gg/XExbewT5GQ">Discord</a> server and have a chat?
</p>
`
};
