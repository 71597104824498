export default {
  title: `The end of a year`,
  date: `2022-12-21`,
  intro: `Year 2022 was an incredible adventure for me! To think that the current Early Access version has been running for only 12 months is stunning!`,
  fullText: `
<p>
We have seen the game evolve with dozens of changes I've done over that time. Back at launch the game didn't have an in-game change log. There was no Steam app, no mobile native apps. The combat was simplistic with only the basic attack move available. A house was a place only you could enter. The option to ask NPCs any question was not there at all. It's astonishing to look at how much the game evolved. And it's humbling knowing that it was only possible thanks to the game's amazing community.
</p>
<p>
 Of course there were things that didn't go that well. I'm sure most players will find one change or another that they didn't quite fancy. But there's also the other side of the "business" that I also want to talk about today.
</p>
<h3>Crunching the numbers</h3>
<p>
  To provide some more transparency I want to bring up the numbers that I've been working with over these last 2 years.
</p>
<p>
  When I first started this project proper I created a budget out of the personal saving we had. That starting budget was £3000, which is around $3638 US. Going forward I will stick to British Pounds for simplicity. That budget was created to pay for the operational costs, art and any other costs related to the game.
</p>
<p>
  That wasn't all that I had available. A large bunch of wonderful people went out of their way to support this effort financially! Most of the income came through Patreon, totaling at £848. The second income channel is via PayPal donation where I received £110. All in all I had £3958 at my disposal.
</p>
<p>
  What's still left in the budget is £967. Out of the nearly £3000 that were spent, about £766 went to pay for the server running the game. Without any surprise, the lion's share went to art and commissions. I am happy to say that around £996 went to artists working on bespoke art and assets for the game! I wish I could pay them better rates, but I am still proud that there was no unpaid work involved. The rest was mostly store assets and tooling.
</p>
<p>
  All in all I am quite proud of how well the game panned out on such a low budget! Still, the money is drying up and the question remains: what happens next?
</p>
<h2>The deal</h2>
<p>
  I first embarked on this journey to work as full time game dev after I quit a somewhat cushy, well-paid job. After speaking with my wife and considering our finances we agreed that's what I'll do for the next 2 years. And that 2 year period is now coming to an end. I want to be crystal clear here: I am grateful to my wife. Throughout those two years she was a massive support of this effort. I had her faith, I felt that and I also believe that in many ways she is proud of what I have achieved.
</p>
<p>
  But at the end of the day I need to be bringing some income to our home. And while there's many things to celebrate about this project, the income is not one of them. I am grateful for every single supporter that chimed in via Patreon or PayPal! You did wonders to my confidence to have this income! And it helped me fund some of the art work and offset other costs running the game. But I hope this upsets no one when I say the total income is not sustainable.
</p>
<p>
  And once again I want to be clear: I have no expectations nor any resentment to anyone! Nor do I have any disappointment or regrets around what I did, including the financing model. Two years is not a long time, a game running for one year even less so. Things like that need time to reach a good form, gain momentum and build trust. I am thankful to have reached the place we're at right now.
</p>
<p>
  So the term is up and the income is not there. What does that mean to the game?
</p>
<h2>Into the next year</h2>
<p>
  First of all, Soulforged won't be shutting down. The game server will continue humming along as it were. Additionally features like NPC questions will stay working, the same as they do today. I do expect that I will be spending less time working on the game. That likely means a little less involved conversations on Discord. And it's no surprise that it will mean longer time between bug fixes, changes and new content. But it also means that once again I will be able to add some funds to the budget myself.
</p>
<p>
  This may sound odd or silly, but in some ways I am happy for this to happen. Hitting this point without having the income gives me the chance to prove what it is that I stand for with this game.
</p>
<p>
  I refuse to consider a deal with a typical publisher. I'm certain they would end up pushing for adding micro-transactions to the game. Or worse. I also refuse to turn Soulforged into some form of crypto-scam. This is not about making money. This is about making an awesome experience and then finding a way to make that happen. If that means it needs to remain a hobby, at least for the time being, that's the preferable option to me.
</p>
<p>
When exactly will the things change? I will actually continue working full time until mid-February. If all goes well, this gives me enough time to finish and release Chapter 2. After that time I will be shifting my attention to job hunting.
</p>
<h2>The future remains bright</h2>
<p>
  I know the above may seem a bit doom and gloom, but don't let that discourage you! The game in an outstanding shape! The player-base is bigger than it ever was, the Chapter 2 is on the horizon and there's still so much more to come! I hope you will stick around with us to see where this journey takes us next!
</p>
`
};
