export default {
  title: `Going big`,
  date: `2020-12-04`,
  intro: `Working on the Soulforged Project full time is not so different to when it was just a hobbyist project. Except in a way - everything is different.`,
  fullText: `
<h2>The three facets</h2>
<p>
  When I ask myself what is different now, as I'm in the early days of this new project, compared to when I first started Soulforged - I think there are three main facets that all make me look differently at what I'm doing and the decisions I make going forward.
</p>
<h2>The experience</h2>
<p>
  First there's my experience. I wouldn't claim I'm now well versed in the art of game development and game balance, but sure as hell I have way more first-hand experience working on them than I had when I started Soulforged 1. Back then the question I'd ask myself is if a game like this can even be fun. I'd be stumbling blind into new features and experiments, seeing what works and what's utterly broken. I feel going through that process, living through the impact my game and the changes I was making on the players - that leaves me better prepared to create a more exciting and enjoyable game.
<p>
  There are several lessons I took from when Soulforged was being actively developed and growing.
</p>
<ul>
  <li>First and foremost - the time needed to develop and maintain such a project. As you probably know, in the final days of Soulforged I was quite overwhelmed with the amount of work that was begging to be done. Fixing scaling, better admin tools, more activity from the Entities, more contents, better balance... the list went on. I think one way I can put it - I simply did not realize when the game reached the point where it was too big to be just a side hobby for me and that hit me hard. I believe now I have better idea of how much time each of those pieces demand.</li>
  <li>Second lesson - perception is everything. It really counts for very little if the game is very well balanced when it doesn't <em>feel</em> balanced. I think the most glaring example I can name here is the combat - and not the things that I messed up (like dodge scaling, twice), but instead the whole idea of counterattack. I have math to prove that it's a vastly superior system to just having one attack per round from everyone, but that's not how the players felt and it created a strange, disjointed combat experience, often bringing to question the balance of 1 vs many fights. I don't think the system necessarily needs changing drastically, but I think there are better ways to present it, making it not only work really well, but also feel better.</li> 
  <li>Third lesson is that balance is hard. I can once again look at combat, but frankly despite some slip ups I think that was working well enough. A better example here is the running joke that was bread in Soulforged 1. I know I need better simulators and automated checks to verify efficiency and the economic viability in a game like this. In a way you could say I'm slightly amused how well Soulforged 1 turned out to be balanced economically, given how lacking how my attitude was in that regard.</li>
  <li>Next is the player expectations. And specifically how they relate to what my intent is. For instance - my intent was for the game to feel and be slow paced, to require patience and persistence. I intended for the tutorial section of the game to communicate this from the get go. Incidentally, tutorial was often panned as the worst part of the game, something one just needs to slog through. And even for players that completed it - going forward certain playstyles seemed very enticing to them (combat-only playstyle) only to find out that this playstyle is not really "viable" in a game like this, or at least requiring things they didn't find fitting well with that playstyle. Lesson here for me is to very closely observe the impression the game makes on players and adding elements that better communicate the purpose of the game systems. Not in a way that player is forced to play certain way, but to help them go from "why is this working like that?" to "it makes sense now" (combat retaliation mechanic rears its ugly head yet again).</li>
  <li>Last but not least is the fact that the smallest changes can have the biggest impact. Back in the very early days of Soulforged, the <em>Research</em> action would take 15 minutes to complete. That was on top of already consuming the materials for research. Seemed like a logical thing at the time - research does take time. Throwing that out and turning it into 3 seconds was one of the best decision I could make for the game at that stage, making the really daunting task of research that little bit more engaging and approachable.</li> 
</ul>
<p> 
  Funnily enough I have to admit that I thought I was already aware of a number of these points, like the importance of player perception or that game balance is hard. Now I have to say - knowing <em>of</em> it and facing it are two quite different things.
</p>
<p>
  Does this mean I feel I'm ready to dive in and make a near perfect game without player input? Hell no. As a famous game narrator once said:
  <q>Remind yourself that overconfidence is a slow and insidious killer</q>
  And I mean to live by that quote. Especially since I believe there are so many different ways in which Soulforged was begging to be improved on and to improve is to change and change is not always good. How can I know if it's good? It's all back to the players again.  
</p>
<h2>The scale</h2>
<p>
  When Soulforged first started (under the guise of "G the Game"), it was merely a proof-of-concept exercise. Unsurprisingly, it wasn't really meant to scale to the level of engagement that game got. It was not built with that expectation, which became increasingly apparent toward the end of life of the game. To give you some examples of how the game was really unprepared to handle its size, here's couple of technical tidbits that may help picture just how bad it was:
</p>
<ul>
  <li>The game stored all the world and player data in a single file. That file would be written to every 5 minutes, it'd take the game server about 5 seconds to write that file toward the end, rendering the game basically unresponsive during those 5 seconds.</li>
  <li>The original world save would take about 9MB of space. Toward the end of life of the game, with all the player characters and creatures and items, the save would increase in size to 50MB.</li>
  <li>Every second the game server would evaluate the entirety of the world, creatures and structures in the world. For instance - every single second each of the characters would have their "Hunger" effect removed from them and then recalculated and then re-applied - which most of the time they'd end up having exactly the same effect.</li>
  <li>The game loop (described above) would take about 300ms. While it may sound good enough remember - it was intended to be executed every second, meaning it'd take almost 1/3rd of the available time.</li>
  <li>Then there's players connecting to the game server and receiving updates. On average, a player update would take about 20ms for each player. That's 200ms for just 10 players connected at the same time. Having 50 players online at one time means it takes 1 second. That's 1 second every second, on top of the 300ms loop described above.</li>
</ul>
<p>
  I hope the above helps picture just how tight the resource was. And it wasn't a weak machine the was running on either. Of course I was full aware of that and I added mechanisms to mitigate some of the impact - like sending only some of the player updates every second or even making the main game loop run once every 2-3 seconds when needed, ensuring we simulate that 2-3 seconds of game time passing. These measures made the game manageable, but just barely.
</p>
<p>
  Another problem with scaling was the code consistency. While I'm overall still pretty happy about the final result, I am aware of number of areas that just beg to be refactored and cleaned up so that the code is easier to work with and changes can be introduced with more confidence of not breaking stuff. While I have done some of that refactoring, time was still the main constraint that stopped me from doing enough of it and in turn fully fleshing out some of the game systems or new features. On top of that, I had only limited automated testing coverage (again - time) which would in turn make double-back and spend more time testing, slowing down the overall development.
</p>
<p>
  You may be wondering - why did I do such poor job of it then? Well, in my humble opinion - I didn't. I knew of these constraints, I knew about a lot of the impact before it happened and I simply accepted them as a price worth paying. "Perfect is the enemy of good" goes the saying and so if I front-loaded my work with better test coverage and different architecture - the game would not be half as fleshed out as it was in the end. Solving those problems upfront would eventually pay dividends, but it doesn't magically make all the other development take no time at all. In retrospective I can say that I feel it was the right decision to make and a price that I'm glad I paid. And it's worth remembering - when this engine, with its obvious flaws, was first conceptualized - the answer to the question "is this a game worth making?" wasn't really known to me yet. So taking any shortcut that'd get me the answer to that question was really worth it. As a final note on this topic I'd like to bring up an old favourite of mine from the comic creator of XKCD: <a href="https://xkcd.com/1205/" target="_blank">Is It Worth the Time?</a>  
</p>
<img src="https://imgs.xkcd.com/comics/is_it_worth_the_time.png" />
<p>
  Speaking of scale - it's not all code and technical side. It's also about understanding the players and designing the game with player behaviour in mind. Back in Soulforged 1, multi-accounting wasn't really a massive problem, I don't think. Now, that's not to say it wasn't happening or it didn't have an impact, but strictly speaking I think it's safe to say it didn't really severely impact a lot of players in a very negative way. The game is intended as a cooperative experience, a game of collaborative discovery and progress. The nature of the game enables players that wouldn't have the time to spend 4-8-10-12 hours a day playing an MMO to still have an impact and meaningfully participate in the cutting-edge of the game progression. Now, having one player run multiple accounts would definitely add up to having an advantage and disrupt some of that experience. And aiming to scale the game up, odds of having players trying shenanigans like this drastically increases. With that in mind I recognize that I will have to drastically step up my efforts to curb that behaviour and I already have plans on how to tackle it.
</p>
<p>
  Finally - there's the matter of the content development and the challenges of developing the content while players are already in the game. Looking back, I can't help but smile thinking of the number of features and content I added to the game while it was just a small hobby. When you think about it - Soulforged started without any of the following: tracking, item sets, dungeons, lore texts, pets, monster dens, items being nameable, follow feature and tons of items and buildings. Still, with the amount of time I had available given the popularity the game garnered - there was no way I could keep this up. So, what's different now?
</p>
<p>
  Well, first of all, I have more time available to be dedicated to the game development. I'm happy to report that my plan to work full-time is going great, I've made a lot of progress and I'm planning to showcase some of that progress in two weeks time! Second - I give myself more time to build the game content gradually before the actual release. This means that when the server goes live - there'll be a nice, chunky amount of content to play through (more than Soulforged 1 had in the end!). Obviously that may raise some concern of how playable or polished the game will be, but worry not. During the development I'll be releasing much smaller test servers that will be designated to test particular features, the overall interface and core elements of the game. You can picture these being really small islands with limited content, each centered around some key feature being tested (research, housing, dungeons, exploration). This means some of those islands - you'll be getting a leg-up and starting out with some pre-built characters, structures or items.
</p>
<h2>The money</h2>
<p>
  The final facet is the money and money - it changes everything. Some things are changed for the better, some - not so much. But before we go there let me talk about where I find myself financially right now.
</p>
<p>
  I've worked almost all of my adult life and I'm the frugal type. Not a big spender, neither is my wife so we are financially pretty secure. This means I was also able to put some money on the side to finance this project to some extent. This means you won't see me in a month or two going desperate asking for donations to keep going at it. However, this does disrupt some of our major plans - we would love to have a place we can call home - and so I can't justify doing this indefinitely without any revenue generated. Also, the budget I have put aside for spending on this project is rather modest and would not allow me to hire even half time anyone for any serious amount of time - and to be honest, being able to create a job and employ someone to work with me on this would be absolutely amazing.
</p>
<p>
  All that is why I decided I would have the mindset that I'd be looking to monetize this game and that - that changes everything. This would mean, in the most basic setup, a monthly subscription setup - pay a fixed subscription, get access to the entire game. If you don't pay, you will only be able to access a much smaller demo area, so to speak.
</p>
<p>
  Now if you think about this and the aforementioned multi-accounting - if it's not curbed effectively that can easily turn the game into a Pay-to-Win scheme. An action takes a large amount of time? I expect some players would come to me saying how the game is padded. Game server going down or necessitating a rollback? It's now paying customers that are affected by this. One way I could see working around many of these issues is having a thriving Patreon-like setup. Having sufficient income based on a Patreon account alone it could enable me to keep the game free for all players (provided this actually has my supporters' approval), but I wouldn't be quick to assume that may happen.
</p>
<p>
  And the good side? Being able to collaborate and pay for contributions, better assets and assets that were created bespoke for the game! And last but not least - my continued contribution of all my working time toward this project. :)
</p>
<p>
  In the end - this doesn't really change my attitude about making the game itself - I want to create a fun, compelling, cooperative game that people really enjoy playing. I believe that a really fun game is worth some money, be it donations or subscription and if I really achieve this goal of making a fun game - the funds will follow.
</p>
<h2>Closing thoughts</h2>
<p>
  As you can see, in my mind developing this new project is not really different, not at all. Except a lot of things are slightly different. What do you think about my approach? Too optimistic? Too reckless? Or maybe you're as excited as I am for this new game? Please let me know on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a>!
</p>
`
};
