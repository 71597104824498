export default {
  title: `Game launch review`,
  date: `2022-01-14`,
  intro: `Today I'd like to share how the game launch looked from my perspective`,
  fullText: `
<p>
    The game launched into the Early Access on 28th December 2021, at noon UK time. The first players registered literally minutes later! Lots of folks registered over the next few days and I quickly realized that scanning every name and chat message is not something I'll have capacity to do.
</p>
<p>
    I got to work and started by expanding the moderation tools to include reporting. Then another feature, then more feedback-fuelled changes... honestly, it's all a bit of a blur. Looking back at my commit history I actually ended up making a change every day since the launch. Some were minor, but improved players' quality of life a lot. Others a bit bigger - like the new powers.
</p>
<p>
    But one thing was haunting me throughout that time (and likely will for a bit longer) and that's the tutorial. Most of the veteran players breezed through the tutorial with no second thought, but newer players were tripping up on some rough edges that I failed to notice and prune. I've smoothen quite a lot of it by now, but there are still some really hard stopping moments.
</p>
<p>
    And it's at that point that I find myself asking - what's a good percentage of players to finish the tutorial, out of all registered players? Is the goal really 90%+, would that really mean that the game is in a better state than 50%?
</p>
<p>
    Don't get me wrong, there were some really terrible design decisions I made that made folks stumble and quit, some of which I fixed, some I likely have yet to fix. But now that the press is keen to include <a href="https://massivelyop.com/2022/01/07/soulforged" target="_blank">an article about my game</a> it'll likely attract more people, with varied interests. So, inevitably it's going to also attract attention of some folks who may, for one reason or another, quit outright.
</p>
<p>
    To no one's surprise the game has relatively low production value. I'm just one dude with very limited budget. I don't pretend I can compete with <a href="https://en.wikipedia.org/wiki/AAA_(video_game_industry)" target="_blank">triple-A</a> titles and I understand that for lots of folks they will look at what the game has to offer visually and that's a quitting point right there.
</p>
<p>
    So I ask myself - do I want to put in the work to try and keep their attention in the game for longer, long enough to complete the tutorial? Are those players going to stick around for the long-term, join the community and become part of this world? How would I ensure that?
</p>
<p>
    Those are, by every stretch, genuine questions I ask myself. And I don't think I have any solid answers for them. But I will admit that I am leaning toward making the overall game's quality my main focus, making sure that the main chunk of the game itself is fun and engaging. 
</p>
<p>
    And I also feel I have some reasons to believe that this might be the right way to go. There has been a small handful of brand new players - people who haven't played SF:0 or the pre-alpha - that shared their initial impressions, often focusing on how slow the AP makes everything. Then a few days later I see some of those players continue to engage with the game and increasingly - with the community. Making plans, looking forward to unlocking new technologies, new discoveries. This is, in a word, awesome.
</p>
<p>I also want to share with you some numbers, showcasing the health of the game so far.</p>
<ul>
  <li>476 accounts registered to date</li>
  <li>159 characters currently considered "alive"</li>
  <li>60 characters currently active</li>
  <li>2 serious server crashes</li>
  <li>1 serious bug</li>
  <li>0.5% - peak server workload under normal working conditions</li>
</ul>
<p>
    I have to say I'm really happy to see those numbers. The active player count is now reaching numbers that the old SF:0 could only show as "alive player characters", making this a bigger community already. I see brand new players joining and sticking around which warms my heart! And all of that without me worrying that the server is about to topple over (like it would have at this point if I was trying to use SF:0 engine).
</p>
<h2>Closing thoughts</h2>
<p>
    In conclusion - I feel overjoyed with the launch of Soulforged. The feedback I received, both appreciation and very constructive suggestions, tells me there are folks who care what happens with this project and they enjoy what's there so far. I have plenty of work ahead of me, lots of improvements to make and new content to be developed and delivered. But if anything these two weeks have further strengthened my resolve.
</p>
<p>
    Thank you, to everyone who's playing the game and everyone supporting it!
</p>
`
};
