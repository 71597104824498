export default {
  title: `We're on track!`,
  date: `2021-12-17`,
  intro: `Today I'd like to give you a quick overview of where we're at as well as outline what happens next.`,
  fullText: `
<p>
  We're in December and that's the month that I re-scheduled the release to, back in August. I'm happy to say that I'm increasingly confident I'll be releasing the game as Early Access this month!
</p>
<p>
  I have completed all the content I was planning to include, went through the process of balancing all of the recipes, monsters and items and I've just started a round of internal play tests of this version of the game.
</p>
<p>
  So, what can you expect to be included in this release? Let's start with some numbers.
</p>
<ul>
  <li>Over 25 different skills to level and utilize</li>
  <li>35 unique creature types</li>
  <li>A massive map consisting of over 1500 locations and varied environments</li>
  <li>Over 250 different crafting recipes to be discovered</li>
  <li>Over 20 different buildings to construct</li>
  <li>A massive project to be discovered and completed, with significant implications for the region</li>
  <li>A core plot line that loosely connects to the Soulforged Zero story</li>
</ul>
<p>
  And a handful more secrets and special locations to explore.
</p>
<p>
  I imagine it's no surprise when I say, but I wish I could include more with this release. More events, more unique locations, more creatures... Still, I'm happy with the amount waiting there for the players to explore!
</p>
<p>
  In any case, what's there will be made available, to everyone, with no strings attached and no fees. All you need to play the game is internet access, a device that operates a modern browser and a google account to handle authentication - so there's no passwords to manage.
</p>
<h2>What happens next?</h2>
<p>
  Once the game is released my top priority becomes ensuring it works smoothly and without issues. This includes reading through feedback and bug reports, responding and carrying out adjustments and fixes.
</p>
<p>
  In addition to that I'll be starting work on a small bunch of features and improvements I already have planned. These include but are not limited to:
</p>
<ul>
  <li>Add moderation tools for character names and chat</li>
  <li>Add building preview/details screen</li>
  <li>Display current actions being performed by other players</li>
  <li>Improvements to the collections screen</li>
  <li>Showing currently worn gear on your avatar</li>
  <li>Expanding combat, including active abilities and varied attacks</li>
</ul>
<p>
  Following that I'll also be starting to work on the follow up content for the game, including additional areas, events and challenges as well as the postponed pets feature.
</p>
<h2>When are these new items coming?</h2>
<p>
  It's a super-important question and the answer to it is: I don't know. The features will be ready when they are ready, that's really all I can say. The reason for that is simply there are a number of factors that affect when a new feature is ready and some of those are not entirely in my control. It's not only about delays, but some of these factors may actually speed up the delivery too!
</p>
<p>
  It all depends on the amount of fixes I'll need to do, the amount of moderation required, the potential for being able to employ other people to help me with the work and the availability of art assets for me to use or being able to commission. And that's just a few of the known unknowns, there's definitely some unknown unknowns that may come up as well.
</p>
<p>
  What I am looking to continue doing is posting daily updates on the <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> channel #daily-updates. For all the most recent developments you can always take a peek there as well as ask any questions you'd like in the general chat.
</p>
<p>
  What this means is the game content that's coming this month will be all that's available for a little while. I imagine that some players may push very hard to explore and discover every nook and cranny before the new content is ready and that's something that I accept. The difference between Soulforged Zero and now is that previously there were some areas that were put in place, already available but unfinished, with me trying frantically to add to these zones before players reach them. That's what made that model unsustainable for me where I felt hard-pressed to add content all the time, with no clear milestones. With this release, there's a bunch of content, all complete and everything that is still to come is clearly marked as such.
</p>
<h2>The monetization</h2>
<p>
  Finally, I'd like to talk a bit more about my monetization plans. Don't worry, I hold very strongly to my promise of no micro-transactions and no other toxic monetization methods. The main plan continues to be setting a subscription, but I'm now increasingly leaning toward a possible Patreon model to replace the subscription. 
</p>
<p>
  I'm considering updating the Patreon page by adding a monthly goal. Then while working on the followup content I hope to grow the playerbase which will hopefully translate to more people supporting the game on Patreon. If the goal set on Patreon is met, I commit to releasing the next content pack without adding the subscription model at all. Meaning that the game continues to be completely free in its entirety without any other payments attached to it whatsoever.
</p>
<p>
  I believe this model would be the best of all of possible outcomes. First of all it helps grow the playerbase, with all the paywalls being completely out of the way. Secondly - it helps in keeping distance between game design and monetization. In my mind, having a monthly sub means players may feel like certain design elements are there to keep people playing (and paying) for longer. And finally, sticking to Patreon helps enable varied levels of support. Some players wouldn't really be able to afford subscription comfortably while for others dropping something like $50 a month may be considered small amount compared to the enjoyment they are getting. This model enables everyone to show their support proportionally to how they enjoy the game as well as what they can comfortably afford.
</p>
<p>
  Of course of note is that the money that is paid via Patreon is supporting the livelihoods of the development team (that is - just me at this point), but it's also about supporting the game development itself. I already have spent a bunch of monies on the pre-made art assets, infrastructure as well as paying for some commissioned bespoke art that's made for the game. With this Patreon goal I'm looking to continue using these funds in this manner.
</p>
<h2>Exciting times!</h2>
<p>
  That's all I have for you for today, but I hope you liked what you've read today and you're excited to see the game come out later this month! As always you're most welcome to share your perspective on the game and on my plans on our <a href="https://discord.gg/XExbewT5GQ" target="_blank">Discord</a> server.
</p>
<p>
  Thank you for reading and hope you have a wonderful day!
</p>
`
};
